import {useSelector} from 'react-redux'
import {birthdaysSelector, contractsSelector, operationsSelector} from 'cores/firm/pages/inbox/selectors'
import {orgEnvsSelector} from 'selectors/records'
import {getTaxItem} from 'cores/firm/pages/inbox'

export function useInboxData(name) {
	let reminders = useReminders()

	if (name === 'contracts') reminders = reminders.filter(item => item.scope === 'contract')
	if (name === 'birthdays') reminders = reminders.filter(item => item.scope === 'birthday')

	// Using useShared within useOperations seemed to be very slow; use useSelector instead.
	const orgEnvs = useSelector(orgEnvsSelector)
	const {groupName, scope} = getTaxItem(name)
	const all = _.flatten([useOperations({orgEnvs}), reminders])

	const operations = useOperations({scope, orgEnvs})

	if (groupName == 'all') return all
	if (groupName == 'operations') return operations
	if (groupName == 'reminders') return reminders
}

function useReminders(scope) {
	const birthdays = useBirthdays()
	const contracts = useContracts()

	if (scope === 'birthdays') return _.flatten(birthdays)
	if (scope === 'contracts') return _.flatten(contracts)
	return _.flattenDeep([birthdays, contracts])
}

function useBirthdays() {
	const zeroDayNotif = useSelector(state => birthdaysSelector(state, 0))
	const sevenDayNotif = useSelector(state => birthdaysSelector(state, 7))

	return [zeroDayNotif, sevenDayNotif]
}

function useContracts() {
	const zeroDayNotif = useSelector(state => contractsSelector(state, 0))
	const sevenDayNotif = useSelector(state => contractsSelector(state, 7))
	const thirtyDayNotif = useSelector(state => contractsSelector(state, 30))

	return [zeroDayNotif, sevenDayNotif, thirtyDayNotif]
}

export function useOperations({scope, orgEnvs} = {}) {
	return useSelector(state => operationsSelector(state, scope))
}
