import {Fragment} from 'react'
import {useForm} from '@eitje/form'
import {DeleteButton, SubmitButton} from 'common/components'
import {t} from 'initializers/i18n'
import {PopoutCard} from '@eitje/web_components'
import {Layout} from 'common/components'
import './styles/form.less'
import {useHotkeys} from 'react-hotkeys-hook'
import utils from '@eitje/web_utils'

export const FormRow = ({children}) => {
	return <div className="form-row">{children}</div>
}

export const FormSubmitButtonInner = props => {
	let {
		submit,
		formTouched,
		className,
		getData,
		ButtonComponent = SubmitButton,
		isDisabled,
		enabledIfTouched,
		showIfTouched,
		form,
		disabled,
		t: translation,
		showIf,
		text,
	} = props
	useHotkeys('enter', submit)
	if (!getData) return null
	const requiredString = t('form.required')
	const {errs = {}} = form?.validateInner?.() || {}

	const missingRequiredFields = Object.values(errs).some(e => e == requiredString)

	const children = translation ? null : props.children || text || t('common.submit')
	if (showIfTouched && !formTouched()) return null
	const formData = getData()
	const shouldShow = !showIf || showIf(formData)
	if (!shouldShow) return null
	const _disabled = isDisabled ? isDisabled(formData) : enabledIfTouched ? !formTouched() : disabled
	const invalidText = t('form.general.tooltips.missing_required_fields')
	const Wrapper = missingRequiredFields ? PopoutCard : Fragment
	const classNames = utils.makeCns(className, 'form-submit-button')

	return (
		<Wrapper title={invalidText}>
			<ButtonComponent
				baseColor={missingRequiredFields ? 'grey' : 'blue'}
				onClick={submit}
				onConfirm={submit}
				data-intercom-target="form-submit-button"
				className={classNames}
				{...props}
				disabled={_disabled}
			>
				{children}
			</ButtonComponent>
		</Wrapper>
	)
}

const FormDeleteButton = props => {
	const {submit} = useForm()
	const children = props.children || t('common.delete')
	return (
		<DeleteButton onDel={submit} {...props}>
			{children}
		</DeleteButton>
	)
}

const FormSubmitButton = props => {
	const {submit, formTouched, getData, form} = useForm()
	return <FormSubmitButtonInner {...props} submit={submit} formTouched={formTouched} getData={getData} form={form} />
}

export {FormSubmitButton, FormDeleteButton}
