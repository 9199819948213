import {useEffect, Children} from 'react'
import {NewForm, useForm} from '@eitje/form'
import {FormRow} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {EitjeModal} from 'components/common'
import {useModalContext} from 'components/common/index'
import {FormSubmitButtonInner} from 'components/form'
import {useFormData} from 'hooks'

const Inner = ({modalProps = {}}) => {
	const {okButtonProps = {}, ...rest} = modalProps
	const {setModalPropsRaw, forceRenderModal} = useModalContext()
	const {submit, formTouched, getData, form, ...formRest} = useForm()
	const _data = useFormData()
	useEffect(() => {
		setModalPropsRaw({
			form: true,
			SubmitButtonOuter: FormSubmitButtonInner,
			okButtonProps: {...okButtonProps, form, submit, formTouched, getData},
			...rest,
		})
	}, [])

	useEffect(() => {
		forceRenderModal()
	}, [_data])

	return null
}

export const ModalWithNewForm = ({name, width, className, modalProps, ...props}) => {
	const classNames = utils.makeCns('eitje-form-3-modal', className)
	return (
		<EitjeModal name={name} width={width} className={classNames} {...modalProps} form>
			<NewModalForm {...props} modalProps={modalProps} />
		</EitjeModal>
	)
}

export const NewModalForm = ({children, modalProps, oneFieldPerFormRow, ...props}) => {
	const {names} = useModalContext()

	if (oneFieldPerFormRow) {
		// using Children.toArray errors when children is a single element or has irregular structures (e.g. fragments).
		children = Children.toArray(children).map(child => {
			return <FormRow>{child}</FormRow>
		})
	}

	return (
		<NewForm name={names} {...props}>
			<Inner modalProps={modalProps} />
			{children}
		</NewForm>
	)
}
