import {PopoutCard} from '@eitje/web_components'
import {t as _t} from 'initializers/i18n'
import {titleWithCount} from 'common/components'
import {UserList} from './user_list'

export const UserListPopout = ({users, t = 'users', onItemClick, ...rest}) => {
	const title = titleWithCount(_t(t), users.length)

	return (
		<PopoutCard
			bodyPadding={0}
			placement="right-start"
			maxHeight={400}
			width={300}
			{...rest}
			body={<UserList users={users} onItemClick={onItemClick} />}
			title={title}
		/>
	)
}
