import {useIncrementState, useMergeState} from '@eitje/react-hooks'
import utils from '@eitje/web_utils'
import {goBackToForeground} from 'actions/routing'
import {Modal} from 'antd'
import {Layout, Spinner} from 'common/components'
import {ModalRedirect, navigate} from 'components/routing'
import {formatCssVars, makeCnVariants, requireProps} from 'helpers'
import {has} from 'helpers/object'
import {useFitInViewportCheck, useModalBack} from 'hooks'
import {t} from 'initializers/i18n'
import React, {useContext, useRef} from 'react'
import {useHistory} from 'react-router'
import {ModalFooter} from './footer'
import {ModalHeader} from './header'
import {ModalRightPanel} from './right_panel'
import './styles/body.less'
import './styles/index.less'

const ModalContext = React.createContext({})
const ModalProvider = ModalContext.Provider
export const useModalContext = () => useContext(ModalContext)

export const Inner = ({width = 560, loading, hideHeader, redirectBack, hideFooter, height, className, ...props}) => {
	const modalRef = useRef()

	const {vertical} = useFitInViewportCheck({element: modalRef, offset: 100})

	let {RightPanel, ...rest} = props

	const modalClassName = props.name.replace(/_/g, '-').concat('-modal')
	const classNames = utils.makeCns(
		'eitje-modal-2',
		'hide-close',
		RightPanel && 'has-panel',
		!vertical && 'small-top-offset',
		height && 'set-fixed-height',
		modalClassName,
		className,
	)

	const restPropsMinusTitle = {...rest, title: undefined}

	if (redirectBack) return <ModalRedirect />

	return (
		<Modal {...restPropsMinusTitle} width={width} footer={null} visible className={classNames}>
			<div
				className="eitje-modal-2-main"
				ref={modalRef}
				style={formatCssVars({
					'modal-height': height,
				})}
			>
				{!hideHeader && <ModalHeader {...props} />}
				<Spinner loading={loading}>
					<ModalBody {...props} />

					{!hideFooter && <ModalFooter {...props} />}
				</Spinner>
			</div>

			<ModalRightPanel {...props} />
		</Modal>
	)
}

const ModalBody = props => {
	const {children, LeftPanel, hasFooter, bodyMaxHeight = 'auto'} = props
	const classNames = makeCnVariants('eitje-modal-2-body', !hasFooter && 'without-footer', LeftPanel && 'has-left-panel')
	const maxHeight = props.bodyMaxHeight ? bodyMaxHeight + 'px' : 'auto'
	return (
		<div className={classNames} style={{maxHeight}}>
			{LeftPanel && <ModalLeftPanel {...props} />}
			{children}
		</div>
	)
}

const ModalLeftPanel = props => {
	const {LeftPanel, leftPanelWidth = 200, leftPanelProps} = props

	return (
		<Layout className="eitje-modal-2-left-panel" width={leftPanelWidth} style={{maxWidth: leftPanelWidth}}>
			<LeftPanel {...leftPanelProps} />
		</Layout>
	)
}

export const EitjeModal = props => {
	const [forceRenderModalCount, forceRenderModal] = useIncrementState(0)

	const [modalContextProps, setModalProps, setRaw] = useMergeState()
	const history = useHistory()
	let {contextProps, onCancel, goBackToForeground: hasGoBackToForegroundProp, name, i18nOpts, simpleBack = true} = props
	const {goBack} = useModalBack({...props, simpleBack})
	const hasForeground = history.length >= 2

	// There are three cases:
	// 1. `goBackToForeground` prop is not passed, simply go back (which may be another modal)
	// 2. `goBackToForeground` prop is passed, and there is a foreground, simply go to the foreground
	// 3. `goBackToForeground` prop is passed AND there is no foreground, when the URL is the first in the tab. This occurs when starting a consecutive
	// Intercom tour, or when you open a modal in an a-tag with target="_blank". Then there is no foreground to go back to, thus fallback to the /home_page url.
	let backToForegroundFn = goBackToForeground
	if (hasGoBackToForegroundProp && !hasForeground) {
		backToForegroundFn = () => navigate('/home_page')
	}

	const singleName = utils.alwaysDefinedArray(name)[0] // form can have multiple names, modal (for now) can't

	if (!onCancel) onCancel = hasGoBackToForegroundProp ? backToForegroundFn : goBack
	const resetModalProps = () => setRaw({})
	requireProps('Modal', {name})
	const modalT = (name, rest) => {
		let keys = [`modals.${singleName}.${name}`]
		if (_.isString(rest)) keys = [...keys, rest]
		return t(keys, {...i18nOpts, ...rest})
	}

	const _props = {...props, onCancel, name: singleName, names: name, hasFooter: has(props, 'hasFooter', true), ...modalContextProps}

	return (
		<ModalProvider
			value={{
				setModalProps,
				forceRenderModal,
				setModalPropsRaw: setRaw,
				resetModalProps,
				goBack,
				onCancel,
				t: modalT,
				names: name,
				name: singleName,
				...contextProps,
			}}
		>
			<Inner {..._props} />
		</ModalProvider>
	)
}
