import utils from '@eitje/web_utils'
import {Label, Layout, PopoutCard, usePageContext} from 'common/components'
import {labelProps, labelContainerProps} from 'cores/balance'
import {useShared} from 'hooks'
import {date} from 'initializers/date'
import {Environment} from 'models'
import {Fragment} from 'react'
import {t} from 'initializers/i18n'

const CurrentEnvWrapper = props => <PopoutCard title={t('balance.show.tooltips.current_env')} {...props} />

const buildContractLabel = (label, env, user, isMultiOrg) => {
	if (!env) return label
	const envLabel = env.naam || t('common.unknown_environment')

	if (isMultiOrg) label = `${label} (${envLabel})`
	if (user.hrIntegration()) label = `${label} (${user.hrIntegration()})`
	return label
}

export const LabelContainer = ({Labels}) => {
	const {user, env, startDate, balance, empType} = usePageContext()

	const {isMultiOrg} = useShared()
	const currentBalance = Object.values(balance)._map('mins').filter(Boolean).sum()
	const schedule = user.workSchedule(date())
	const contract = user.contractHolder(date())
	const currentEnv = Environment.find(contract?.environment_id)
	let contractLabel
	if (schedule) {
		contractLabel = t(`contract.hours_on_contract`, {amt: utils.minToTimeString(schedule.total_mins)})
		contractLabel = buildContractLabel(contractLabel, currentEnv, user, isMultiOrg)
	}

	let empTypeLabel = empType ? t(`common.${empType.name}_contract`) : t('common.no_contract')
	if (empType) empTypeLabel = buildContractLabel(empTypeLabel, currentEnv, user, isMultiOrg)

	const envLabel = env.naam || t('common.unknown_environment')
	const envLabelPopout = env.naam || t('common.unknown_environment_long')

	const activeSinceKey = isMultiOrg ? `active_since_multi` : `active_since`

	const EmpTypeWrapper = empType && isMultiOrg ? CurrentEnvWrapper : Fragment
	const ScheduleWrapper = isMultiOrg ? CurrentEnvWrapper : Fragment

	return (
		<Layout {...labelContainerProps}>
			<Label
				{...labelProps}
				colorSet="solid"
				data-intercom-target="current-balance"
				t={{key: 'balance.show.labels.current', balance: utils.minToTimeString(currentBalance, true)}}
			/>
			{Labels && <Labels user={user} userEmpType={empType} />}

			<PopoutCard title={t(`balance.show.tooltips.${activeSinceKey}`, {env: envLabelPopout})}>
				<Label
					{...labelProps}
					t={{key: `balance.show.labels.${activeSinceKey}`, env: envLabel, date: startDate.formatDate({year: true})}}
				/>
			</PopoutCard>

			{schedule && (
				<ScheduleWrapper>
					<Label {...labelProps} children={contractLabel} />
				</ScheduleWrapper>
			)}

			{(!empType || !empType.isFixed()) && (
				<EmpTypeWrapper>
					<Label {...labelProps} children={empTypeLabel} />
				</EmpTypeWrapper>
			)}
		</Layout>
	)
}
