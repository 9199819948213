import {useFind} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {WeekTableCellItem} from 'common/components'
import {ModalLink} from 'components/routing'
import {ProfileLink} from 'components/shared'
import {OvertimeBalanceLink} from 'cores/balance'
import {useOpenDirectChatText} from 'cores/chat'
import {useContractMins, useRoleMessage} from 'hooks'
import {t} from 'initializers/i18n'

export function useUserPanelDropdownOptions({user, dateRange, createShiftUrl, disableCreateShift}) {
	const openChatText = useOpenDirectChatText(user.id)
	const contract = useFind('contractHolders', {user_id: user.id, dateRange})
	const disableContractRole = useRoleMessage({roles: 'contracten', env: contract.environment_id})
	const disableContract = disableContractRole || (!contract.id && t('disabled.no_contract'))

	return [
		{icon: 'plus', element: <ModalLink to={createShiftUrl} t="add" />, disabled: disableCreateShift},
		openChatText && {icon: 'chat', element: openChatText},
		{icon: 'document', element: <ModalLink t="viewContract" to={`/forms/contracts/${contract.id}/details`} />, disabled: disableContract},
		{icon: 'person', element: <ProfileLink userId={user.id} />},
		{icon: 'hourglass', element: <OvertimeBalanceLink userId={user.id} />},
	]
}

export const ContractHoursCellItem = ({workSchedule, ...props}) => {
	// Use a component over object to prevent running the slow useContractMins unnecessary
	const {totalMins} = useContractMins(workSchedule?.id)
	const value = utils.minutesToString(totalMins)
	return <WeekTableCellItem value={value} {...props} />
}
