import {makeCnVariants} from 'helpers'
import {Text, Layout} from 'common/components'
import {useBasicTableContext} from 'common/components/basic_table'

export * from './cells'

export const BasicTableCell = ({item, column}) => {
	const {withBorders, cssColumnWidth, ...rest} = useBasicTableContext()
	const classNames = makeCnVariants('basic-table-row-cell', withBorders && 'with-borders')
	const {Cell, accessor} = column
	const value = item[accessor]

	return (
		<Layout height="100%" padding="0 16" width={cssColumnWidth} className={classNames}>
			{Cell && <Cell {...rest} column={column} item={item} value={value} />}
			{!Cell && <Text truncate>{value}</Text>}
		</Layout>
	)
}
