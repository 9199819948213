// import {DatePicker} from 'antd'
import {DatePicker} from '@eitje/form-fields-web'
import {Layout, Text, Icon} from 'common/components'
import {date} from 'initializers/date'
import './index.less'
import {useEitjeDatePicker} from './use_eitje_date_picker.js'

export * from './use_eitje_date_picker.js'

export const EitjeDatePicker = ({
	value,
	label,
	onChange,
	height = 'regular',
	showArrow,

	// Date picker props
	disabledBefore,
	disabledAfter,
	showToday = true,

	// Within the FixedRangePicker component, it doesn't look like a detached, floating button, but has side buttons instead.
	noBorderRadius,
}) => {
	// Manage state to hide and show ant's picker, which we need to do since we refuse to use their original trigger.
	const {datePopoutVisible, setDatePopoutVisible} = useEitjeDatePicker()

	value = date(value)
	// Object in render to access window variables
	const SIZE_PROPS = {
		regular: {
			height: __REGULAR_HEIGHT,
		},
		small: {
			height: __SMALL_HEIGHT,
		},
	}

	const _onChange = _date => {
		onChange(_date)
		setDatePopoutVisible(false)
	}

	// Apparently a wrapper div is necessary to position the calendar Popout correctly below the trigger.
	return (
		<div className="eitje-date-picker" data-intercom-target="eitje-date-picker">
			<Layout
				className="eitje-date-picker-trigger"
				colorSet
				onClick={() => setDatePopoutVisible(!datePopoutVisible)}
				horizontal="center"
				padding="4 12"
				border
				width="unset"
				borderRadius={!noBorderRadius}
				{...SIZE_PROPS[height]}
			>
				<Text bold>{label || value.ftime('dayMonthYear')}</Text>
				{showArrow && <Icon size={14} name="chevron-small-down" />}
			</Layout>
			{datePopoutVisible && (
				<DatePicker
					raw
					bordered={false}
					open
					value={value}
					onChange={_onChange}
					disabledBefore={disabledBefore}
					disabledAfter={disabledAfter}
					showToday={showToday}
				/>
			)}
		</div>
	)
}
