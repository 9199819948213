import {NewModalForm} from 'components/form/new_modal_form'
import {useParams} from 'hooks'
import {Topic} from 'models'
import {TrainingFormFields} from './training_form_fields'

export const TrainingForm = () => {
	const {id} = useParams()
	const {always_trainable, valid_untill, days_to_start, title, ...topic} = Topic.find(id)

	const handleSubmit = async ({trainable, trainingType, valid_untill, days_to_start}) => {
		const always_trainable = trainingType === 'always_trainable'
		const daysToStart = days_to_start && days_to_start > 0 && days_to_start - 1

		const data = trainable
			? {always_trainable, valid_untill, days_to_start: daysToStart}
			: {always_trainable: false, valid_untill: null, days_to_start: null}

		return await topic.update(data)
	}

	const trainingType = topic.trainingType()
	const initialValues = {
		trainable: !!trainingType,
		trainingType: trainingType || 'always_trainable', // always_trainable is default
		always_trainable,
		valid_untill,
		days_to_start: _.isInteger(days_to_start) && days_to_start + 1,
	}

	return (
		<NewModalForm name="training" initialValues={initialValues} onSubmit={handleSubmit}>
			<TrainingFormFields title={title} />
		</NewModalForm>
	)
}
