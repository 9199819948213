import {HelpButton} from 'common/components'
import {NAMESPACE} from 'cores/knowledge'

const docsLinks = [
	{id: 6009233, t: 'create_topic'},
	{id: 6015044, t: 'activate_training'},
]

const introductionProps = {
	title: 'common.introduction',
	body: `${NAMESPACE}.topic_help_popout_body`,
	docsId: 4588528,
}

export const TopicHelpButton = () => <HelpButton docsLinks={docsLinks} collection={4588528} introductionProps={introductionProps} />
