import {PageLayout, YouTube, SupportCollections} from 'common/components'
import {VideoCollection} from 'cores/onboarding/components'
import {
	featureExplanationsTaxonomy,
	useOnboardingStepsTaxonomy,
	useOnboardingModalTaxonomy,
	articlesTaxonomy,
} from 'cores/onboarding/taxonomies'
import {PageHeader} from './page_header'
import {WelcomeText} from './welcome_text'
import {Placeholder} from './placeholder'

export const NAMESPACE = `onboarding.pages.welcome`
export const BODY_WIDTH = 800

export const WelcomePage = () => {
	const steps = useOnboardingStepsTaxonomy()
	const stepModals = useOnboardingModalTaxonomy()
	const features = featureExplanationsTaxonomy()
	const supportCollections = articlesTaxonomy()

	const {youTubeId: featuredVideo} = steps.find(t => t.id === 'create_environment')
	const stepsTitle = `${NAMESPACE}.step_previews_title`
	const featuresTitle = `${NAMESPACE}.feature_explanations_title`
	const docsTitle = `${NAMESPACE}.support_collection_title`

	return (
		<PageLayout name="welcome" bodyWidth={BODY_WIDTH}>
			<PageHeader />
			<WelcomeText />
			<YouTube id={featuredVideo} />
			<VideoCollection videos={stepModals} title={stepsTitle} />
			<VideoCollection videos={features} title={featuresTitle} />
			<SupportCollections title={docsTitle} items={supportCollections} />
			<Placeholder />
		</PageLayout>
	)
}
