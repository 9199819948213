import {t} from 'initializers/i18n'
import {NAMESPACE, formatGrade} from 'cores/knowledge'

export const useTrainingResult = (resources, userAnswers, userId) => {
	let score = 0
	let totalQuizItems = 0

	const results = resources.map(({tableName, id, ...resource}) => {
		switch (tableName) {
			case 'infos': {
				const {title, readers} = resource
				const read = readers.find(reader => reader.user_id === userId)
				return {id, tableName, title, read}
			}
			case 'quizItems': {
				const {question, answers, correct_answer} = resource
				const userAnswer = userAnswers.find(({quiz_item_id}) => quiz_item_id === id)?.answer
				const correct = userAnswer === correct_answer

				if (correct) score += 1
				totalQuizItems += 1

				return {
					id,
					tableName,
					title: question,
					answer: answers[userAnswer],
					correct,
					correctAnswer: answers[correct_answer],
				}
			}
		}
	})

	if (!totalQuizItems) {
		return {
			results,
			score: t(`${NAMESPACE}.no_resources`),
		}
	}

	score = formatGrade((score / totalQuizItems) * 10)
	return {results, score}
}
