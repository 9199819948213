import utils from '@eitje/utils'
import {Icon} from '@eitje/web_components'
import {
	EitjeButton,
	EitjeDropdown,
	HelpButton,
	Layout,
	PeriodListPicker,
	Text,
	PageHeader as _PageHeader,
	usePageContext,
} from 'common/components'
import {ModalLink, RouteSelect} from 'components/routing'
import {ProfileLink} from 'components/shared'
import {getTableName} from 'helpers'
import {useArbitrary, useRoleMessage, useRoleOrgEnvs, useShared} from 'hooks'
import {t} from 'initializers/i18n'
import {getBalanceArticles} from 'cores/balance'
import {Contract, Environment, User, WorkSchedule} from 'models'

export const PageHeader = () => {
	const {periodType, setPeriodType, kind, user} = usePageContext()

	const leaveBalanceUserIds = useArbitrary('leave_balance')._filter('!inactive')._map('user_id')
	const overtimeBalanceUserIds = useArbitrary('overtime_balance')._filter('!inactive')._map('user_id')
	const balanceUserIds = kind == 'overtime' ? overtimeBalanceUserIds : leaveBalanceUserIds

	const hasLeave = leaveBalanceUserIds.includes(user.id)
	const hasOvertime = overtimeBalanceUserIds.includes(user.id)

	const users = User.where(balanceUserIds)
	const currentRoute = `/balance/${kind}/${user.id}`
	const {org} = useShared()

	const userItems = users.map(u => ({
		label: u.full_name,
		value: `/balance/${kind}/${u.id}`,
		query: {periodType},
		active: !u.isDeleted(org),
	}))

	const items = [
		{
			label: t('overtime'),
			disabled: !hasOvertime && t('balance.show.no_overtime'),
			value: `/balance/overtime/${user.id}`,
			query: {periodType},
		},
		{label: t('common.leave'), disabled: !hasLeave && t('balance.show.no_leave'), value: `/balance/leave/${user.id}`, query: {periodType}},
	]
	const specificArticle = kind == 'leave' ? 'balance.leave_101' : 'balance.overtime_101'

	const docsLinks = getBalanceArticles([specificArticle, 'balance.setup', 'balance.adjustment'])

	const userGroups = [
		{id: true, name: t('common.in_service')},
		{id: false, name: t('common.out_of_service')},
	]

	return (
		<_PageHeader
			type="floating"
			left={[
				<EitjeButton t="balance.all" iconLeft="arrow-left" link={`/balance/${kind}`} />,
				<RouteSelect
					data-intercom-target="user-switch"
					trigger="click"
					colorSet="color-bordered-color-content"
					shouldTranslate={false}
					items={userItems}
					groupItems={userGroups}
					groupField="active"
					currentVal={currentRoute}
				/>,
				<RouteSelect data-intercom-target="kind-switch" trigger="click" shouldTranslate={false} currentVal={currentRoute} items={items} />,
				<PeriodListPicker
					trigger="click"
					without={['flex_wage_period', 'hours_wage_period']}
					raw
					onChange={setPeriodType}
					value={periodType}
				/>,
			]}
			right={[
				<ActionsDropdown kind={kind} user={user} dropdown />,
				<EitjeDropdown
					wrapperLayoutProps={{'data-intercom-target': 'contract-dropdown'}}
					elements={getContractBlocks({user})}
					elementLayoutProps={{padding: 8}}
					minWidth={280}
					trigger="click"
					className="balance-user-contracts-dropdown"
				>
					<EitjeButton t={getTableName('contract')} iconLeft="document-signed" dropdown />
				</EitjeDropdown>,
				<HelpButton tourId={587462} docsLinks={docsLinks} collection={3362955} />,
			]}
		/>
	)
}

const ActionsDropdown = ({user, kind}) => {
	const envs = useRoleOrgEnvs('uren_accorderen')
	const {environment_id} = usePageContext()
	const envId = _.intersection(user.environment_ids, envs._map('id'))[0] || envs[0].id
	const disabled = useRoleMessage({env: environment_id, roles: 'uren_accorderen'})

	const elements = [
		{icon: 'pencil', disabled, element: <ModalLink to="adjustments/new" t="balance.adjustments.create" />},
		{icon: 'person', element: <ProfileLink userId={user.id} />},
		{icon: 'three-sliders', disabled, element: <ModalLink to={`/balance/setup/${envId}/${kind}_balance`} t="manageBalance" />},
	]
	return (
		<EitjeDropdown wrapperLayoutProps={{'data-intercom-target': 'actions-dropdown'}} elements={elements} trigger="click">
			<EitjeButton t="actions" iconLeft="three-sliders" dropdown />
		</EitjeDropdown>
	)
}

const getContractBlocks = ({user}) => {
	return user.userEmploymentTypes.map(empType => <ContractBlock empType={empType} />)
}

const ContractBlock = ({empType}) => {
	const contract = Contract.find({dateRange: empType.dateRange, user_id: empType.user_id})
	const workSchedule = WorkSchedule.find({dateRange: empType.dateRange, user_id: empType.user_id})
	const env = Environment.find(contract.environment_id)
	const {isMultiOrg} = useShared()
	return (
		<Layout width="full" gap={16} modalLink={`/forms/contracts/${contract.id}/details`} parentLayoutProps={{width: 'full'}}>
			<Layout grow direction="vertical" gap={0}>
				<Text bold>
					{t(empType.name)} {empType.dateRange.hasToday() && `(${t('common.active_now')})`}
				</Text>
				<Text darkGrey t={{key: 'functions.fromTill', from: empType.start_date, till: empType.end_date}} />
				{empType.isFixed() && workSchedule.id && (
					<Text darkGrey t={{key: 'common.contract_hours_per_week', amt: utils.minutesToString(workSchedule.total_mins)}} />
				)}
				{isMultiOrg && (
					<Text darkGrey>
						{t('common.environment')}: {env.naam || t('common.unknown_environment_long')}
					</Text>
				)}
			</Layout>
			<Icon name="caret-right" />
		</Layout>
	)
}
