import {createContext, useContext, useEffect, useRef} from 'react'
import {useEditor, useEditorState} from '@tiptap/react'
import deepEqual from 'deep-equal'
import {COMMANDS} from '../taxonomies'
import {getExtensions} from '../extensions'

const RichTextEditorContext = createContext({})
const {Provider} = RichTextEditorContext

export const RichTextEditorProvider = ({initialContent, readOnly, updatedAt, onSave = _.noop, children}) => {
	const documentContainerRef = useRef(null)
	const editable = !readOnly
	const editor = useEditor({
		extensions: getExtensions(),
		content: initialContent,
		autofocus: 'start',
		editable,
		emitUpdate: editable,
		shouldRerenderOnTransaction: false, // optimized react rendering flow
	})

	// this hook subscribes to formatting state updates and ensures the editor only
	// rerenders in any of the formatting options changes
	useEditorState({
		editor,
		selector: editorStateSelector,
		equalityFn: deepEqual,
	})

	useEffect(() => {
		editor.setEditable(editable, false) // second argument determines whether onUpdate is triggered.
	}, [editable])

	return <Provider value={{editor, readOnly, updatedAt, onSave, documentContainerRef}} children={children} />
}

export const useRichTextEditorContext = () => useContext(RichTextEditorContext)

function editorStateSelector({editor}) {
	return Object.values(COMMANDS).reduce((acc, command) => {
		acc[command.name] = command.isActive(editor)
		return acc
	}, {})
}
