import {NewModalForm} from 'components/form/new_modal_form'
import {useParams} from 'hooks'
import {Topic} from 'models'
import {TopicFormFields} from '../components/topic_form_fields'

export const TopicForm = () => {
	const {id} = useParams()
	const topic = Topic.find(id)

	const handleSubmit = async data => await topic.update(data)

	const initialValues = {...topic, remote_avatar_url: topic.avatar}

	return (
		<NewModalForm name="edit_topic" initialValues={initialValues} onSubmit={handleSubmit}>
			<TopicFormFields showPublishedField />
		</NewModalForm>
	)
}
