import {Layout} from 'common/components'
import {ResultField, ResultResource} from '.'
import {useTopicContext, NAMESPACE} from 'cores/knowledge'
import {t} from 'initializers/i18n'
import {useTrainingResult} from './hooks'

export const TrainingResults = ({userId}) => {
	const {resources, started, finished, user_answers} = useTopicContext()
	const userAnswers = user_answers?.filter(answer => answer.user_id === userId) || []
	const {results, score} = useTrainingResult(resources, userAnswers, userId)

	const hasStarted = started?.some(u => u === userId)
	const hasFinished = finished?.some(u => u === userId)

	const scoreValue = getScoreValue(score, hasStarted, hasFinished)

	return (
		<Layout className="training-results" direction="vertical" gap={0} width="full">
			<ResultField t="score" value={scoreValue} />
			{results.map(({id, title, ...rest}) => (
				<ResultField key={id} label={title}>
					<ResultResource {...rest} />
				</ResultField>
			))}
		</Layout>
	)
}

function getScoreValue(score, hasStarted, hasFinished) {
	if (!hasStarted) return t(`${NAMESPACE}.score_not_started`)
	if (!hasFinished) return t(`${NAMESPACE}.score_not_finished`)
	return score
}
