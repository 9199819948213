import {useEffect, useRef, useState} from 'react'
import {useModalContext} from 'components/common/index'
import utils from '@eitje/web_utils'
import {FormDeleteButton, FormSubmitButton, FormSubmitButtonInner} from 'components/form'
import {DeleteButton, ConfirmButton, SubmitButton, BackButton, DocsButton, Layout} from 'common/components'
import './styles/footer.less'

export const ModalFooter = props => {
	const {LeftFooter, hasFooter, hideCancel} = props
	const ns = 'eitje-modal-2-footer'

	// overwrite spaceBetween's default 100% width to ensure the content may overgrow the container,
	// otherwise the refs won't measure the fatty content
	const inlineStyle = {width: 'fit-content', minWidth: '100%'}
	const horizontalPadding = 16
	const footerRef = useRef()
	const contentRef = useRef()
	const footerWidth = footerRef.current?.clientWidth
	const contentWidth = contentRef.current?.clientWidth
	const [hasCancel, setHasCancel] = useState(!hideCancel)

	useEffect(() => {
		setHasCancel(!hideCancel)
	}, [hideCancel])

	if (footerWidth && contentWidth && hasCancel) {
		const footerContent = contentWidth + 2 * horizontalPadding
		const contentFits = footerContent <= footerWidth
		if (!contentFits) setHasCancel(false)
	}

	if (!hasFooter) return null

	return (
		<Layout padding={`16 ${horizontalPadding}`} className={ns} width="full" ref={footerRef}>
			<Layout horizontal="spaceBetween" className={`${ns}-content`} style={inlineStyle} ref={contentRef}>
				{LeftFooter && <LeftFooter />}
				<Layout className={`${ns}-right`}>
					<RightFooter {...props} hasCancel={hasCancel} />
				</Layout>
			</Layout>
		</Layout>
	)
}

const ModalBackButton = () => {
	const {onCancel} = useModalContext()
	return <BackButton onClick={onCancel} />
}

const RightFooter = props => {
	const {
		form,
		onSubmit,
		afterDel,
		submitIsDanger,
		SubmitButtonComponent,
		SubmitButtonOuter,
		buttons,
		insertAtIndex,
		confirmSubmit,
		hasCancel,
		hideSubmit,
		onDel,
		collection,
		docsId,
		okButtonProps = {},
	} = props

	const getSubmitButton = () => {
		if (submitIsDanger) return FormDeleteButton
		if (confirmSubmit) return ConfirmButton
		if (SubmitButtonComponent) return SubmitButtonComponent
	}

	const {onCancel} = useModalContext()

	const ButtonComponent = getSubmitButton()
	const _SubmitButtonComponent = SubmitButtonOuter ? FormSubmitButtonInner : form ? FormSubmitButton : SubmitButton
	let condOpts = form ? {} : {onClick: onSubmit}
	const showSubmit = !hideSubmit && (onSubmit || form)
	const backButton = <BackButton onClick={onCancel} />
	const showDocsButton = docsId || collection

	let _buttons = [
		hasCancel && backButton,
		onDel && <DeleteButton afterDel={afterDel} onDel={onDel} />,
		...utils.alwaysArray(buttons),
		showDocsButton && <DocsButton collection={collection} docsId={docsId} />,
		showSubmit && <_SubmitButtonComponent colorSet="solid" ButtonComponent={ButtonComponent} {...okButtonProps} {...condOpts} />,
	].filter(Boolean)

	if (insertAtIndex) {
		Object.keys(insertAtIndex).forEach(idx => {
			const comp = insertAtIndex[idx]
			_buttons.splice(idx, 0, comp)
		})
	}

	return _buttons
}
