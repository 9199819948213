import {backend} from '@eitje/easy_api'
import {useAsyncEffect, useIncrementState} from '@eitje/react-hooks'
import {endLoad, startLoad} from 'helpers/general'
import {useState} from 'react'

export const useGet = (endPoint, {params = {}, watch, skip, reduxLoad, skipInitial} = {}) => {
	const [retries, retry] = useIncrementState()
	const [data, setData] = useState({})
	const [error, setError] = useState()
	const [loading, setLoading] = useState(true)

	useAsyncEffect(async () => {
		if (!skip && endPoint && (!skipInitial || retries > 0)) {
			setLoading(true)
			if (reduxLoad) startLoad()
			const res = await backend.get(endPoint, params)
			if (reduxLoad) endLoad()

			if (res.ok) {
				setData(res.data)
			} else {
				setError(true)
			}
			setLoading(false)
		}
	}, [retries, watch])

	const _data = data || {}

	return {..._data, data: _data, error, loading, retry}
}

export default useGet
