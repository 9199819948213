import {BasicPopout} from '@eitje/web_components'
import {BasicTable, EitjeButton, InfoPopout, Text, BasicTableTimeStringCell, Layout} from 'common/components'
import {Link} from 'components/routing'
import {LeaveList, ShiftList, useBalanceStartDateRaw} from 'cores/balance'
import {t} from 'initializers/i18n'
import {stopLink} from 'helpers'
import {EmploymentType} from 'models'
import {useQueryParams} from 'hooks'
import utils from '@eitje/utils'

const ActiveCell = ({value}) => {
	if (value == 3) return t('common.never')
	return value == 1 ? t('yes') : t('no')
}

const BalanceCell = ({value, item, date}) => {
	const {shifts = [], leave = []} = item
	return (
		<>
			<BasicTableTimeStringCell value={value} />
			{shifts.length > 0 && (
				<InfoPopout
					onClick={stopLink}
					PopoutComponent={BasicPopout}
					warning
					title={t('balance.unapproved_popout.title')}
					body={<UnapprovedBody date={date} user={item} shifts={shifts} />}
				/>
			)}

			{leave.length > 0 && (
				<InfoPopout
					onClick={stopLink}
					PopoutComponent={BasicPopout}
					title={t('balance.unapproved_leave_popout.title')}
					body={<UnapprovedLeaveBody user={item} date={date} leave={leave} />}
				/>
			)}
		</>
	)
}

const UnapprovedLeaveBody = ({user, date, leave}) => {
	return (
		<Layout direction="vertical" gap={20}>
			<Text styles={{hyphens: 'auto'}}>{t('balance.unapproved_leave_popout.body', {user, count: leave.length, date})}</Text>
			<LeaveList leave={leave.sortBy('start_datetime', ['desc'])} />
		</Layout>
	)
}

const UnapprovedBody = ({user, shifts, date}) => {
	const totalHours = shifts._map('total_mins').sum()
	return (
		<Layout direction="vertical" gap={20}>
			<Text styles={{hyphens: 'auto'}}>{t('balance.unapproved_popout.body', {user, hours: totalHours, date})}</Text>
			<ShiftList shifts={shifts.sortBy('date', ['desc'])} />
		</Layout>
	)
}

const columns = [
	{accessor: 'full_name', tooltip: true},
	{accessor: 'balance', Cell: BalanceCell, tooltip: true},
	{accessor: 'active', Cell: ActiveCell, tooltip: true},
]

const outOfServicePlaceholder = {
	animation: 'waving-people',
	name: 'users_out_of_service',
}

export const UserTable = ({users, isActive, kind, hasFiltered, date, ...rest}) => {
	const empTypes = EmploymentType.all()
	const hasLeave = empTypes.some(e => e.hasLeave())

	const fixedType = empTypes.find(e => e.isFixed())
	const flexType = empTypes.find(e => e.isFlex())

	const {out_of_service} = useQueryParams()

	const dates = useBalanceStartDateRaw()

	const noLeavePlaceholder = {
		name: 'balance.no_leave',
		docsId: '6019469',
		children: [
			<EitjeButton iconLeft="three-sliders" modalLink={`/forms/employment_types/${fixedType.id}`} t="setup_fixed_leave" />,
			<EitjeButton iconLeft="three-sliders" modalLink={`/forms/employment_types/${flexType.id}`} t="setup_flex_leave" />,
		],
		animation: 'calendar',
	}

	const noDatePlaceholder = {
		name: 'balance.no_date',
		docsId: '6019469',
		children: <EitjeButton iconLeft="three-sliders" colorSet="solid" modalLink="/balance/setup" t="setup" />,
		animation: 'calendar',
	}

	let placeholderProps
	if (out_of_service) placeholderProps = outOfServicePlaceholder
	if (kind == 'leave' && !hasLeave) placeholderProps = noLeavePlaceholder
	if (!utils.exists(dates)) placeholderProps = noDatePlaceholder

	const extraName = out_of_service ? 'out_of_service' : 'in_service'

	const data = !hasLeave && kind == 'leave' ? [] : users

	return (
		<BasicTable
			RowWrapper={({item, ...rest}) => <Link to={item.id} {...rest} />}
			hasFiltered={hasFiltered}
			layoutProps={props => props.item.active == 3 && {colorSet: 'disabled'}}
			tooltip={props => props.item.active == 3 && t('basic_tables.balance_index.inactive', {user: props.item})}
			date={date}
			endOfContentPlaceholder
			defaultSort={'active'}
			placeholderProps={placeholderProps}
			{...rest} // name is passed as null (i think layout does that)
			name={[`balance_index.${kind}`, `balance_index.${extraName}`, 'balance_index']}
			data={isActive ? data : []}
			columns={columns}
		/>
	)
}
