import useParams from 'hooks/use_params'
import {Redirect} from 'components/routing'
import useShared from 'hooks/use_shared'
import {LeaveRequest} from 'models/index'
import {formMapper} from 'cores/leave/index'
import Add from './index'
import {useGet, useHasRole} from 'hooks/index'

export const Edit = () => {
	const {id} = useParams()
	const req = LeaveRequest.find(id)
	if (req.id) return <InnerEdit req={req} />

	return <Redirect to="/leave" />
}

const InnerEdit = ({req}) => {
	const {me} = useShared()
	const {data} = useGet(req.buildUrl('audits'))
	const hasRole = useHasRole('verlof', req.relevantEnvironmentIds())
	const isSelf = me.id == req.user_id
	if (!hasRole && !isSelf) return <Redirect to="/leave" />

	const {audits = []} = data
	return <Add request={{...req, audits}} initialValues={formMapper(req)} />
}

export default Edit
