import {ProtectedRoute} from 'components/private_route'
import {
	OvertimeBalance,
	LeaveBalance,
	OvertimeBill,
	LeaveBill,
	NewAdjustment,
	ShowAdjustment,
	LeaveBalanceShow,
	OvertimeBalanceShow,
	SetupBalance,
} from 'cores/balance'

const NewOvertimeAdjustment = props => <NewAdjustment {...props} kind="overtime" />
const NewLeaveAdjustment = props => <NewAdjustment {...props} kind="leave" />

const balanceForegroundRoutes = [
	<ProtectedRoute roleInAnyEnv="uren_accorderen" path="/balance/leave" exact component={LeaveBalance} />,
	<ProtectedRoute roleInAnyEnv="uren_accorderen" path="/balance/overtime" exact component={OvertimeBalance} />,
	<ProtectedRoute path="/balance/overtime/:user_id" component={OvertimeBalanceShow} />,
	<ProtectedRoute path="/balance/leave/:user_id" component={LeaveBalanceShow} />,
]

const balanceBackgroundRoutes = [
	<ProtectedRoute roleInAnyEnv="uren_accorderen" path="/balance/overtime/:user_id/adjustments/new" component={NewOvertimeAdjustment} />,
	<ProtectedRoute roleInAnyEnv="uren_accorderen" path="/balance/leave/:user_id/adjustments/new" component={NewLeaveAdjustment} />,
	<ProtectedRoute
		roleInAnyEnv="uren_accorderen"
		component={OvertimeBill}
		path="/balance/overtime/:user_id/periods/:period_type/:period_id"
	/>,
	<ProtectedRoute roleInAnyEnv="uren_accorderen" component={LeaveBill} path="/balance/leave/:user_id/periods/:period_type/:period_id" />,
	<ProtectedRoute roleInAnyEnv="uren_accorderen" component={ShowAdjustment} path="/balance_adjustments/:id" />,
	<ProtectedRoute roleInAnyEnv="uren_accorderen" component={SetupBalance} path="/balance/setup/" />,
]

export {balanceForegroundRoutes, balanceBackgroundRoutes}
