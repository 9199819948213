import {AttachmentButton, EitjeButton, Layout, Title} from 'common/components'
import {history} from 'index'
import {useTrainingContext, NAMESPACE} from 'cores/knowledge'
import {t} from 'initializers/i18n'

export const PageHeader = () => {
	const {title, activeResource, selectedAnswer, handleNext, testRun} = useTrainingContext()

	const isQuiz = activeResource.tableName === 'quizItems'
	const nextDisabled = isQuiz && selectedAnswer == null && t(`${NAMESPACE}.no_answer`)

	const attachments = activeResource.attachments?.map(({name, url}) => ({
		title: name,
		url,
	}))

	return (
		<Layout className="training-header" padding="24 - 8 -" margin="- auto" horizontal="spaceBetween" maxWidth={1000} borderBottom={isQuiz}>
			<Title>{title}</Title>
			<Layout>
				{testRun && (
					<EitjeButton
						name={NAMESPACE}
						t="exit_dry_run"
						iconLeft="arrow-undo"
						colorSet="color-bordered-fill"
						baseColor="orange"
						onClick={history.goBack}
					/>
				)}
				<AttachmentButton attachments={attachments} />
				<EitjeButton t="common.next" iconLeft="arrow-right" colorSet="solid" onClick={handleNext} disabled={nextDisabled} />
			</Layout>
		</Layout>
	)
}
