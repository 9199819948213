import utils from '@eitje/web_utils'
import {FilterTrigger} from '@eitje/web_components'
import {useAdminOrgTeams} from 'hooks/use_admin_teams'
import useShared from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import React, {useMemo, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {roleEnvSelector} from 'selectors/new_settings'
import {crazyFilter} from 'selectors/records'
import {Team} from 'models'
import {falsy} from 'constants/general'
import {ListPicker} from 'common/components'

const schedulableQuery = {locked: falsy, onroosterbaar: falsy}

export const useTeamFilter = (
	items,
	{
		dropdown = true,
		initialValue = [],
		form = false,
		Trigger = !form ? FilterTrigger : undefined, // Explicit undefined is needed for default Trigger in DropdownListPicker to work
		role,
		allTeams,
		afterChange = _.noop,
		filterTeam,
		schedulableTeams,
		overwriteTeams,
		...rest
	} = {},
) => {
	let _items = items
	const {orgEnvs, multiEnvOrg} = useShared()
	const elementRef = useRef()
	const [teamFilter, setTeamFilter] = useState(initialValue)
	let teams = useAdminOrgTeams()
	const _allTeams = Team.all()
	const schedulableTeamsList = Team.where(schedulableQuery)

	if (overwriteTeams) teams = overwriteTeams
	if (allTeams) teams = _allTeams
	if (schedulableTeams) teams = schedulableTeamsList
	if (filterTeam) {
		teams = teams.filter(t => filterTeam(t))
	}
	let teamSelectProps = {}

	if (utils.exists(teamFilter) && utils.exists(items)) {
		_items = crazyFilter(items, {team_ids: teamFilter})
	}

	const roleEnvs = useSelector(state => roleEnvSelector(state, role))

	if (role) {
		teams = teams.filter(t => roleEnvs.includes(t.environment_id))
	}

	const teamEnvs = teams._map('environment_id').uniq()

	if (multiEnvOrg && teamEnvs.length > 1) {
		teamSelectProps = {groupField: 'environment_id', groupItems: orgEnvs, groupLabelField: 'naam', groupReadOnly: true}
	}

	const teamIds = teams.map(t => t.id)

	const _setTeamFilter = value => {
		setTeamFilter(value)
		afterChange(value, {teamIds})
	}

	const clearListPicker = elementRef?.current?.clearListPicker

	const filter = (
		<ListPicker
			dropdown={dropdown}
			placeholder={t('teams')}
			Trigger={Trigger}
			collapseGroups
			showSearch={items.length > 10}
			name="teams"
			title={t('teams')}
			labelVisible
			useSearch={false}
			labelField="naam"
			hasIcon="/images/location.png"
			items={teams}
			onChange={_setTeamFilter}
			hideEasyRemovePopout
			value={teamFilter}
			form={form}
			{...teamSelectProps}
			{...rest}
			forwardRef={elementRef}
		/>
	)

	const activeTeamIds = teamFilter.length == 0 ? teamIds : teamFilter

	const lastTeamsUpdate = _.max(teams._map('updated_at'))
	const activeTeams = useMemo(() => teams.filter(team => activeTeamIds.includes(team.id)), [lastTeamsUpdate, JSON.stringify(activeTeamIds)])

	return {
		clearListPicker,
		items: _items,
		teams,
		activeTeamIds,
		activeTeams,
		teamIds,
		filter,
		resetTeamFilter: () => _setTeamFilter([]),
		setFilter: setTeamFilter,
		hasFiltered: Boolean(teamFilter.length && !_.isEqual(teamFilter?.sort(), teamIds?.sort())),
		teamFilterState: teamFilter,
	}
}

export const labelWithShiftCount = (str, item, shifts) => {
	const shiftAmount = shifts[item.id]?.length || 0
	return `${str} (${shiftAmount})`
}

export default useTeamFilter

const selProps = {
	field: 'team_ids',
	required: true,
}

export const useTeamFilterForm = props => useTeamFilter([], {...selProps, ...props, form: true})
