import {makeNewRegisteredField, useForm} from '@eitje/form'
import {FormRow} from '@eitje/form-fields-web'
import utils from '@eitje/utils'
import {Icon} from '@eitje/web_components'
import {useFormData} from 'hooks'
import pluralize from 'pluralize'
import {useEffect, useState} from 'react'
import {t} from 'initializers/i18n'
import {EitjeButton, Layout, Text} from 'common/components'

const buildItem = (field, item) => {
	const id = item?.id || utils.randomId()
	return {id, field: `arrayField-${field}-${id}`}
}

const getInitial = (data, field, minItems) => {
	if (data[field]) {
		const extraItems = Math.max(0, minItems - data[field].length)
		return [...data[field], ...Array(extraItems)]
	}
	return [...Array(minItems)]
}

let ArrayField = ({minItems = 1, maxItems = 100, valueField = 'value', onRemove = _.noop, field, ...rest}) => {
	const data = useFormData()
	const {setValues} = useForm()
	const initialItems = getInitial(data, field, minItems).map(item => buildItem(field, item))
	const [items, setItems] = useState(initialItems)
	const fieldIds = items._map('field')

	const relevantData = _.pickBy(data, (val, key) => fieldIds.includes(key) && val != null && val != undefined)
	const joined = Object.values(relevantData).join('') // expects string values

	useEffect(() => {
		if (data[field]) {
			const initialObject = items.reduce((acc, cum) => {
				acc[cum.field] = data[field].find(item => item.id == cum.id)?.[valueField]
				return acc
			}, {})
			setValues(initialObject)
		}
	}, [])

	useEffect(() => {
		if (joined != '') {
			const newVals = items
				.filter(i => relevantData[i.field])
				.map(i => ({
					id: i.id,
					[`${valueField}`]: data[i.field],
				}))
			rest.onChange(newVals)
		}
	}, [joined])

	const addItem = () => setItems(items => [...items, buildItem(field)])

	const removeItem = id => {
		setItems(items => items.filter(i => i.id != id))
		return onRemove(id)
	}

	const allowRemove = minItems < items.length
	const showAddItemRow = maxItems > items.length
	return (
		<>
			{items.map((i, idx) => (
				<ArrayRow
					{...rest}
					{...i}
					idx={idx}
					translationField={pluralize.singular(field)}
					allowRemove={allowRemove}
					remove={() => removeItem(i.id)}
				/>
			))}
			{showAddItemRow && <AddItemRow {...rest} onClick={addItem} />}
		</>
	)
}

const ArrayRowDeleteButton = props => <EitjeButton className="delete-row-button" iconLeft="trash" baseColor="red" {...props} />

const ArrayRow = ({Component, translationField, remove, idx, field, allowRemove, componentProps, name, ...rest}) => {
	let label = t(`form.${name}.fields.${translationField}.label`)
	label = `${label} ${idx + 1}`
	return (
		<FormRow>
			<Component {...componentProps} label={label} rightElement={allowRemove && <ArrayRowDeleteButton onClick={remove} />} field={field} />
		</FormRow>
	)
}

const AddItemRow = ({onClick, addItemLabel = 'add row'}) => {
	return (
		<Layout horizontal="center" width="full" height={40} onClick={onClick} colorSet="color-bordered-fill" gap={6}>
			<Icon name="plus" size={10} />
			<Text small t={addItemLabel} />
		</Layout>
	)
}

ArrayField = makeNewRegisteredField(ArrayField, {})
export {ArrayField}
