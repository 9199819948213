import {Input, FormRow} from '@eitje/form-fields-web'
import {ListPicker} from 'common/components'
import {ArrayField} from 'components/form/index'
import {ModalWithNewForm} from 'components/form/index'
import {useFormData} from 'hooks'
import {history} from 'index'
import {NAMESPACE} from 'cores/knowledge'
import {t} from 'initializers/i18n'
import {useEffect} from 'react'
import {useForm} from '@eitje/form'

const answerFieldProps = {maxLength: 70, required: true}

const CorrectAnswerField = ({valueField}) => {
	const {setValues} = useForm()
	const {answers, correct_answer} = useFormData()
	const showCorrectAnswerField = answers?.some(Boolean)

	useEffect(() => {
		if (!correct_answer) return

		const correctAnswerInAnswers = answers.find(answer => answer.id === correct_answer)

		if (!correctAnswerInAnswers) {
			setValues({correct_answer: null})
		}
	}, [answers, correct_answer])

	if (!showCorrectAnswerField) return null

	return (
		<ListPicker
			form
			field="correct_answer"
			valueField="id"
			labelField={valueField}
			items={answers}
			dropdown
			required
			single
			noSort
			label={t(`${NAMESPACE}.correct_answer_field`)}
		/>
	)
}

export const QuizItemModal = ({name, quizItem = {}, onSubmit, onDel}) => {
	const valueField = 'text'

	const {question, answers, correct_answer} = quizItem
	const initialValues = {
		question,
		answers: answers?.map((answer, index) => ({
			id: index.toString(),
			[valueField]: answer,
		})),
		correct_answer: correct_answer?.toString(),
	}

	const handleSubmit = async ({question, answers, correct_answer}) => {
		const flatAnswers = answers.map(answer => answer[valueField])
		const correctAnswer = answers.findIndex(answer => answer.id === correct_answer)

		return onSubmit({question, answers: flatAnswers, correct_answer: correctAnswer})
	}

	return (
		<ModalWithNewForm
			name={name}
			width={500}
			initialValues={initialValues}
			onSubmit={handleSubmit}
			afterSubmit={history.goBack}
			goBackToForeground
			modalProps={{docsId: 6022818, onDel}}
		>
			<FormRow>
				<Input
					field="question"
					required
					maxLength={120}
					textarea
					autoSize={{minRows: 1, maxRows: 4}}
					label={t(`${NAMESPACE}.quiz_question`)}
				/>
			</FormRow>
			<ArrayField
				field="answers"
				valueField={valueField}
				minItems={2}
				maxItems={5}
				addItemLabel={`${NAMESPACE}.add_quiz_answer`}
				Component={Input}
				componentProps={answerFieldProps}
			/>
			<FormRow>
				<CorrectAnswerField valueField={valueField} />
			</FormRow>
		</ModalWithNewForm>
	)
}
