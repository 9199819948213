import {DocsPopout, Icon, Layout, Text, IconCircle} from 'common/components'
import {useBasicTableContext} from 'common/components/basic_table'
import './index.less'

export const BasicTableHeader = () => {
	const {columns, sortField, changeSort, setSortDirection, defaultSort, basicTableT, cssColumnWidth} = useBasicTableContext()

	const handleClick = column => {
		if (column === sortField) {
			setSortDirection()
		} else {
			setSortDirection('asc')
			changeSort(column)
		}
	}

	return (
		<Layout className="basic-table-header" width="100%" gap={0}>
			{columns.map((column, i) => {
				const {accessor, docsId, tooltip} = column
				const clickColumn = () => handleClick(accessor)
				const hasPopout = tooltip || docsId

				return (
					<Layout
						data-intercom-target={`basic-table-column-${accessor}`}
						key={i}
						className="basic-table-header-cell"
						onClick={defaultSort && clickColumn}
						initialBackground={Colors.lightGrey}
						padding={16}
						height={40}
						width={cssColumnWidth}
					>
						<SortIcon column={column} />
						<Layout>
							<Text black truncate semiBold noTextSelect>
								{basicTableT(`${accessor}.title`)}
							</Text>
							{hasPopout && <BasicTablePopout docsId={docsId} basicTableT={basicTableT} column={accessor} />}
						</Layout>
					</Layout>
				)
			})}
		</Layout>
	)
}

// TO DO: throw component away once we have implemented the BasicPopouts into Text
const BasicTablePopout = ({children, docsId, basicTableT, column}) => {
	const title = basicTableT([`${column}.popout.title`, `${column}.title`])
	const body = basicTableT(`${column}.popout.body`)
	return (
		<DocsPopout title={title} body={body} docsId={docsId} trigger="hover">
			<IconCircle name="question-mark" baseColor="pink" />
		</DocsPopout>
	)
}

const SortIcon = ({column}) => {
	const {sortDirection, sortField} = useBasicTableContext()
	if (column.accessor !== sortField) return null
	return <Icon name={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} size={14} />
}
