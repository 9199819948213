import {useSimpleContext} from 'contexts/simple'
import {t} from 'initializers/i18n'
import {LeaveRequest} from 'models'
import {Bill, Divider, Row, SignedRow} from './index'
import utils from '@eitje/utils'

export const LeaveBill = () => {
	return <Bill hasWarning={hasWarning} CalculateExtra={CalculateExtra} WarningExtra={WarningExtra} PeriodExtra={PeriodExtra} kind="leave" />
}

// opgebouwd over contract uren
// opgebouwd over gewerkte uren

const CalculateExtra = ({leave_requests = [], separate_leave, worked = {}, contract = {}, leave = 0}) => {
	const requests = leave_requests.map(l => new LeaveRequest(l))
	const total = leave
	worked = transformBuiltUp(worked)
	contract = transformBuiltUp(contract)
	const {expanded} = useSimpleContext()

	const contractExpanded = expanded['buildup_contract']
	const workedExpanded = expanded['buildup_worked']

	const topRowExpanded = contractExpanded || workedExpanded

	return (
		<>
			{utils.exists(contract.factor) && (
				<SignedRow id="buildup_contract" label={t('common.builtup_over_contract_hours')} value={contract.builtUp}>
					<SignedRow label={t('hoursOnContract')} value={contract.total} />
					<Row sign="*" label={t('leave_factor')} value={contract.factor} />
				</SignedRow>
			)}

			{topRowExpanded && <Divider />}

			{worked.factor && (
				<SignedRow id="buildup_worked" label={t('common.builtup_over_worked_hours')} value={worked.builtUp}>
					<SignedRow label={t('hoursWorked')} value={worked.total} />
					<Row sign="*" label={t('leave_factor')} value={worked.factor} />
				</SignedRow>
			)}

			{!topRowExpanded && expanded['leave'] && <Divider />}

			<SignedRow id="leave" label={t('leave.taken')} value={-total}>
				{requests.map(l => (
					<SignedRow value={l.tot_mins} label={l.toString()} />
				))}
				{!!separate_leave && <SignedRow value={separate_leave} label={'losse uren'} />}
			</SignedRow>
		</>
	)
}

const transformBuiltUp = obj => {
	return {...obj, factor: obj.factor == 'multiple' ? t('balance.multiple_factors') : obj.factor, builtUp: obj.total * obj.factor}
}

const PeriodExtra = ({leave_contract_days = 0, total_days}) => {
	if (!total_days) return null
	const noLeaveContractDays = total_days - leave_contract_days
	return (
		<>
			<Row label={t('balance.bill.leave_contract_days')} value={leave_contract_days} />
			{noLeaveContractDays > 0 && <Row label={t('balance.bill.no_leave_contract_days')} value={noLeaveContractDays} />}
		</>
	)
}

const hasWarning = ({unaccorded_leave_shift_mins, pending_leave_mins}) =>
	utils.exists(unaccorded_leave_shift_mins) || utils.exists(pending_leave_mins)

const WarningExtra = ({unaccorded_leave_shift_mins, pending_leave_mins}) => {
	return (
		<>
			{utils.exists(pending_leave_mins) && <SignedRow label={t('leave.unapproved_requests')} value={pending_leave_mins} />}
			{utils.exists(unaccorded_leave_shift_mins) && <SignedRow label={t('leave.unapproved_hours')} value={unaccorded_leave_shift_mins} />}
		</>
	)
}
