import {useState, useRef} from 'react'
import useOutsideClick from 'hooks/use_outside_click'

const classNames = ['ant-picker-dropdown', 'ant-picker-cell-inner']

export const useEitjeDatePicker = () => {
	const [datePopoutVisible, setDatePopoutVisible] = useState(false)
	const outsideClickRef = useRef(null)
	useOutsideClick(outsideClickRef, () => setDatePopoutVisible(false), {classNames, checkParents: true})

	return {datePopoutVisible, setDatePopoutVisible}
}
