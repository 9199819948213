import ActiveRecord from './active_record/base'

class EmploymentType extends ActiveRecord {
	static tableName = 'employmentTypes'
	static defaultJoins = ['environments']

	sanitizedName() {
		if (this.name == 'Nul uren') return 'flex'
		if (this.name == 'Uren contract') return 'fixed'
		if (this.name == 'Payroll') return 'payroll'
		return 'self_employed'
	}

	hasLeave() {
		return this.leave_registration != 'no_leave'
	}

	isFlex() {
		return this.sanitizedName() == 'flex'
	}

	isFixed() {
		return this.sanitizedName() == 'fixed'
	}

	hasBalance(kind) {
		if (kind == 'leave') return this.hasLeave()
		return this.hasOvertime()
	}

	hasOvertime() {
		return !!this.overtime_registration
	}
}

export default EmploymentType
