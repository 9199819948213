import {createContext, useContext, useCallback, useMemo, useRef} from 'react'
import {useArticleRoleMessage} from 'cores/knowledge'

const ArticleContext = createContext()
const {Provider} = ArticleContext

export const ArticleProvider = ({article, readOnly, children}) => {
	const updateRef = useRef()
	const editDisabled = useArticleRoleMessage(article, readOnly)

	// assigning update function to a ref here, because we are calling this method when unmounting the page
	// there is and edge-case that when an article is destroyed and we navigate to the index page, this method
	// is also called. But the resource is gone at that time, so we need to make sure to not call an update on
	// the destroyed resource.
	updateRef.current = article

	const updateArticle = useCallback(
		async data => {
			const {id: currentArticleId, content: currentContent, update} = updateRef?.current || {}
			const noContent = !data.content
			const notSameArticleId = currentArticleId !== article.id
			const sameContent = currentContent === data.content

			if (editDisabled || noContent || !update || notSameArticleId || sameContent) return

			// only update if the ref contains the update method of the current article
			return await update(data, {
				extraParams: {skipSuccessNotif: true, doNotLoad: true},
			})
		},
		[article, editDisabled],
	)

	const {pdf_content, pdf_leading} = article
	const isPDF = !!pdf_content && pdf_leading
	const isRTE = !isPDF

	const state = useMemo(() => ({article, editDisabled, updateArticle, isPDF, isRTE}), [article, editDisabled, updateArticle, isPDF, isRTE])

	const noArticle = _.isEmpty(article)
	if (noArticle) return null

	return <Provider value={state} children={children} />
}

export const useArticleContext = () => useContext(ArticleContext)
