import {useParams} from 'hooks'
import {QuizItem} from 'models'
import {history} from 'index'
import {QuizItemModal} from '../components'

export const EditQuizItem = () => {
	const {id} = useParams()
	const quizItem = QuizItem.find(id)

	const handleSubmit = async data => await quizItem.update(data)

	const handleDelete = () => {
		quizItem.destroy()
		history.replace(`/topics/${quizItem.topic_ids[0]}`)
	}

	return <QuizItemModal name="edit_quiz_item" quizItem={quizItem} onSubmit={handleSubmit} onDel={handleDelete} />
}
