import {useEffect} from 'react'
import {PageLayout, Layout} from 'common/components'
import {Channel, ChannelList, JumpToMessageProvider, useChatWindowContext, ChatClientProvider, useSetInitialChannel} from 'cores/chat'
import {store} from 'index'
import {PageHeader} from './page_header'
import {useChatContext} from 'stream-chat-react'

const bodyLayoutProps = {
	gap: 0,
	direction: 'vertical',
	height: 'full',
	padding: '- 32',
	margin: 'auto',
	maxWidth: 1264, // 1200px + 2*32px padding
}

const Chat = () => {
	useSetInitialChannel()
	const {setActiveChannel} = useChatContext()
	const {chatWindowOpen, closeChatWindow} = useChatWindowContext()

	useEffect(() => {
		// reset active channel on dismount
		return () => {
			store.dispatch({type: 'SET_INITIAL_CHANNEL', initialChannel: ''})
			setActiveChannel(null)
		}
	}, [])

	useEffect(() => {
		// close chat window in case chat page is rendered
		chatWindowOpen && closeChatWindow()
	}, [chatWindowOpen])

	return (
		<PageLayout name="chat" bodyLayoutProps={bodyLayoutProps} unsetPagePadding>
			<PageHeader />
			<Layout grow width="full" gap={0} minHeight={0} vertical="unset">
				<JumpToMessageProvider>
					<ChannelList borderLeft borderRight minWidth={250} width="30%" />
					<Channel minWidth={550} width="70%" />
				</JumpToMessageProvider>
			</Layout>
		</PageLayout>
	)
}

export const ChatPage = () => (
	<ChatClientProvider>
		<Chat />
	</ChatClientProvider>
)
