import utils from '@eitje/utils'
import ActiveRecord from './active_record/base'

class WorkWeek extends ActiveRecord {
	static tableName = 'workWeeks'

	totalMins = () => utils.weekdays.map(w => this[w]).sum()
	totalDays = () => utils.weekdays.filter(w => this[w] > 0).length
}

export default WorkWeek
