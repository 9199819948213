import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import useParams from 'hooks/use_params'

export const useShow = (kind, {idField = 'id', opts = {}} = {}) => {
	const prms = useParams()
	const id = prms[idField]
	return useFind(kind, id, opts)
}

export default useShow
