import {useFind} from '@eitje/easy_api'
import {BasicPopout} from '@eitje/web_components'
import {InfoPopout, Layout, Text, BasicTableTimeStringCell, usePageContext} from 'common/components'
import {LeaveList, ShiftList} from 'cores/balance'
import {EventRow} from 'cores/balance/components/event_row'
import {stopLink} from 'helpers'
import {date} from 'initializers/date'
import {t} from 'initializers/i18n'
import _date from 'initializers/date'

const useIsOutsideBounds = date => {
	const {envStartDate} = usePageContext()
	date = _date(date)
	return envStartDate.isAfter(date) || _date().isBefore(date)
}

const ContractEnd = ({item}) => {
	const {kind, user} = usePageContext()

	const actualDate = item.labelDate || item.date // sometimes we flunk date for basic table sorting purposes

	const isOutsideBounds = useIsOutsideBounds(item.labelDate || item.date)

	const suffix = isOutsideBounds ? `contract_end_before_start` : `contract_end`
	const _t = {key: `basic_tables.balance_show.${suffix}`, kind, item: {...item, date: item.labelDate || item.date}}

	let conditionalProps = {}

	const contract = user.contractHolder(actualDate)

	if (item.balanceEnded) conditionalProps.background = Colors.lightRed

	return <EventRow modalLink={`/forms/contracts/${contract?.id}/details`} t={_t} {...conditionalProps} />
}

const AdjustmentRow = ({item}) => {
	const author = useFind('users', item.author_id)
	const to = `/balance_adjustments/${item.id}`
	const key = author.id ? `adjustment` : `adjustment_no_user`
	const _t = {key: `basic_tables.balance_show.${key}`, item, user: author}
	return <EventRow modalLink={to} t={_t} />
}

const InitialBalance = ({item}) => {
	const children = `${item.label}: ${t('basic_tables.balance_show.initial', {item: {amt: 0, ...item}})}`

	let conditionalProps = {}
	if (item.terminatedTooltip) conditionalProps.background = Colors.lightGrey

	return <EventRow modalLink="/balance/setup" children={children} {...conditionalProps} />
}

const ContractStart = ({item}) => {
	const {kind, user} = usePageContext()
	const {active, type} = item
	const actualDate = item.labelDate || item.date // sometimes we flunk date for basic table sorting purposes
	const isOutsideBounds = useIsOutsideBounds(actualDate)

	const _active = active ? t('common.active') : t('common.inactive')
	const contractType = t(`common.${type}_contract`)

	const suffix = isOutsideBounds ? `contract_start_before_start` : `contract_start`

	const key = `basic_tables.balance_show.${suffix}`
	const _t = {key, contractType, kind, active: _active, item: {...item, date: actualDate}}

	const contract = user.contractHolder(actualDate)

	let conditionalProps = {}
	if (item.balanceEnded) conditionalProps.background = Colors.lightRed

	return <EventRow modalLink={`/forms/contracts/${contract?.id}/details`} t={_t} {...conditionalProps} />
}

const TerminationDate = ({item}) => {
	const _t = {key: 'basic_tables.balance_show.termination_date', date: item.label}
	return <EventRow className="default-cursor-important" t={_t} background={Colors.lightRed} />
}

const BalanceCell = props => {
	const {item} = props
	const {leave = [], shifts = []} = item
	return (
		<>
			<BasicTableTimeStringCell {...props} />
			{shifts.length > 0 && (
				<InfoPopout
					onClick={stopLink}
					PopoutComponent={BasicPopout}
					title={t('balance.unapproved_popout.title')}
					body={<UnapprovedShifts {...props} shifts={shifts} />}
				/>
			)}

			{leave.length > 0 && (
				<InfoPopout
					onClick={stopLink}
					PopoutComponent={BasicPopout}
					title={t('balance.unapproved_leave_popout.title')}
					body={<UnapprovedLeaveBody {...props} leave={leave} />}
				/>
			)}
		</>
	)
}

const UnapprovedLeaveBody = ({user, item}) => {
	const {leave} = item
	return (
		<Layout direction="vertical">
			<Text>{t('balance.unapproved_leave_user_popout.body', {user, period: item.label, count: leave.length, date})}</Text>
			<LeaveList leave={leave.sortBy('start_datetime', ['desc'])} />
		</Layout>
	)
}

const UnapprovedShifts = ({item, user}) => {
	const {shifts} = item
	const totalHours = shifts._map('total_mins').sum()
	return (
		<Layout direction="vertical">
			<Text>{t('balance.unapproved_user_popout.body', {user, hours: totalHours, period: item.label})}</Text>
			<ShiftList shifts={shifts.sortBy('date', ['desc'])} />
		</Layout>
	)
}

const DiffCell = ({item}) => {
	if (!item.balanceActive) return <Text>{t('balance.no_active_contract')}</Text>
	return <BasicTableTimeStringCell value={item.diff} />
}

const PeriodCell = ({value, item}) => {
	const {endDate} = usePageContext()
	return (
		<Text truncate>
			{value} {!endDate && item.isLast && `(${t('common.current_period')})`}
		</Text>
	)
}

export const columns = [
	{accessor: 'label', Cell: PeriodCell, tooltip: true},
	{accessor: 'diff', Cell: DiffCell, tooltip: true},
	{accessor: 'balance', Cell: BalanceCell, tooltip: true},
]

export const textRows = [
	{id: 'adjustment', Component: AdjustmentRow},
	{id: 'initial_balance', Component: InitialBalance},
	{id: 'contract_start', Component: ContractStart},
	{id: 'contract_end', Component: ContractEnd},
	{id: 'termination_date', Component: TerminationDate},
]
