import {EitjeAvatar, Layout, Text} from 'common/components'

export const UserListItem = ({user, onClick, ...rest}) => {
	const handleClick = () => onClick(user.id)

	return (
		<Layout minHeight={40} gap={12} padding="10 12" width="full" onClick={onClick && handleClick} colorSet={!!onClick} {...rest}>
			<EitjeAvatar user={user} />
			<Text truncate truncateTooltip={false}>
				{user.full_name}
			</Text>
		</Layout>
	)
}
