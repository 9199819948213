import {useLayoutEffect} from 'react'
import {PageLayout} from 'common/components'
import {useParams} from 'hooks'
import {Info} from 'models'
import {ArticleProvider} from 'cores/knowledge'
import {navigate} from 'components/routing'
import {PageHeader} from './page_header'
import {Article} from './article'

const bodyLayoutProps = {
	height: 'full',
	direction: 'vertical',
}

export const ArticlePage = () => {
	const {id} = useParams()
	const article = Info.find(id)

	const noArticle = _.isEmpty(article)

	useLayoutEffect(() => {
		// if a user is on a /article/:id page and switches env
		// no article will be returned. In that case we redirect them.
		if (noArticle) return navigate('/topics')
	}, [noArticle])

	return (
		<ArticleProvider article={article}>
			<PageLayout name="article" bodyLayoutProps={bodyLayoutProps} headers={<PageHeader />} unsetPagePadding bodyWidth={1000}>
				<Article />
			</PageLayout>
		</ArticleProvider>
	)
}
