import createCachedSelector from 'initializers/selectors'
import {orgEnvsSelector} from 'selectors/records'
import {all} from '@eitje/easy_api'

export const activeUsersSelector = createCachedSelector(
	orgEnvsSelector,
	state => all(state, 'users'),
	(orgEnvs, users) => {
		return users.where({environment_ids: orgEnvs.ids()})
	},
)
