import utils from '@eitje/web_utils'
import {requireProps, childrenWithClonedProps} from 'helpers'
import {FullScreenPageHeader, FloatingPageHeader} from './types'

export const PageHeader = ({type = 'fullScreen', className, height, left, right, center, ...props}) => {
	requireProps('PageHeader', {type})
	className = utils.makeCns('page-header', `page-header-${_.kebabCase(type)}`, className)

	// You can pass the PageHeader a size="small", or size="large" prop, which sizes all the buttons and triggers within the header,
	// without you having to set that manually for each header item.
	left = left && format(left, {height})
	center = center && format(center, {height})
	right = right && format(right, {height})

	const _props = {className, left, right, center, height, ...props}

	switch (type) {
		case 'fullScreen':
			return <FullScreenPageHeader {..._props} />
		case 'floating':
			return <FloatingPageHeader {..._props} />
	}
}

function format(children, props) {
	return childrenWithClonedProps({
		children,
		props: {...props, trigger: 'hover'},
	})
}
