import ActiveRecord from './active_record/base'

class BalanceAdjustment extends ActiveRecord {
	static tableName = 'balanceAdjustments'
	static defaultJoins = ['users']

	revert() {
		const params = _.omit({...this, amt: -this.amt}, 'id')
		return BalanceAdjustment.create(params)
	}
}

export default BalanceAdjustment
