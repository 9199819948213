import {backend} from '@eitje/easy_api'
import {store} from 'index'

export const getTraining = async () => {
	const res = await backend.get(`training`)
	handleRes(res)
}

const handleRes = res => {
	if (res.ok) {
		const {items} = res.data
		store.dispatch({type: 'SET_VALUE', name: 'training', payload: res.data})
	}
}
