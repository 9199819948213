import {PrivateRoute, ProtectedRoute} from 'components/private_route'
import {
	ArticlePage,
	EditArticle,
	NewArticle,
	NewTopic,
	NewQuizItem,
	EditQuizItem,
	Results,
	TrainPage,
	TrainResults,
	EditTopic,
} from './pages'

export const knowledgeForegroundRoutes = [
	<PrivateRoute path="/articles/:id" component={ArticlePage} exact />,
	<PrivateRoute path="/topics/:id/train" component={TrainPage} />,
]

export const knowledgeBackgroundRoutes = [
	<ProtectedRoute path="/topics/new" component={NewTopic} exact roleInAnyEnv="manager" />,
	<ProtectedRoute path="/topics/:id/edit" component={EditTopic} roleInAnyEnv="manager" />,
	<ProtectedRoute path="/articles/new" component={NewArticle} exact roleInAnyEnv="manager" />,
	<ProtectedRoute path="/articles/:id/edit" component={EditArticle} exact roleInAnyEnv="manager" />,
	<ProtectedRoute path="/quiz_items/new" component={NewQuizItem} exact roleInAnyEnv="manager" />,
	<ProtectedRoute path="/quiz_items/:id" component={EditQuizItem} exact roleInAnyEnv="manager" />,
	<PrivateRoute path="/topics/:id/training_results" component={Results} />,
	<PrivateRoute path="/topics/:id/train/results" component={TrainResults} />,
]
