import {useEffect, useState} from 'react'
import {useChatContext} from 'stream-chat-react'
import {t} from 'initializers/i18n'

export const normalizeAttachment = ({type, title, fallback, image_url, asset_url, localMetadata}) => {
	switch (type) {
		case 'image':
			return {
				type,
				title: title || fallback || t('image'),
				url: image_url,
				metadata: localMetadata,
			}
		case 'audio':
			return {
				type,
				title: t('audioFile'),
				url: asset_url,
				metadata: localMetadata,
			}
		case 'file':
		case 'video':
			return {
				type,
				title: title || t(type),
				url: asset_url,
				metadata: localMetadata,
			}
	}
}

const DESC = -1
const SEARCH_LIMIT = 1000

export const useChannelAttachments = () => {
	const {client, channel} = useChatContext()
	const [attachments, setAttachments] = useState([])

	const {cid: fullChannelId} = channel || {}

	useEffect(() => {
		let isSubscribed = true

		if (!fullChannelId) return

		const fetchAttachments = async eventMessage => {
			const channelFilters = {cid: fullChannelId}
			const messageFilters = {attachments: {$exists: true}}
			const sort = {
				sort: {updated_at: DESC},
				limit: SEARCH_LIMIT,
			}

			const {results} = await client.search(channelFilters, messageFilters, sort)

			// get unique messages from api response and event
			let messages = results._map('message')
			if (eventMessage) {
				messages = [...messages, eventMessage].reduce((acc, message) => {
					const existingMessage = acc.some(o => o.id === message.id)
					if (!existingMessage) acc.push(message)
					return acc
				}, [])
			}

			if (isSubscribed && messages.length > 0) {
				const items = messages.flatMap(messageObj => messageObj.attachments)
				const normalizedItems = items.map(normalizeAttachment).filter(Boolean)
				setAttachments(normalizedItems)
			}
		}

		// reset attachments so that the UI updates immediately after switching channel
		if (isSubscribed) setAttachments([])
		fetchAttachments()

		const subscription = client.on('message.new', ({cid, message}) => {
			// refetch attachment when a new message with attachment in the current channel is received
			if (cid === fullChannelId && message.attachments.length) {
				fetchAttachments(message)
			}
		})

		return () => {
			isSubscribed = false
			subscription.unsubscribe()
		}
	}, [fullChannelId])

	return {attachments}
}
