import {Text} from '../text'
import './index.less'
import {Layout} from '../layout'

export const Label = ({colorSet = 'solid', height = 'regular', children, t, ...rest}) => {
	const hasText = t || children

	// Below settings are duplicated from EitjeButton and could be shared (but the button now lives in web_components).
	const isMini = height === 'mini'
	const isSmall = height === 'small'
	const _height = isMini ? 24 : isSmall ? 32 : 40
	const textSize = isMini ? 'small' : 'regular'
	const padding = isMini ? [0, 8] : [0, 16]

	// The padding is also shared with EitjeButton – except for the !hasText check.
	const _padding = hasText ? padding : 0

	// Explicit undefined is needed for correct width (apparently... old Chris comment).
	const width = !hasText ? 12 : undefined

	return (
		<Layout className="label" colorSet={colorSet} vertical="center" width={width} height={_height} padding={_padding} {...rest}>
			{hasText && <Text size={textSize} t={t} children={children} />}
		</Layout>
	)
}
