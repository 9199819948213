import {createContext, useContext} from 'react'
import {useParams} from 'hooks'
import {navigate} from 'components/routing'
import {useTopicWithResources} from 'cores/knowledge'

const TopicContext = createContext()
const {Provider} = TopicContext

export const TopicProvider = ({children}) => {
	const {id} = useParams()
	const topic = useTopicWithResources(id)

	if (!topic) return navigate('/topics')

	return <Provider value={topic} children={children} />
}

export const useTopicContext = () => useContext(TopicContext)
