import {PopoutCard} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {goBackToForeground as _goBackToForeground} from 'actions'
import {logout} from 'actions/auth'
import {changeLocale} from 'actions/settings'
import {startConversation} from 'actions/support'
import {DocsButton, EitjeButton, Text} from 'common/components'
import {ExtLink} from 'components/routing'
import {cannyLink, languages, salesPhoneNumber, salesPhoneNumberFormatted} from 'constants/general'
import {getCssVariables, isMobile} from 'helpers'
import {history} from 'index'
import i18n, {t, t as translate} from 'initializers/i18n'
import {forwardRef} from 'react'
import {useSelector} from 'react-redux'
import {getUserSetting} from 'selectors/new_settings'
import {DeletePopout, SubmitPopout} from '../basic_popout'
import {EitjeDropdown} from '../eitje_dropdown'

export const SHARED_SUPPORT_PROPS = {
	colorSet: 'solid',
	baseColor: 'pink',
}

export const AddButton = props => {
	return <EitjeButton data-intercom-target="add-button" className="add-button" iconLeft="plus-small" t="add" {...props} />
}

export const BackButton = ({goBackToForeground, onClick = goBackToForeground ? _goBackToForeground : history.goBack, ...rest}) => {
	return <EitjeButton className="back-button" iconLeft="arrow-left" onClick={onClick} t={'common.back'} {...rest} />
}

export const ClearButton = props => {
	return <EitjeButton iconLeft="cross-small" className="clear-button" {...props} />
}

const FilterAmount = ({amount}) => (
	<>
		: {amount} {t('common.hidden')}
	</>
)

export const ClearFiltersButton = ({clearFilters, filteredOutAmount = 0}) => {
	const showAmount = filteredOutAmount > 0
	return (
		<EitjeButton
			onClick={clearFilters}
			className="clear-filters-button"
			iconLeft="cross-circled"
			colorSet="color-bordered-outline"
			baseColor="red"
		>
			<span>filters</span>
			{showAmount && <FilterAmount amount={filteredOutAmount} />}
		</EitjeButton>
	)
}

export const ConfirmButton = ({onClick, confirmText, onVisibleChange, className, ...rest}) => {
	const {disabled} = rest

	const classNames = utils.makeCns('eitje-confirm-button', className)

	let condOpts = {}
	if (confirmText) condOpts['text'] = confirmText

	return (
		<SubmitPopout {...condOpts} disabled={disabled} onVisibleChange={onVisibleChange} onSubmit={onClick}>
			<EitjeButton className={classNames} {...rest} />
		</SubmitPopout>
	)
}

export const CreateButton = props => {
	return <EitjeButton className="create-button" iconLeft="plus" colorSet="solid" t="create" {...props} />
}

const buttons = {
	active: {
		title: 'active',
		icon: 'checked',
	},
	inActive: {
		title: 'create',
		icon: 'plus',
	},
}

export const CreateOrActiveButton = ({isActive, ...rest}) => {
	const activeButton = buttons[isActive ? 'active' : 'inActive']
	return <EitjeButton className="create-active-button" width={220} t={activeButton.title} iconRight={activeButton.icon} {...rest} />
}

export const DeleteButton = ({
	afterDel,
	nonDestructive,
	height,
	confirmText,
	confirmTitle,
	deleteButtonProps,
	t = 'delete',
	children,
	onDel,
	onClick,
	baseColor = 'red',
	disabled,
	...rest
}) => {
	if (!onDel) onDel = onClick

	const _onDel = async e => {
		await onDel(e)
		afterDel && afterDel()
	}

	let condOpts = {}
	if (nonDestructive) {
		condOpts['text'] = translate('popouts.delete.non_destructive_text')
	}
	if (confirmText) condOpts['text'] = confirmText
	if (confirmTitle) condOpts['title'] = confirmTitle
	if (deleteButtonProps) condOpts = {...condOpts, ...deleteButtonProps}

	return (
		<DeletePopout onDelete={_onDel} {...condOpts} hidden={disabled} {...rest}>
			<EitjeButton
				className="eitje-delete-button"
				height={height}
				iconLeft="trash"
				baseColor={baseColor}
				t={t}
				{...rest}
				disabled={disabled}
			>
				{children}
			</EitjeButton>
		</DeletePopout>
	)
}

ConfirmButton.__ANT_BUTTON = true

export const DownloadLink = ({to, url, ...rest}) => (
	<EitjeButton target="_self" className="download-button" t="download" {...rest} externalLink={to || url} />
)

export const EditButton = ({t = 'common.edit', ...rest}) => {
	return <EitjeButton className="edit-button" iconLeft="pencil" t={t} {...rest} />
}

const ExternalLinkButton = ({externalLink, style, target = '_blank', disabled, ...rest}) => {
	return (
		<ExtLink to={externalLink} style={{display: 'contents', ...style}} disabled={disabled} target={target}>
			<EitjeButton
				classname="external-link-button"
				iconLeft="external-link"
				style={getCssVariables(style)}
				{...rest}
				externalLink={false}
			/>
		</ExtLink>
	)
}

const MailToButton = ({email, ...rest}) => {
	return (
		<a href={`mailto:${email}`}>
			<EitjeButton className="mailto-button" iconLeft="envelope" t="common.contact_via_mail" {...rest} />
		</a>
	)
}

export const NextButton = props => {
	return <EitjeButton className="next-button" iconLeft="arrow-right" t="common.next" colorSet="solid" {...props} />
}

export const OptionsButton = forwardRef(({className, ...props}, ref) => {
	return (
		<PopoutCard ref={ref} trigger="click" className={`options-button ${className}`} {...props} onClick={e => e.stopPropagation()}>
			<EitjeButton iconLeft="three-dots" />
		</PopoutCard>
	)
})

const SelectAllButton = ({count, t = count ? 'common.deselect_all' : 'common.select_all', ...props}) => {
	return <EitjeButton t={t} className="select-all-button" iconLeft="checkbox-checked" {...props} />
}

const SettingsButton = props => {
	return (
		<EitjeButton
			data-intercom-target="settings_button"
			className="settings-button"
			t="common.settings"
			modalLink="settings"
			iconLeft="cog-wheel"
			{...props}
		/>
	)
}

export const startTour = tourId => {
	window.Intercom('startTour', tourId)
}

export const SubmitButton = props => {
	return <EitjeButton className="submit-button" iconLeft="checked" colorSet="solid" t="submit" {...props} />
}

export const SupportFilmButton = ({id, ...props}) => {
	return <EitjeButton iconLeft="film" t="common.watch_support_film" modalLink={`/support_film/${id}`} {...props} />
}

export const SupportButton = props => {
	return (
		<EitjeButton
			{...SHARED_SUPPORT_PROPS}
			className="support-button"
			iconLeft="chat"
			onClick={() => startConversation(props.message)}
			t="common.ask_help"
			{...props}
		/>
	)
}

export const RequestFeatureButton = props => {
	return (
		<EitjeButton className="request-feature-button" iconLeft="light-bulb" t="common.request_feature" externalLink={cannyLink} {...props} />
	)
}

export const ChatButton = props => {
	return <EitjeButton className="chat-button" iconLeft="chat" onClick={() => startConversation(props.message)} t="common.chat" {...props} />
}

export const CallButton = ({showPhoneNumberTooltip, ...rest}) => {
	const popoutTitle = showPhoneNumberTooltip && salesPhoneNumberFormatted

	return (
		<EitjeButton
			className="call-button"
			iconLeft="phone"
			t="common.call"
			{...rest}
			popoutTitle={popoutTitle}
			externalLink={`tel:${salesPhoneNumber}`}
		/>
	)
}

export const ToolsButton = ({elements, ...rest}) => {
	// Set trigger to click in order to A) prevent too much popping elements in the top of the page and B) increase the
	// perceived performance by not always opening slow popouts.

	return (
		<EitjeDropdown placement="bottom-end" visibleAfterClick elements={elements} trigger="click">
			<EitjeButton className="tools-button" iconLeft="tools" t="common.tools" {...rest} />
		</EitjeDropdown>
	)
}

export const TourButton = ({tourId, ...props}) => {
	return (
		<EitjeButton
			{...SHARED_SUPPORT_PROPS}
			iconLeft="diamond"
			className="tour-button"
			t="common.start_tour"
			{...props}
			onClick={() => startTour(tourId)}
		/>
	)
}

const BaseLocaleButton = ({currentLanguage, changeLanguage, ...props}) => {
	const transKey = language => `common.languages.${language}`
	const icon = language => `flag-${language}`

	// The dropdown shows on hover, thus the PopoutCard's onClick is set to undefined in web components. But the button should
	// become a hover trigger on mobile. We have to set this explicitly to prevent very flaky and inconsistent behaviour.
	const mobile = isMobile()
	const trigger = mobile ? 'click' : 'hover'

	const elements = languages.map(language => ({
		element: <Text t={transKey(language)} />,
		onClick: () => changeLanguage(language),
		icon: icon(language),
	}))

	return (
		<EitjeDropdown elements={elements} trigger={trigger} placement="bottom-end">
			<EitjeButton
				className="locale-button"
				iconLeft={icon(currentLanguage)}
				iconRight="chevron-small-down"
				t={transKey(currentLanguage)}
				{...props}
			/>
		</EitjeDropdown>
	)
}

export const NoAuthLocaleButton = props => {
	const {language} = i18n
	const currentLanguage = language.split('-')[0] // language may be 'en-GB' instead of 'en'

	const changeLanguage = language => {
		const {language: current} = i18n
		if (language === current) return
		if (window.Intercom) window.Intercom('update', {language_override: language})

		window.location.reload()
		i18n.changeLanguage(language)
	}

	return <BaseLocaleButton currentLanguage={currentLanguage} changeLanguage={changeLanguage} {...props} />
}

export const AuthLocaleButton = props => {
	const currentLanguage = useSelector(state => getUserSetting(state, 'locale')) || i18n.language
	const changeLanguage = language => {
		changeLocale(language)
	}

	return <BaseLocaleButton currentLanguage={currentLanguage} changeLanguage={changeLanguage} {...props} />
}

export const UploadButton = props => {
	return <EitjeButton className="upload-button" iconLeft="upload" colorSet="solid" t="upload" {...props} />
}

export const VideoButton = ({videoId, ...rest}) => (
	<DocsButton className="video-button" iconLeft="video" t="common.watch_video" {...rest} to={videoId} />
)

export const ViewButton = ({elements, ...rest}) => {
	return (
		<EitjeDropdown trigger="click" elements={elements} visibleAfterClick>
			<EitjeButton className="view-button" iconLeft="eye-open" t="common.view_text" {...rest} />
		</EitjeDropdown>
	)
}

export const DemoButton = ({...rest}) => {
	const demoUrl = 'https://calendly.com/eitje-joris/eitje-demo'
	return <EitjeButton externalLink={demoUrl} t="common.plan_demo" iconLeft="calendar" {...rest} />
}

export const TrialButton = ({...rest}) => {
	return <EitjeButton iconLeft="paper-plane" link="/create_account" t="common.try_free" {...rest} />
}

export const LogoutButton = ({...rest}) => {
	return <EitjeButton onClick={logout} iconLeft="locked" t="header.logout" {...rest} />
}

export const UserProfileButton = ({user, ...rest}) => {
	return <EitjeButton link={`/user/${user.id}/profile`} iconLeft="person" t="profile" {...rest} />
}

export {ExternalLinkButton, MailToButton, SelectAllButton, SettingsButton}
