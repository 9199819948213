import {ArticleProvider, Article, useTrainingContext} from 'cores/knowledge'
import {QuizItem} from './quiz_item'

export const TrainingStep = () => {
	const {activeResource} = useTrainingContext()
	const {tableName, ...resource} = activeResource

	switch (tableName) {
		case 'infos':
			return (
				<ArticleProvider readOnly article={resource}>
					<Article />
				</ArticleProvider>
			)
		case 'quizItems':
			return <QuizItem item={resource} />
	}
}
