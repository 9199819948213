import {CreateButton, useFilterFooterContext} from 'common/components'
import {AdvancedTable, useAdvancedTable} from 'common/components/advanced_table'
import {ModalLink} from 'components/routing'
import {useHasRole, useShared} from 'hooks'
import {Fragment} from 'react'
import {columns} from './columns'
import {TABLE_INFO} from './constants'

export * from './constants'

export const LeaveAdvancedTable = () => {
	const {filteredItems: leaveRequests, filteredOutAmount, clearFilters} = useFilterFooterContext()

	const placeholderProps = {
		animation: 'plane',
		name: 'leave',
		docsId: '6019593',
		children: <CreateButton modalLink="/leave/new" t="action_button" />,
	}

	const tableData = useAdvancedTable({
		data: leaveRequests,
		initialPinning: ['user_id', 'status'],
		columns,
		TABLE_INFO,
		filteredOutAmount,
		clearFilters,
	})

	return <AdvancedTable data={tableData} RowWrapper={RowWrapper} placeholderProps={placeholderProps} />
}

const RowWrapper = ({row, ...rest}) => {
	const {me} = useShared()
	const {id, user} = row.original
	const hasRole = useHasRole('verlof', row.original.relevantEnvironmentIds())
	const myLeave = user?.id === me.id
	const Wrapper = hasRole || myLeave ? ModalLink : Fragment

	return <Wrapper to={`/leave/${id}/approve`} {...rest} />
}
