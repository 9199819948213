import ActiveRecord from './active_record/base'

class UserEmploymentType extends ActiveRecord {
	static tableName = 'userEmploymentTypes'
	static defaultJoins = ['employmentTypes']

	isFixed = () => {
		return this.name == 'Uren contract'
	}
}

export default UserEmploymentType
