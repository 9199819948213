import {EitjeVirtuoso} from '@eitje/web_components'
import {Layout} from 'common/components'
import {BasicTableRow, useBasicTableContext} from 'common/components/basic_table'
import {EndOfContentPlaceholder} from './end_of_content_placeholder'
import './index.less'

export const BasicTableBody = () => {
	const {data, hasData, columns, endOfContentPlaceholder, ...props} = useBasicTableContext()
	const inlineStyle = {alignItems: 'unset'}

	if (!hasData) return null

	return (
		<Layout className="basic-table-body" direction="vertical" width="full" gap={0} grow style={inlineStyle}>
			<EitjeVirtuoso
				data={data}
				components={{Footer: EndOfContentPlaceholder}}
				itemContent={(i, item) => <BasicTableRow key={i} item={item} columns={columns} />}
			/>
		</Layout>
	)
}
