import {NavItemLayout} from './nav_item'
import {useHasRole, useShared, usePusher} from 'hooks'
import {InboxPopout} from 'cores/firm'
import {useOperations} from 'cores/firm/pages/inbox'
import {Operation} from 'models'

export const Notifs = () => {
	const {env, orgEnvs} = useShared()
	const ops = useOperations({orgEnvs})
	const unreadCount = ops.filter(op => op.id && !op.read).length
	const anyManager = useHasRole('manager')
	const anyApproveHours = useHasRole('uren_accorderen')
	const anyFinancial = useHasRole('financieel')
	const show = anyManager || anyApproveHours || anyFinancial

	usePusher(`${env.id}-inbox`, 'operation', () => Operation.index())

	if (!show) return null
	return (
		<InboxPopout>
			<NavItemLayout title="header.notification_center" countSuffix={unreadCount} modalLink="/inbox/all" />
		</InboxPopout>
	)
}
