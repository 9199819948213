import _ from 'lodash'
import QueryString from 'query-string'
import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

const commaSeparatedNumbersRegex = /[0-9]+(,[0-9]+)*/

export const useSetQueryParams = (obj = {}, watch) => {
	const history = useHistory()
	const params = useQueryParams()
	useEffect(() => {
		const newParams = new URLSearchParams(obj)
		if (!_.isEqual(obj, params)) history.replace({search: `?${newParams.toString()}`})
	}, [watch])
}

export const useQueryParams = (props = {}) => {
	const location = useLocation()
	const parsed = QueryString.parse(location.search)
	return _.mapValues(parsed, (v, k) => mapValue(v, k, props))
}

const mapValue = (value, key) => {
	if (value === 'false' || value == 'null' || value == 'undefined') return false
	if (value === 'true') return true

	if ((key.endsWith('_ids') || key === 'ids') && value.match(commaSeparatedNumbersRegex)) {
		return value.split(',').map(Number)
	}

	if (isNaN(Number(value))) return value

	return Number(value)
}

export default useQueryParams
