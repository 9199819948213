export const useBlendRevDaysInForecast = (days, revDays) => {
	let updatedDays = [...days]
	revDays.forEach(day => {
		// When manually adding a revenue forecast, there might be automatically calculated forecasts lingering around. When reloading,
		// they disappear. Always filter them here as guard.
		_.remove(updatedDays, obj => obj.date === day.hasForecast() && day.date && obj.omzet_groep_id == day.omzet_groep_id)

		updatedDays.push({
			...day,
			custom: true,
			amt: day.forecast_amt_in_cents / 100,
			amt_in_cents: day.forecast_amt_in_cents,
		})
	})

	return updatedDays
}
