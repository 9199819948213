import {Layout, Text} from 'common/components'
import {useBasicTableContext} from 'common/components/basic_table'

export const EndOfContentPlaceholder = () => {
	const {name, basicTableT} = useBasicTableContext()

	return (
		<Layout
			name={`basic_tables.${name}.end_of_content`}
			className="end-of-content-placeholder"
			padding="48 0"
			horizontal="center"
			direction="vertical"
			width="full"
			grow
		>
			<Text noTextSelect darkGrey bold children={basicTableT('end_of_content.title')} />
			<Text noTextSelect darkGrey children={basicTableT('end_of_content.subtitle')} />
		</Layout>
	)
}
