import utils from '@eitje/utils'
import {Link} from 'components/routing'
import {useRoleOrgEnvs} from 'hooks'
import useUserOvertime from 'hooks/use_user_overtime'

export const OvertimeBalanceLink = ({userId}) => {
	const overtimeBalance = useUserOvertime(userId)
	const envs = useRoleOrgEnvs('uren_accorderen')
	if (!utils.exists(envs)) return null
	if (!overtimeBalance['available']) return null
	return <Link t="balance.overtime.full_title" to={`/balance/overtime/${userId}`} />
}
