import {Icon} from '@eitje/web_components'
import {Layout, Text} from 'common/components'
import {ModalLink} from 'components/common'
import {Fragment} from 'react'

export const EventRow = ({modalLink, t, background = Colors.paleBlue, children, className}) => {
	// Manually set as high as the rows, otherwise there is a slight non clickable area along the row above and below the text.
	const lineHeight = '40px'
	const textProps = children ? {children} : {t}

	return (
		<Layout
			className="event-row"
			modalLink={modalLink}
			gap={16}
			padding="- 16 - 32"
			width="full"
			style={{background}}
			className={className}
		>
			<Icon name="arrow-small-hook-right" />
			<Text {...textProps} lineHeight={lineHeight} />
		</Layout>
	)
}
