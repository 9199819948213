import {Layout} from 'common/components'
import './index.less'

export const FloatingPageHeader = ({className, left, center, right}) => {
	return (
		<Layout className={className} childrenGrow padding="24 0 4 0">
			<Layout horizontal="start" children={left} />
			{center && <Layout horizontal="center" children={center} />}
			<Layout horizontal="end" children={right} />
		</Layout>
	)
}
