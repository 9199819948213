import {Layout} from 'common/components'

const FormRow = ({childrenEqual = true, children, ...rest}) => {
	return (
		<Layout childrenEqual={childrenEqual} className="eitje-form-2-row" gap={0} vertical="start" {...rest}>
			{children}
		</Layout>
	)
}

export default FormRow
