import ActiveRecord from 'models/active_record/base'
import {API} from '@eitje/easy_api'

class Topic extends ActiveRecord {
	static tableName = 'topics'

	async fetchTrainingOverview() {
		await this.resourceReq('training_overview', {method: 'get'})
	}

	async createTopicPrompt(userId) {
		const res = await API.create('user_topic_prompt', {topic_id: this.id, user_id: userId})
		await this.fetchTrainingOverview()
		return res
	}

	trainingType() {
		const {always_trainable, valid_untill, days_to_start} = this

		if (always_trainable) return 'always_trainable'
		if (valid_untill) return 'valid_untill'
		if (days_to_start != null) return 'days_to_start'

		return null
	}
}

export default Topic
