import utils from '@eitje/utils'
import {scrollToEnd} from 'actions/routing'
import useScroll from 'hooks/use_scroll'
import {sortItems} from 'hooks/use_sort'
import _ from 'lodash'
import {FlatList} from 'components/list/flat_list'
import {GroupedList} from 'components/list/grouped_list'

export const List = (props = {}) => {
	let {groupField, items = [], onEndReached = () => {}, prependChild = null, sortField, sortEnabled = true, draggable, className} = props
	const _onEndReached = _.debounce(onEndReached, 1500, {leading: true})

	const _handleScroll = () => {
		scrollToEnd() < 150 && _onEndReached()
	}

	const handleScroll = _.throttle(_handleScroll, 200, {leading: true})

	useScroll(handleScroll, [onEndReached])

	if (sortField && !draggable && sortEnabled) {
		// draggable handles its own sorting
		items = sortItems(items, props)
	}

	const _className = utils.makeCns(className, 'list-container', items.length == 0 && 'list-container-empty')

	return (
		<div className={_className}>
			{prependChild}
			{groupField ? <GroupedList {...props} items={items} /> : <FlatList {...props} items={items} />}
		</div>
	)
}

export default List
