import {API, find, where, whereNot} from '@eitje/easy_api'
import {LegacyMultiForm as MultiForm} from '@eitje/form'
import {
	LegacyDropdownPicker as DropdownPicker,
	LegacyInput as Input,
	LegacyDatePicker as DatePicker,
	LegacySwitch as Switch,
} from '@eitje/form-fields-web'
import utils from '@eitje/utils'
import {navigateModal} from 'actions/routing'
import {FormRow} from 'components/form'
import {LegacyHourMinutePicker} from 'components/form/hour_minute_picker'
import {SalaryInput} from 'components/form/input_number'
import Modal from 'components/general/modal'
import {makeRange} from 'helpers/date'
import {Label, makeMinsObj} from 'helpers/schedule'
import useShared from 'hooks/use_shared'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import moment from 'initializers/date'
import React, {Fragment, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {date} from 'initializers/date'
import {roleOrgEnvsSelector} from 'selectors/new_settings'
import './new_contract.less'

const createContract = data => {
	return API.arbitrary('contracts', `/new_contracts`, data)
}

const navigateToEditModal = (data, params) => {
	const holderId = data?.items?.contract_holders[0]?.id
	navigateModal(`/forms/contracts/${holderId}/details/`, {replace: true})
}

const NewContract = () => {
	const {hasContractRole, envId: currentEnvId} = useShared()
	const {id} = useParams()
	const [indefinite, setIndefinite] = useState(false)
	const [fixed, setFixed] = useState(false)
	const [multiFormTouched, setTouched] = useState(false)

	const user = useSelector(state => find(state, 'users', id))

	let envs = useSelector(state => roleOrgEnvsSelector(state, 'contracten'))
	envs = envs.filter(e => user.environment_ids.includes(e.id))
	const [envId, setEnvId] = useState(envs.length == 1 ? envs[0].id : currentEnvId)

	let employmentTypes = useSelector(state => whereNot(state, 'employmentTypes', {personal: true}))
	employmentTypes = employmentTypes.filter(e => e.environment_id == envId)

	const currentContracts = useSelector(state => where(state, 'contractHolders', {user_id: id}))
	const currentRanges = currentContracts.map(i => makeRange(i.start_date, i.end_date))

	const multiForm = useRef(null)
	const form = useRef(null)

	const afterChange = (field, data, allFields) => {
		if (field == 'environment_id') {
			setEnvId(data)
			form.current.setValues({employment_type_id: null})
		}

		if (field == 'hours' && !multiFormTouched) multiForm.current?.setValues(makeMinsObj(data))
		if (field == 'employment_type_id') {
			setFixed(isFixed(allFields))
			form.current.setValues({hours: null})
		}
	}

	const afterMultiChange = (field, allData) => {
		if (!utils.weekdays.includes(field)) return
		const val = utils.reduceArrOfObj(allData) / 2 // 2 is amt of weeks
		form.current?.setValues({hours: val})
	}

	const isFixed = (fields = {}) => {
		const _type = employmentTypes.find(e => e.id === fields['employment_type_id'])
		return _type && _type.fixed
	}

	return (
		<Modal redirectIf={!hasContractRole} wrapClassName="new-contract-modal" onOk={() => form.current.submit()} title={t('newContract')}>
			<Form
				onSubmit={data => createContract({environment_id: envId, ...data, weeks: multiForm.current?.getParams(), user_id: id})}
				fieldProps={{bordered: false}}
				ref={form}
				afterChange={afterChange}
				afterSubmit={navigateToEditModal}
			>
				{envs.length > 1 && <DropdownPicker field="environment_id" placeholder={t('select')} required items={envs} labelField="naam" />}
				<FormRow fieldWrapper>
					<DropdownPicker
						required
						field="employment_type_id"
						buildLabel={val => t(val)}
						placeholder={t('select')}
						items={employmentTypes}
					/>
					<LegacyHourMinutePicker
						required
						validateMessage={t('form.required')}
						validate={val => val > 0}
						label={t('contractHours')}
						extraLabel={<p>{t('amountHours')}</p>}
						max={70}
						readOnly={multiFormTouched}
						hidden={!fixed}
						decimalSeparator=":"
						field="hours"
					/>
				</FormRow>

				{fixed && (
					<div fieldWrapper className="elementContainer">
						<MultiForm formProps={{afterTouch: () => setTouched(true)}} afterChange={afterMultiChange} ref={multiForm}>
							<Label field="precies gap" />
							<div className="row-with-justify-between" fieldWrapper>
								{utils.weekdays.map(d => (
									<LegacyHourMinutePicker max={14} labelVisible={false} field={d} className="default-field" />
								))}
							</div>
						</MultiForm>
					</div>
				)}

				<FormRow fieldWrapper>
					<DatePicker
						placeholder={t('selectDate')}
						disabledRanges={currentRanges}
						field="start_date"
						isStart
						defaultPickerValue={({end_date}) => end_date && date(end_date)}
						dropdownClassName={'new-contract-start-date-picker'}
						required
					/>
					{!indefinite && (
						<DatePicker
							placeholder={t('selectDate')}
							disabledRanges={currentRanges}
							field="end_date"
							isEnd
							showToday={false}
							defaultPickerValue={({start_date}) => start_date && date(start_date)}
							dropdownClassName={'new-contract-end-date-picker'}
							renderExtraFooter={props => <ExtraFooter setIndefinite={() => setIndefinite(true)} {...props} />}
							required
						/>
					)}

					{indefinite && (
						<Input
							rightChildren={
								<img
									onClick={() => setIndefinite(false)}
									src="/images/easyRemove.png"
									style={{width: 14, position: 'absolute', marginTop: 16, right: 38}}
								/>
							}
							readOnly
							value={t('ccontract2')}
							field="end_date"
						/>
					)}
				</FormRow>
				<FormRow fieldWrapper>
					<SalaryInput bordered field="amount" extraLabel={<p>{t('uurloon')}</p>} info="dit is een bruto uurloon" required />
					<DropdownPicker field="trial_months" className="full-width" placeholder="-" defaultValue={null} items={trialItems} />
				</FormRow>
			</Form>
		</Modal>
	)
}

export default NewContract

export const ExtraFooter = ({onChange, setIndefinite, startValue, value, picker}) => {
	const startMoment = date(startValue, ['YYYY-MM-DD', 'DD-MM-YYYY'])

	const setDate = amt => {
		onChange(startMoment.add(amt, 'months').subtract(1, 'day').format('YYYY-MM-DD'))
		picker.current && picker.current.blur()
	}

	return (
		<div className="date-picker-contract-options">
			<p
				onClick={() => {
					onChange(null)
					setIndefinite()
				}}
				className="date-extra-footer-item"
			>
				{t('indefiniteContract')}
			</p>
			{startValue && (
				<Fragment>
					<p className="date-extra-footer-item" onClick={() => setDate(6)}>
						{t('halfYear')}
					</p>
					<p className="date-extra-footer-item" onClick={() => setDate(12)}>
						{t('einyear')}
					</p>
					<p className="date-extra-footer-item" onClick={() => setDate(18)}>
						{t('einhalfYear')}
					</p>
				</Fragment>
			)}
		</div>
	)
}

const trialItems = [
	{id: 1, name: '1 maand'},
	{id: 2, name: '2 maand'},
	{name: '-', id: null},
]
