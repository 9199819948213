import date from 'initializers/date'
import {t} from 'initializers/i18n'

export const formatDateForBalance = (period, periodType) => {
	if (periodType == 'year') return period
	const parts = period.split('-')
	const year = parts[0]?.substring?.(2)
	switch (periodType) {
		case 'week':
			return `${t(`common.${periodType}`)}  ${parts[1]} ('${year})`
		case 'quarter':
			return `Q${parts[1]} ('${year}) `
		case 'month':
			return `${date()
				.set('month', parts[1] - 1)
				.format('MMMM')} ('${year})`
		case 'day':
			return date(period).format("dd D MMM 'YY")
	}
}
