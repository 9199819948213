import {createContext, useContext} from 'react'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {useSort} from 'hooks'
import {useFilterFooterContext} from 'common/components'

const BasicTableContext = createContext({})
const {Provider} = BasicTableContext

export const BasicTableProvider = ({children, columns, name, data, i18nOpts, defaultSort, ...rest}) => {
	const {clearFilters} = useFilterFooterContext()
	const {sortedItems, sortField, sortDirection, changeSort} = useSort({items: data, defSortField: defaultSort})
	const setSortDirection = () => changeSort(sortField)
	const cssColumnWidth = `calc(100% / ${columns.length})`

	const basicTableT = transKey => {
		const inputKeys = utils.alwaysDefinedArray(transKey)
		const transKeys = utils
			.alwaysDefinedArray(name)
			.map(name => inputKeys.map(key => `basic_tables.${name}.${key}`))
			.flat()

		return t(transKeys, i18nOpts)
	}

	const value = {
		...rest,
		basicTableT,
		name,
		columns,
		cssColumnWidth,
		data: sortedItems || [],
		hasData: !_.isEmpty(data),
		clearFilters,
		defaultSort,
		sortField,
		sortDirection,
		changeSort,
		setSortDirection,
	}

	return <Provider value={value}> {children} </Provider>
}

export const useBasicTableContext = () => useContext(BasicTableContext)
