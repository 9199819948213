import {useMemo} from 'react'
import {Topic} from 'models'

// topic resources are sorted in this order:
// 	1. id
// 	2. type (infos before quizItems)
// 	3. order attribute in the resource map

const resourcesWithOrder = (resources, resourceMap, resourceKind) => {
	const results = resources.map(resource => {
		const {order, id: topicResourceId} = resourceMap.find(r => r.resource_id === resource.id && r.kind === resourceKind)

		return {...resource, topicResourceId, order}
	})
	return _.sortBy(results, 'id')
}

export const useTopicWithResources = id => {
	const topic = Topic.find(id, {joins: ['infos', 'quizItems']})
	const {resource_map, infos = [], quizItems = []} = topic || {}

	const enhancedInfos = useMemo(() => {
		const publishedInfos = infos.filter(info => info.published)
		return resourcesWithOrder(publishedInfos, resource_map, 'Info')
	}, [infos])

	const enhancedQuizItems = useMemo(() => resourcesWithOrder(quizItems, resource_map, 'QuizItem'), [quizItems])

	const resources = useMemo(() => _.sortBy([...enhancedInfos, ...enhancedQuizItems], 'order'), [enhancedInfos, enhancedQuizItems])

	if (_.isEmpty(topic)) return null

	const trainable = !!topic.trainingType()
	return {
		...topic,
		infos: enhancedInfos,
		quizItems: enhancedQuizItems,
		resources,
		trainable,
	}
}
