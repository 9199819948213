import {useFind} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {ContractHoursCellItem, UserRowPanel} from 'common/components'
import {useUserContext} from 'contexts/user'
import {MultiActionCheckbox, useEditableShifts, usePlanningContext} from 'cores/planning'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'
import {useHasRole} from 'hooks'
import useUserOvertime from 'hooks/use_user_overtime'
import {PanelDropdown} from './panel_dropdown'

export const Panel = ({...rest}) => {
	const {multiEdit} = useEnvsContext()
	const {shifts, user} = useUserContext()
	const plannedTime = utils.minutesToString(shifts.sum(s => s.totalMins()))
	const namespace = 'planning.per_member.user_row'
	const shared = {type: 'greySubtitle', namespace}
	const {dateRange} = usePlanningContext()
	const workSchedule = useFind('workSchedules', {user_id: user.id, dateRange})
	const showContractHours = useHasRole('manager', workSchedule.environment_id)
	const {timeString, available} = useUserOvertime(user.id)

	const items = [
		{value: plannedTime, localValue: 'planningPerMemberShowUserPlanned', ...shared},
		available && {value: timeString, localValue: 'planningPerMemberShowOvertimeBalance', ...shared},
		workSchedule.id && showContractHours && (
			<ContractHoursCellItem workSchedule={workSchedule} localValue="planningPerMemberShowContractHours" {...shared} />
		),
	]

	return (
		<UserRowPanel
			multiEdit={multiEdit}
			PanelDropdown={PanelDropdown}
			useEditableShifts={useEditableShifts}
			MultiActionCheckbox={MultiActionCheckbox}
			items={items}
			{...rest}
		/>
	)
}
