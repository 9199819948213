import {Label, Layout, PopoutCard} from 'common/components'
import {labelProps, labelContainerProps, useBalanceStartDate} from 'cores/balance'
import {ftime} from 'helpers'
import {useOrgEnvSettingKeyValue} from 'hooks'
import {t} from 'initializers/i18n'
import {Environment} from 'models'
import _date from 'initializers/date'
import {Fragment} from 'react'

const MultiDatePopout = props => <PopoutCard {...props} title={<EnvDates />} />
const EnvDates = () => {
	const envDates = useOrgEnvSettingKeyValue('statistieken', 'vanaf')
	return Object.keys(envDates).map(envId => <EnvDate envId={envId} date={envDates[envId]} />)
}

const EnvDate = ({envId, date}) => {
	const env = Environment.find(envId)
	if (!env.id) return null
	const dateString = date ? ftime(_date(date), 'dayMonthYear') : t('common.none')
	return `${env.naam}: ${dateString}\n`
}

export const LabelContainer = ({Labels}) => {
	const dates = useBalanceStartDate().map(d => d.format())
	const sameDate = dates.uniq().length == 1
	const text = sameDate ? `balance.initial_active` : `balance.initial_active_multi_env`
	const Wrapper = sameDate ? Fragment : MultiDatePopout

	return (
		<Layout {...labelContainerProps} name="balance">
			<Wrapper dates={dates}>
				<Label {...labelProps} children={t(text, {date: dates[0]})} />
			</Wrapper>
			{Labels && <Labels />}
		</Layout>
	)
}
