import utils from '@eitje/utils'
import {Spin} from 'antd'
import {t} from 'initializers/i18n'
import {useEffect, useState} from 'react'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'
import 'styles/app/components/pdf.less'
import './styles/fullscreen_pdf_page.less'
import Modal from 'components/general/modal'
import {BackButton, DownloadLink, EitjeButton, Layout} from 'common/components'
import {DeletePopout} from 'common/components'

const options = {
	cMapUrl: 'cmaps/',
	standardFontDataUrl: 'standard_fonts/',
}

const PdfViewer = ({pdf, onRemove, modalView}) => {
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState()
	const [fullscreen, setFullscreen] = useState()

	useEffect(() => {
		setNumPages(null)
	}, [pdf])

	function onDocumentLoadSuccess({numPages}) {
		setNumPages(numPages)
		if (!pageNumber) setPageNumber(1)
	}

	function onItemClick({pageNumber: itemPageNumber}) {
		setPageNumber(itemPageNumber)
	}

	function changePage(offset) {
		setPageNumber(prevPageNumber => prevPageNumber + offset)
	}

	function decrementPage() {
		changePage(-1)
	}

	function incrementPage() {
		changePage(1)
	}

	const props = {
		onRemove,
		pdf,
		onDocumentLoadSuccess,
		onItemClick,
		pageNumber,
		modalView,
		numPages,
		incrementPage,
		decrementPage,
		setFullscreen,
	}

	return (
		<div className="pdf-container">
			{fullscreen ? (
				<PdfViewerFullscreen {...props} setFullscreen={() => setFullscreen(false)} />
			) : (
				<Pdf setFullscreen={() => setFullscreen(true)} {...props} />
			)}
		</div>
	)
}

const PdfViewerFullscreen = ({setFullscreen, ...props}) => {
	return (
		<Modal wrapClassName="fullscreen-pdf-page" onCancel={setFullscreen} size="fs">
			<Pdf fullScreen setFullscreen={setFullscreen} {...props} />
		</Modal>
	)
}

const FullScreenButton = ({onClick}) => <EitjeButton onClick={onClick} height="small" iconLeft="plus" t="common.full_screen" />

const Pdf = ({
	onRemove,
	pdf,
	fullScreen,
	setFullscreen,
	onDocumentLoadSuccess,
	onItemClick,
	pageNumber,
	modalView,
	numPages,
	incrementPage,
	decrementPage,
}) => {
	return (
		<>
			{onRemove && (
				<DeletePopout inlineTrigger className="confirm-popover" onDelete={onRemove} body={t('removePdf')}>
					<img src={'/images/web/icons/soleCrossIconBlack.png'} className="pdf-destroy-icon" />
				</DeletePopout>
			)}
			<Layout padding={16} horizontal="center" width="900px">
				<DownloadLink url={pdf} height="small" />
				{fullScreen ? <BackButton height="small" onClick={setFullscreen} /> : <FullScreenButton onClick={setFullscreen} />}
			</Layout>

			<Document
				loading={() => <PdfPlaceHolder loading />}
				error={() => <PdfPlaceHolder />}
				file={pdf}
				onLoadSuccess={onDocumentLoadSuccess}
				onItemClick={onItemClick}
				options={options}
			>
				<Page width={1024} pageNumber={pageNumber} />
			</Document>
			<PageCount
				modalView={modalView}
				pageNumber={pageNumber}
				numPages={numPages}
				onNextPage={incrementPage}
				onPreviousPage={decrementPage}
			/>
		</>
	)
}

const ControlButton = ({disabled, onClick, back}) => {
	const imageClassName = utils.makeCns('pdf-toggle-icon', back && 'left', disabled && 'disabled')
	return (
		<div onClick={!disabled ? onClick : undefined} className="pdf-toggle-container">
			<img className={imageClassName} src={'/images/downArrow.png'} />
		</div>
	)
}

const PageCount = ({pageNumber, numPages, onNextPage, onPreviousPage, modalView}) => {
	const containerClass = utils.makeCns('pdf-controls-container', modalView && 'modal-view')
	return (
		<div className={containerClass}>
			{numPages > 1 && <ControlButton disabled={pageNumber == 1} onClick={onPreviousPage} back />}

			<div className="pdf-count-container">
				<p className="eitje-text-secondary">
					Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
				</p>
			</div>
			{numPages > 1 && <ControlButton disabled={pageNumber >= numPages} onClick={onNextPage} />}
		</div>
	)
}

const PdfPlaceHolder = ({loading}) => {
	const text = loading ? 'Loading PDF...' : 'Unable to load PDF.'
	return (
		<div className="pdf-body-container">
			<div className="text-center">
				{loading && <Spin />}
				<p className="margin-top-xs">{text}</p>
			</div>
		</div>
	)
}

export default PdfViewer
