import {
	getEnvSetting,
	getEnvSettingGroup,
	orgEnvSettingKeyValueSelector,
	orgEnvSettingSelector,
	orgEnvsSettingsSelector,
	orgEnvsSettingGroupSelector,
} from 'selectors/new_settings'
import useSelector from 'hooks/use_selector'
import utils from '@eitje/web_utils'

export const useEnvSetting = (kind, key, envId) => {
	return useSelector(getEnvSetting, kind, key, envId)
}

export const useEnvSettingGroup = (kind, envId) => {
	return useSelector(getEnvSettingGroup, kind, envId)
}

export const useOrgEnvSetting = (kind, key) => {
	return useSelector(orgEnvSettingSelector, kind, key)
}

export const useEnvsSetting = (kind, key, envIds) => {
	const allEnvs = useSelector(orgEnvsSettingsSelector)
	const relevant = utils.exists(envIds) ? _.pick(allEnvs, envIds) : allEnvs
	const transformed = transformValues(relevant, [kind, key])
	return transformed
}

function transformValues(obj, pathArray) {
	let newObj = {}
	for (let key in obj) {
		newObj[key] = pathArray.reduce((o, k) => o && o[k], obj[key])
	}
	return newObj
}

export const useOrgEnvSettingKeyValue = (kind, key) => {
	return useSelector(orgEnvSettingKeyValueSelector, kind, key)
}

export const useOrgEnvsSettingGroup = kind => {
	return useSelector(orgEnvsSettingGroupSelector, kind)
}

export default useEnvSetting
