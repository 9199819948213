import createCachedSelector from 'initializers/selectors'
import {t} from 'initializers/i18n'
import {date} from 'initializers/date'
import {ftime} from 'helpers'
import {activeUsersSelector} from './active_users'

const UPCOMING_DAYS = 7
const PAST_DAYS = 90

export const birthdaysSelector = createCachedSelector(
	activeUsersSelector,
	(state, days) => days,
	(users, days) => {
		users = users.filter(u => u.verjaardag)
		return notifications({users, days})
	},
)

function notifications({users, days}) {
	return users
		.map(u => {
			let age = u.age()
			const zeroDays = days === 0
			const nextBday = u.nextBirthday()
			const prevBday = u.previousBirthday()
			let _date = nextBday
			let label = u.full_name
			const upcoming = nextBday.isSameOrBefore(date().add(UPCOMING_DAYS, 'days')) && !zeroDays
			const pastOrToday = prevBday.isSameOrAfter(date().subtract(PAST_DAYS, 'days')) && zeroDays // check for zeroDays to only show one past birthday
			if (upcoming) {
				age += 1
				label += ` ${t('inbox.age_becomes')} ${age} ${t('common.on')} ${ftime(nextBday, 'dayMonth')}`
			} else if (pastOrToday) {
				_date = prevBday
				label += ` ${t('inbox.age_becomes_today')} ${age} ${t('inbox.age_becomes_today_suffix')}`
			} else {
				return // skip birthdays out of range
			}
			const createdAt = _date.subtract(days, 'days')
			return {
				label,
				createdAt,
				scope: 'birthday',
				read: true, // BE doesn't store read status, so always set to true for now
			}
		})
		.filter(Boolean) // filter birthdays that are out of range
}
