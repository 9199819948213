import {useRoleMessage, useShared} from 'hooks'
import {Team, Topic} from 'models'
import {t} from 'initializers/i18n'
import {NAMESPACE} from 'cores/knowledge'

export const useArticleRoleMessage = (article, readOnly) => {
	const {me} = useShared()
	const {topic_ids, environment_ids} = article

	const topics = Topic.where({id: topic_ids})
	const teamIds = topics.flatMap(topic => topic.team_ids)
	const teams = Team.where({id: teamIds})
	const isTeamMember = teams.some(team => team.user_ids.includes(me.id))

	// environment_ids is updated in the BE
	// if not linked to a topic, it returns the env the article has been created in
	// if linked to a topic, it returns all envs of teams linked to that topic
	// therefore we can use this check for both linked and unlinked articles
	const adminDisabled = useRoleMessage({roles: 'admin', env: environment_ids})
	const managerDisabled = useRoleMessage({roles: 'manager', env: environment_ids})

	// if no teams, managers can edit, else they need to be a teammember to be allowed to edit
	const teamEnabled = !teams.length || isTeamMember

	const disabled = readOnly || getIsArticleDisabled(adminDisabled, managerDisabled, teamEnabled)
	return disabled
}

function getIsArticleDisabled(adminDisabled, managerDisabled, teamEnabled) {
	// BE is filtering articles based on roles and published state,
	// so we only get articles here that a user is allowed to see

	// admins in one of the envs can always edit, even if not part of the team
	if (!adminDisabled) return false

	// if no admin and no manager, not allowed to edit
	if (managerDisabled) return managerDisabled

	// user is manager, if also a teammember or no teams, allow edit
	if (teamEnabled) return false

	// if manager, but not part of team, not allowed to edit
	return t(`${NAMESPACE}.article_no_teammember_message`)
}
