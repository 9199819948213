import {fileDomain} from 'initializers/api'
import {t} from 'initializers/i18n'
import {ftime} from 'helpers'

import {all} from '@eitje/easy_api'
import createCachedSelector from 'initializers/selectors'
import {orgEnvsSelector} from 'selectors/records'

const MAX_OPERATIONS = 100

export const operationsSelector = createCachedSelector(
	state => all(state, 'export2Excels'),
	state => all(state, 'export2Tables'),
	state => all(state, 'operations'),
	(state, scope) => scope,
	(state, scope, rawOps) => rawOps,
	orgEnvsSelector,
	(customExcels, tables, operations, scope, rawOps, orgEnvs) => {
		return notifications({customExcels, tables, operations, scope, rawOps, orgEnvs})
	},
)

export function notifications({customExcels, tables, operations, scope, orgEnvs, rawOps}) {
	let ops = rawOps
	if (!rawOps) {
		ops = scope ? operations.where({kind: scope}) : operations
		ops = ops.whereNot({kind: null}) // filter operations which resource has been deleted, e.g. integrations
	}

	ops = ops.sortBy(o => -o.createdAt())
	ops = ops.map(op => {
		const {kind, status, url, read} = op
		const extLink = url && `${fileDomain}${url}`
		const error = status === 'error'
		const warning = status === 'warning'
		const excel = kind === 'excel'
		const integ = kind === 'hr' || kind === 'pos' || kind == 'loyalty'

		const to = integ && !isLegacyHr(op, 'nmbrs') && '/integrations/my'
		const createdAt = op.createdAt()
		const label = makeLabel({orgEnvs, op, error, warning, excel, integ})
		return {...op, label, extLink, error, warning, to, createdAt}
	})

	// Custom excels don't have Operation records, but are their own Excel records. So query them separately
	// and then mix them in.
	if (!rawOps && (!scope || scope == 'custom_excel')) {
		const items = customExcels.map(e => {
			const error = !e.download_url
			const status = error && 'error'
			const label = makeLabel({customExcel: e, tables, error, status})

			return {label, extLink: e.download_url, read: true, createdAt: e.createdAt(), error, id: e.id}
		})

		// Mixin custom excels with the other operations and sort again before slicing the top off

		ops = [...ops, ...items].sortBy('createdAt', 'desc')
	}

	ops = ops.slice(0, MAX_OPERATIONS)

	return ops
}

function isLegacyHr(operation, kind) {
	if (!operation) return

	// Don't link Nmbrs operations to integrations page since we don't have Nmbrs there yet....
	// Once we partially have, see the new setup/ data to only redirect if the integration is there.
	const {code, message} = operation
	const match = text => text?.toLowerCase?.().includes(kind)
	return match(code) || match(message)
}

function makeLabel({op, orgEnvs, tables, error, warning, excel, integ, customExcel, status}) {
	let label = ''
	let {name} = op || {}
	status = op?.status || status

	if (!name && isLegacyHr(op, 'nmbrs')) name = 'Nmbrs'
	if (!name && isLegacyHr(op, 'visma')) name = 'Visma'

	const showStatus = error || warning

	// add name of provider of operation, since it is not always present in the message
	if (name && integ) label += name
	if (name && excel) label += t(`exports.${_.upperFirst(_.camelCase(name))}.title`)
	if (customExcel) label += tables.find(t => t.id === customExcel.table_id)?.name

	// add space between status and provider name
	if ((name || customExcel) && showStatus) label += ' '

	if (showStatus) label += t(`inbox.item.${status}`)

	// if things have been prefixed, demarcate the prefix
	if (label.length > 0) label += ': '

	if (excel) label += excelLabel({op, orgEnvs})
	if (customExcel) label += customExcelLabel({status})
	if (integ) {
		if (op.code == 'EXPORT' && op?.environment?.naam) label += `"${op.environment.naam}" `
		label += integLabel({op})
	}

	return label
}

function excelLabel({op, orgEnvs}) {
	// if the operation is a legacy "standard" excel, print the exported period and environment (always is a single env)
	let {start_date, end_date, environment_id} = op
	const envName = orgEnvs.find(e => e.id === environment_id)?.naam

	start_date = ftime(start_date, 'dayMonth')
	end_date = ftime(end_date, 'dayMonth')

	return `${start_date} - ${end_date} ${t('common.in')} ${envName}`
}

function customExcelLabel({status}) {
	return t(`inbox.item.custom_export.${status || 'success'}`)
}

function integLabel({op}) {
	// First lookup a custom message for the provider + status, but if none is given, fallback to a generic
	// translation for the status of this provider type (pos || hr).
	const {kind, code, name, title, translation_key, status, params} = op
	let sanitizedCode = code.toLowerCase()
	if (sanitizedCode == 'nmbrs_user_sync' || sanitizedCode == 'visma_sync') sanitizedCode = 'sync_users'
	if (sanitizedCode == 'nmbrs_shift_export' && status != 'success') sanitizedCode = 'export'
	// legacy support, fails/busy have the same translation as new integs, only success is different due to the export dates being saved in another place
	const keys = [translation_key, `inbox.item.${kind}.${sanitizedCode}.${status}`, `inbox.item.defaults.${status}`].filter(Boolean)
	return t(keys, {params, providerName: name, period: title}) // period is legacy support for old nmbrs exports
}
