import {Layout, Text} from 'common/components'
import {Icon} from '@eitje/web_components'
import {t} from 'initializers/i18n'
import {NAMESPACE} from 'cores/knowledge'

export const ResultField = ({t, label, labelPopoutComponent, value, children}) => {
	return (
		<Layout className="result-field" direction="vertical" padding="16 24" borderBottom width="full" gap={0}>
			<Label t={t} label={label} PopoutComponent={labelPopoutComponent} />
			{value && <Text>{value}</Text>}
			{children}
		</Layout>
	)
}

export const ResultResource = ({tableName, ...rest}) => {
	switch (tableName) {
		case 'infos':
			return <InfoResult {...rest} />
		case 'quizItems':
			return <QuizAnswer {...rest} />
	}
}

const InfoResult = ({read}) => <Answer t={read ? 'read' : 'not_read'} iconColor={read ? Colors.green : Colors.grey} />

const QuizAnswer = ({answer, correctAnswer}) => {
	const correct = answer === correctAnswer

	const label = t(`${NAMESPACE}.${correct ? 'correct' : 'incorrect'}`, {answer})

	if (!answer) return <Answer t="not_answered" iconColor={Colors.grey} />
	if (correct) return <Answer label={label} iconColor={Colors.green} />
	if (!correct) {
		const tooltip = {key: `${NAMESPACE}.correct_answer_tooltip`, answer: correctAnswer}
		return <Answer label={label} tooltip={tooltip} iconColor={Colors.red} />
	}
}

const Label = ({t, label, PopoutComponent}) => {
	const popoutTitle = !!PopoutComponent
	const popoutBody = !!PopoutComponent

	return (
		<Text
			name={NAMESPACE}
			t={t}
			truncate
			popoutTitle={popoutTitle}
			popoutBody={popoutBody}
			PopoutComponent={PopoutComponent}
			darkGrey
			fontSize={12}
		>
			{label}
		</Text>
	)
}

const Answer = ({t, label, iconColor, tooltip}) => (
	<Layout tooltip={tooltip}>
		<Icon name="dot" fill={iconColor} size={10} />
		<Text name={NAMESPACE} t={t}>
			{label}
		</Text>
	</Layout>
)
