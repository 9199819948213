import {Text, Layout} from 'common/components'
import {ModalLink} from 'components/routing'
import date from 'initializers/date'
import {t} from 'initializers/i18n'
import {LeaveRequest} from 'models'

export const ShiftList = ({shifts}) => {
	return (
		<Layout direction="vertical">
			<Text>{t('balance.unapproved_popout.shift_name', {count: shifts.length})}:</Text>
			{shifts.map(s => (
				<Shift item={s} />
			))}
		</Layout>
	)
}

const Shift = ({item}) => {
	return (
		<ModalLink to={`/time_registration/${item.id}?isReg=${item.reg}`}>
			<Text underline blue>
				{date(item.date).formatDate({year: true})}
			</Text>
		</ModalLink>
	)
}

export const LeaveList = ({leave}) => {
	const leaveReqs = leave.map(l => new LeaveRequest(l))
	return (
		<Layout direction="vertical">
			<Text>{t('records.leave_request.name', {count: leave.length})}:</Text>
			{leaveReqs.map(s => (
				<ModalLink to={`/leave/${s.id}/approve`}>
					<Text underline blue>
						{s.toString()}
					</Text>
				</ModalLink>
			))}
		</Layout>
	)
}
