import {Icon} from 'common/components'
import {Layout} from '../layout'
import './styles/icon_circle.less'

export const IconCircle = props => {
	let {baseColor, ...rest} = props
	if (['questionmark'].includes(props.name)) baseColor = 'orange'
	if (['exclamation-mark'].includes(props.name)) baseColor = 'red'
	return (
		<Layout className="icon-circle" colorSet="solid" baseColor={baseColor}>
			<Icon fill={Colors.white} size="14px" {...rest} />
		</Layout>
	)
}
