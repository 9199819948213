import {useFind} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {ContractHoursCellItem, UserRowPanel, usePageContext} from 'common/components'
import {useUserContext} from 'contexts/user'
import {MultiActionCheckbox, useEditableShifts, usePerWeekContext} from 'cores/time_registration'
import useUserOvertime from 'hooks/use_user_overtime'
import {PanelDropdown} from './panel_dropdown'

export const Panel = ({...rest}) => {
	const {multiEdit} = usePageContext()
	const {shifts, user} = useUserContext()
	const [totalPlanned, totalWorked] = _.partition(shifts, s => s.isPlan())
	const namespace = 'time_registration.per_week.user_row'
	const format = shifts => utils.minutesToString(shifts.sum(s => s.totalMins()))
	const {dateRange} = usePerWeekContext()
	const workSchedule = useFind('workSchedules', {user_id: user.id, dateRange})
	const shared = {type: 'greySubtitle', namespace}

	const {timeString, available} = useUserOvertime(user.id)

	const items = [
		{value: format(totalWorked), localValue: 'hoursUserWorked', ...shared},
		{value: format(totalPlanned), localValue: 'hoursUserPlanned', ...shared},
		workSchedule.id && <ContractHoursCellItem workSchedule={workSchedule} localValue="hoursContractHours" {...shared} />,
		available && {value: timeString, localValue: 'hoursOvertimeBalance', ...shared},
	]

	return (
		<UserRowPanel
			multiEdit={multiEdit}
			PanelDropdown={PanelDropdown}
			useEditableShifts={useEditableShifts}
			MultiActionCheckbox={MultiActionCheckbox}
			{...rest}
			items={items}
		/>
	)
}
