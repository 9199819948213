import {requireProps} from 'helpers'
import {useFilterFooterContext, Layout} from 'common/components'
import {BasicTableHeader, BasicTableBody, BasicTableProvider} from 'common/components/basic_table'
import {Placeholder} from './placeholder'

export const BasicTable = ({name, columns, data, defaultSort, hasFiltered, placeholderProps, ...contextProps}) => {
	requireProps('BasicTable', {name, columns})

	contextProps = {
		name,
		columns,
		data,
		defaultSort,
		hasFiltered,
		placeholderProps,
		...contextProps,
	}

	return (
		<BasicTableProvider {...contextProps}>
			<Layout className="basic-table" width="100%" direction="vertical" gap={0} grow colorSet borderRadius style={{overflowY: 'auto'}}>
				<BasicTableHeader />
				<BasicTableBody />
				<Placeholder />
			</Layout>
		</BasicTableProvider>
	)
}
