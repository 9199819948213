import {BalanceShow} from './index'
import {EmploymentType} from 'models'
import {Label} from 'common/components'
import {labelProps} from 'cores/balance'
export const LeaveBalanceShow = () => {
	return <BalanceShow Labels={Labels} kind="leave" />
}

const Labels = ({userEmpType}) => {
	const empType = EmploymentType.find(userEmpType?.employment_type_id)
	const key = !empType.id || empType.leave_registration == 'no_leave' ? 'no_buildup' : 'buildup'
	return <Label {...labelProps} t={{key: `balance.show.labels.${key}`, type: empType.leave_registration}} />
}
