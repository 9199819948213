import {Icon} from '@eitje/web_components'
import {Layout, Text} from 'common/components'

export const ButtonBar = ({
	t,
	label,
	onClick,
	numberLeft,
	iconLeft,
	iconRight,
	loading,
	height = 40, // default 40 to align with buttons etc. Set to null to allow multiple lines.
	colorSet = 'grey-bordered',
	...rest
}) => {
	return (
		<Layout
			className="button-bar"
			padding="8 12"
			width="full"
			horizontal="spaceBetween"
			border
			borderRadius
			gap={0}
			height={height}
			onClick={onClick}
			colorSet={colorSet}
			{...rest}
		>
			<Layout gap={16} grow>
				<LeftContent iconLeft={iconLeft} numberLeft={numberLeft} />
				<Text semiBold truncate={!!height} t={t}>
					{label}
				</Text>
			</Layout>
			<IconRight loading={loading} iconRight={iconRight} onClick={onClick} />
		</Layout>
	)
}

const inlineStyles = {alignSelf: 'flex-start'}
const LeftContent = ({iconLeft, numberLeft}) => {
	if (numberLeft) return <Text styles={inlineStyles}>{numberLeft}.</Text>
	if (iconLeft) return <Icon name={iconLeft} />
	return null
}

const IconRight = ({loading, iconRight}) => {
	if (loading) return <Icon rotatingAnimation name="arrow-circle-right" />
	if (typeof iconRight === 'string') return <Icon name={iconRight} />
	if (iconRight) return iconRight
	return null
}
