import {useState} from 'react'
import {PopoutCard} from '@eitje/web_components'
import {EitjeButton, ListPicker} from 'common/components'
import {t} from 'initializers/i18n'
import Lightbox from 'react-image-lightbox'

export const ATTACHMENT_TRANSKEYS = {
	audio: 'audioFile',
	image: 'image',
	file: 'file',
	video: 'video',
}

const IMG_REGEX = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i

export const AttachmentButton = ({attachments}) => {
	const [activeLightboxImage, setActiveLightboxImage] = useState(null)
	const attachmentsCount = attachments?.length

	if (!attachmentsCount) return null

	const handleClick = url => {
		const attachment = attachments.find(a => a.url === url)

		if (attachment.type === 'image') {
			setActiveLightboxImage(url)
		} else {
			window.open(url, '_blank')
		}
	}

	const handleClose = () => setActiveLightboxImage(null)

	const typedAttachment = attachments.map(attachment => {
		const fallbackType = IMG_REGEX.test(attachment.url) ? 'image' : 'file'

		return {
			...attachment,
			type: attachment.type || fallbackType,
		}
	})

	const groups = Object.entries(ATTACHMENT_TRANSKEYS).map(([attachmentType, transKey]) => ({
		id: attachmentType,
		name: t(transKey, {
			count: 2, // force plural
		}),
	}))

	return (
		<>
			<PopoutCard
				body={
					<ListPicker
						labelField="title"
						valueField="url"
						items={typedAttachment}
						single
						raw
						onChange={handleClick}
						groupField="type"
						groupItems={groups}
					/>
				}
				bodyPadding={0}
				trigger="click"
				placement="bottom-start"
				width={292}
			>
				<EitjeButton iconLeft="paperclip" iconRight="chevron-small-down">{`${t('attachment')} (${attachmentsCount})`}</EitjeButton>
			</PopoutCard>
			{activeLightboxImage && <Lightbox mainSrc={activeLightboxImage} onCloseRequest={handleClose} />}
		</>
	)
}
