import {Message} from './message'
import {makeCnVariants} from 'helpers'
import {FormRow} from 'components/ui'
import utils from '@eitje/web_utils'

export const FormMessage = ({colorSet = 'color-bordered-fill', formPosition = 'top', ...props}) => {
	const classNames = utils.makeCns(makeCnVariants('form-row-message', formPosition))

	return (
		<FormRow className={classNames} initialBackground={Colors.white}>
			<Message colorSet={colorSet} {...props} />
		</FormRow>
	)
}
