import {t} from 'initializers/i18n'
import {LocalCheckboxButton} from 'components/ui'

export function viewOptionsDropdownItem({
	record,
	buttons,
	translationNamespace,
	namespace,
	usePerInLabel = true,
	disabled: collectionDisabled,
	...rest
}) {
	let title = usePerInLabel ? `${t('view_options.view_per')} ` : ''
	title += `${t(`view_options.${record}`)}`
	translationNamespace = translationNamespace || `view_options.${record}_options`

	const elements = buttons.filter(Boolean).map(button => {
		// Pass buttons either as localValue strings or as objects with more attributes such as 'disabled'
		let kind = button.kind || button

		// Local values are often heavily namespaced to avoid collision, but typing them is cumbersome, thus pas namespace once.
		if (namespace) kind = `${namespace}${kind}`

		// When a collection / top level DD item is disabled, all its nested children should also be disabled individually.
		// When the top level item is enabled, you may still disable individual items.
		const {disabled: _itemDisabled} = button
		const itemDisabled = _itemDisabled || collectionDisabled

		const element = <LocalCheckboxButton {...rest} {...button} kind={kind} namespace={translationNamespace} />
		return itemDisabled ? {element, disabled: itemDisabled} : element
	})

	return {
		icon: 'eye-open',
		title,
		children: elements,
		disabled: collectionDisabled,
		...rest,
	}
}
