import {refreshTokenIfNeeded} from 'actions/auth'
import {create} from 'apisauce'
import {store} from 'index'
import _ from 'lodash'
import * as jsUtils from 'utils/jsutils'

const nodeStage = process.env.NODE_ENV
const envStage = process.env.REACT_APP_STAGE
let loketClientId = 'Eitje'
let lightspeedKId = 'Eitje-92c3-436d-aaf1-2db88c1ab7e3'

const stage = envStage || nodeStage
let loketUrl = 'https://oauth.loket.nl/authorize'
let chatKey

let prefEnv = 'test2'
// if (process.env.REACT_APP_ABEL == 'true') prefEnv = 'development'

let lsUrl = 'https://test.lightspeedapis.com/resto/oauth2/v1/authorize'
const actualEnv = nodeStage === 'development' ? envStage || prefEnv : stage
let domain
let lightspeedKUrl = 'https://api.trial.lsk.lightspeed.app/oauth/authorize'

let protocol = 'https://'
switch (actualEnv) {
	case 'development':
		domain = 'localhost:3000/api'
		protocol = 'http://'
		loketUrl = 'https://oauth.loket-acc.nl/authorize'
		loketClientId = 'ThirdPartiesTestClient'
		lightspeedKId = 'eitjeTrial-fe11-4c1a-804e-2d72b8f7662d'
		break
	case 'test':
		domain = 'testapi.eitje.app/api'
		loketUrl = 'https://oauth.loket-acc.nl/authorize'
		loketClientId = 'ThirdPartiesTestClient'
		lightspeedKId = 'eitjeTrial-fe11-4c1a-804e-2d72b8f7662d'
		chatKey = 'REACT_APP_CHAT_KEY_TEST'
		break
	case 'test2':
		domain = 'eitje-test-api2.eitje.app/api'
		chatKey = 'REACT_APP_CHAT_KEY_STAGING'
		break
	case 'staging':
		domain = 'staging-api.eitje.app/api'
		chatKey = 'REACT_APP_CHAT_KEY_STAGING'
		break
	case 'mirror':
		domain = 'mirror-api.eitje.app/api'
		chatKey = 'REACT_APP_CHAT_KEY_STAGING'
		break
	case 'production':
		lightspeedKUrl = 'https://api.lsk.lightspeed.app/oauth/authorize'
		lsUrl = 'https://lightspeedapis.com/resto/oauth2/v1/authorize'
		loketUrl = 'https://oauth.loket.nl/authorize'
		domain = 'api.eitje.app/api'
		chatKey = 'REACT_APP_CHAT_KEY'
		break
	case 'jurr':
		domain = '192.168.178.50:3000/api'
		protocol = 'http://'
		break
	default:
		domain = 'api.eitje.app/api'
}

const url = `${protocol}${domain}`
export const ENV = actualEnv
export const CHAT_ENV_KEY = nodeStage === 'development' ? chatKey : 'REACT_APP_CHAT_KEY'
export const DOMAIN = domain
export const HOST = url
export const fileDomain = url.replace(/\/api$/, '')
export {lightspeedKId, lightspeedKUrl, loketClientId, loketUrl, lsUrl}
const api = create({
	baseURL: HOST,
	headers: {'Content-Type': 'application/json', credentials: 'same-origin', 'Access-Control-Allow-Origin': '*'},
})

async function changeTokenHeader(req) {
	if (req.url !== 'oauth/token' && req.url !== 'auth' && req.url !== 'users/sign_up' && req.url !== 'auth/confirmed') {
		const token = await refreshTokenIfNeeded()
		if (token) {
			if (!req.params) req.params = {}
			if (req.params) req.params['access_token'] = token
		}
	}
}

async function addEnvId(req) {
	const st = store.getState()
	const hasEnvId = (req.data && req.data['env_id']) || (req.params && req.params['env_id'])
	if (hasEnvId) return
	if (req.data && req.method !== 'get' && !req.data['env_id']) {
		req.data['env_id'] = st.entities.active
	} else if (req.params && !req.params['env_id']) {
		req.params['env_id'] = st.entities.active
	}
}

api.addAsyncRequestTransform(request => changeTokenHeader(request))
api.addAsyncRequestTransform(request => addEnvId(request))

api.postOrPut = (url, data, isPut = false) => {
	const id = getId(data)
	if (id) {
		return api.put(`${url}/${id}`, data)
	} else {
		return api.post(url, data)
	}
}

api.postOrPatch = (url, data, isPut = false) => {
	const id = getId(data)
	if (id) {
		return api.patch(`${url}/${id}`, data)
	} else {
		return api.post(url, data)
	}
}

const getId = data => {
	if (data.id) return data.id
	const k = Object.keys(data)
	if (k.length === 1) {
		return data[k].id
	} else {
		const key = k.find(key => _.isObject(data[key]) && _.has(data[key], 'id'))
		return data[key] && data[key].id
	}
}

export default api

export function handleRes(res, {succ = () => {}, fail = () => {}}) {
	if (!res.problem) {
		succ(res.data)
	} else {
		fail()
		if (res.data && res.data.errors) res.data.errors.forEach(e => jsUtils.errNotif('Let op', e))
	}
}
