import {ModalWithNewForm} from 'components/form/new_modal_form'
import {history} from 'index'
import {Topic} from 'models'
import {TopicFormFields, TopicHelpButton} from '../components'

const modalProps = {
	oneFieldPerFormRow: true,
	buttons: <TopicHelpButton />,
	hideCancel: true,
}

export const NewTopic = () => {
	const handleSubmit = async data => await Topic.create(data)

	const close = ({res}) => {
		history.replace(`/topics/${res.item.id}`)
	}

	return (
		<ModalWithNewForm name="create_topic" width={400} onSubmit={handleSubmit} afterSubmit={close} modalProps={modalProps}>
			<TopicFormFields />
		</ModalWithNewForm>
	)
}
