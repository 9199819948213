import utils from '@eitje/web_utils'

export const BasicTableTimeStringCell = ({value, ...rest}) => {
	const val = utils.minToTimeString(value, true)

	return <Cell displayValue={val} value={value} {...rest} />
}

const Cell = ({value, displayValue, defaultValue = '-'}) => {
	if (utils.exists(value)) return displayValue
	return defaultValue
}
