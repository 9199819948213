import {useQueryParams} from 'hooks'
import {QuizItem} from 'models'
import {QuizItemModal} from '../components'
import {navigate} from 'components/routing'

export const NewQuizItem = () => {
	const {topic_id} = useQueryParams()

	if (!topic_id) {
		return navigate('/topics')
	}

	const handleSubmit = async data => {
		return await QuizItem.create({
			...data,
			topic_ids: [topic_id],
		})
	}

	return <QuizItemModal name="new_quiz_item" onSubmit={handleSubmit} />
}
