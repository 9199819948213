import {buildField} from '@eitje/form-fields-web'
import {upload} from 'actions/files'
import {Layout, EitjeButton} from 'common/components'
import {useRef, useState} from 'react'

const inlineStyles = {borderRadius: '50%', overflow: 'hidden'}

const Field = ({value, onChange, ...rest}) => {
	const hiddenInput = useRef(null)
	const openImagePicker = () => hiddenInput.current.click()
	const showImage = value
	const showButton = !value

	const afterImageSelect = async event => {
		const image = event.target.files
		const url = await upload(image)
		if (url) onChange(url)
	}

	return (
		<Layout>
			{showImage && (
				<Layout onClick={openImagePicker} width={40} height={40} border borderRadius style={inlineStyles}>
					<img src={value} style={{width: '100%', height: '100%', objectFit: 'cover'}} />
				</Layout>
			)}
			{showButton && <EitjeButton iconLeft="upload" onClick={openImagePicker} />}
			<input ref={hiddenInput} style={{display: 'none'}} accept="image/*" type="file" id="single" onChange={afterImageSelect} />
		</Layout>
	)
}

export const AvatarPicker = buildField(Field, {className: 'eitje-avatar-picker', clearIcon: false, inputPosition: 'right'})
