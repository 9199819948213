import {useEffect} from 'react'
import {ButtonBar, Layout, Title} from 'common/components'
import {useTrainingContext} from 'cores/knowledge'

export const QuizItem = ({item}) => {
	const {selectedAnswer, setSelectedAnswer} = useTrainingContext()
	const {id, question, answers} = item

	useEffect(() => {
		setSelectedAnswer(null)
	}, [id])

	return (
		<Layout direction="vertical" width={550} margin="48 - - -" gap={12}>
			<Title>{question}</Title>
			<Layout direction="vertical" width="full">
				{answers.map((answer, index) => (
					<ButtonBar
						key={`${index}-${answer}`}
						label={answer}
						numberLeft={index + 1}
						onClick={() => setSelectedAnswer(index)}
						active={selectedAnswer === index}
						height={null}
					/>
				))}
			</Layout>
		</Layout>
	)
}
