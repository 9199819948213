import utils from '@eitje/web_utils'
import {useSimpleContext} from 'contexts/simple'
import {t} from 'initializers/i18n'
import {Bill, Divider, Row, SignedRow} from './index'

export const OvertimeBill = () => {
	return (
		<Bill
			PeriodExtra={PeriodExtra}
			hasWarning={hasWarning}
			CalculateExtra={CalculateExtra}
			CalculateTopExtra={CalculateTopExtra}
			WarningExtra={WarningExtra}
			kind="overtime"
		/>
	)
}

const PeriodExtra = ({fixed_days}) => <Row label={t('common.fixed_contract_days')} value={fixed_days} />

const CalculateTopExtra = ({contract_mins}) => {
	return <SignedRow label={t('common.contract_hours_plu')} value={-contract_mins} />
}

const CalculateExtra = ({total_hours = 0, adjustments, worked = 0, leave = 0, special_leave = 0, sick = 0}) => {
	const labelBase = `time_registration.hour_types`
	const {expanded} = useSimpleContext()
	return (
		<>
			<SignedRow id="total_hours" bold label={t('totalHours')} value={total_hours}>
				<SignedRow label={t(`${labelBase}.worked`)} value={worked} />
				<SignedRow label={t(`${labelBase}.leave`)} value={leave} />
				<SignedRow label={t(`${labelBase}.special_leave`)} value={special_leave} />
				<SignedRow label={t(`${labelBase}.sick`)} value={sick} />
			</SignedRow>
			{utils.exists(adjustments) && expanded['total_hours'] && <Divider />}
		</>
	)
}

const hasWarning = ({unaccorded}) => unaccorded

const WarningExtra = ({unaccorded}) => {
	return <SignedRow label={t('time_registration.unapproved_hours')} value={unaccorded} />
}
