import {PopoutCard} from '@eitje/web_components'
import {ExportButton, FilterSentence} from 'cores/exports2/index'
import {t as baseT} from 'initializers/i18n'
import {EitjeButton, Label, DeleteButton, EditButton} from 'common/components'
import './styles/table_card.less'

export const TableCard = ({item}) => {
	const {name, description, draft, stock} = item
	let Actions = MainActions
	if (stock) Actions = StockActions
	if (draft) Actions = DraftActions
	return (
		<div className="table-card">
			<div className="table-card-left">
				<div className="type-and-name">
					<Label t={item._tableName()} height="mini" />
					<h4 className="name"> {name} </h4>
				</div>
				<h5 className="description"> {description} </h5>
			</div>
			<div onClick={e => e.stopPropagation()} className="table-card-right">
				<Actions className="hihi" item={item} stock={stock} draft={draft} />
			</div>
		</div>
	)
}

const DraftActions = ({item}) => {
	return (
		<>
			<EditButton height="small" link={`${item.id}/edit`} t="common.continue" />
			<DeleteButton height="small" onClick={() => item.destroy()} />
		</>
	)
}

const MainActions = ({item, stock, draft}) => {
	return (
		<>
			<ExportButton height="small" iconLeft="paper-plane" id={item.id} />

			{!stock && !draft && (
				<PopoutCard className="table-filters-popout" trigger="click" body={<FilterSentence item={item} />}>
					<EitjeButton iconLeft="filter" height="small">
						{baseT('common.filter', {count: 2})}
					</EitjeButton>
				</PopoutCard>
			)}
		</>
	)
}

const StockActions = ({item}) => {
	return (
		<>
			<EitjeButton data-intercom-target="export-stock-use" iconLeft="plus" height="small" onClick={() => item.duplicate()} t="common.use" />
			<EitjeButton
				data-intercom-target="export-stock-example"
				height="small"
				iconLeft="download"
				externalLink={item.example_url}
				t="common.example"
			/>
		</>
	)
}
