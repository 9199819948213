import {useFind} from '@eitje/easy_api'
import {goBackToForeground} from 'actions/routing'
import {DeleteButton} from 'common/components'
import {ResourceFetcher} from 'components/shared/index'
import {reverseKindMap} from 'cores/balance'
import {useParams} from 'hooks'
import date from 'initializers/date'
import {t} from 'initializers/i18n'
import {BalanceAdjustment} from 'models'
import {AddAdjustment} from './index'

export const ShowAdjustment = props => {
	const {id} = useParams()
	const item = BalanceAdjustment.show()
	const author = useFind('users', item?.author_id)

	if (!item.id) return <ResourceFetcher kind="balance_adjustments" id={id} />

	const onClick = async () => {
		await item.revert()
		goBackToForeground()
	}

	const init = {
		...item,
		amt: Math.abs(item.amt),
		created_by: author.full_name,
		created_at: date(item.created_at).formatDate({year: true}),
		plus_or_minus: item.amt > 0 ? 'increase' : 'decrease',
	}
	return (
		<>
			<AddAdjustment
				{...props}
				showMessageAtTop
				kind={reverseKindMap[item.typ]}
				insertAtIndex={{
					2: (
						<DeleteButton
							confirmText={t('modals.show_adjustment.confirm_text')}
							confirmTitle={`${t('common.revert')}?`}
							t="common.revert"
							deleteButtonProps={{
								deleteText: t('common.revert'),
								deleteIcon: 'arrow-undo',
							}}
							iconLeft="arrow-circle-left"
							onClick={onClick}
						/>
					),
				}}
				fieldProps={{readOnly: true}}
				user={item.user}
				isShow
				initialValues={init}
				name="show_adjustment"
			/>
		</>
	)
}
