import {DatePicker} from '@eitje/form-fields-web'
import {saveSettingGroup} from 'actions/settings'
import {DocsButton, goToNext, HelpButton, Message} from 'common/components'
import {useModalContext} from 'components/common'
import {ModalForm} from 'components/form/index'
import {FormRow} from 'components/ui'
import {useBalanceStartDateEnv} from 'cores/balance'
import {store} from 'index'
import date from 'initializers/date'
export const StartDate = props => {
	const {t} = useModalContext()
	const {item} = props
	const startDate = useBalanceStartDateEnv(item.id)
	const submit = async data => {
		const res = await saveSettingGroup('statistieken', data, {env_id: item.id})
		if (res.ok) {
			store.dispatch({type: 'UPDATE_ENV_SETTING_GROUP', settings: {active: true}, groupName: 'balance', id: item.id})
		}
		return res
	}
	return (
		<ModalForm
			afterSubmit={() => goToNext(props)}
			modalProps={{confirmSubmit: true, buttons: [<DocsButton to="6019469" />], okButtonProps: {confirmText: t('start_date.confirm_text')}}}
			onSubmit={submit}
			initialValues={{vanaf: startDate?.format()}}
			transNamespace="setup_balance.start_date"
		>
			<Message children={t('start_date.message')} form />
			<FormRow>
				<DatePicker disabledAfter={date()} i18nOpts={{envName: item.naam}} field="vanaf" />
			</FormRow>
		</ModalForm>
	)
}
