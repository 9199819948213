import {API, initApi, setup} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {authLogout, refreshTokenIfNeeded} from 'actions/auth'
import {navigateModal} from 'actions/routing'
import {createUrls, indexUrls, stampFields, updateUrls} from 'constants/api'
import {isProd} from 'constants/general'
import {makeRange} from 'helpers/date'
import {store} from 'index'
import {ENV, HOST} from 'initializers/api'
import {date} from 'initializers/date'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import dbConfig from 'models/db_config'
import models, {ApplicationRecord, Association} from 'models/index'
import pluralize from 'pluralize'
import Reactotron from 'reactotron-react-js'

import {navigate} from 'components/routing'
import {navigateAndReloadHome} from 'cores/auth'

let api
let backend = api

const toLocalType = typ => pluralize(typ).toLowerCase()

const relFields = {
	teams: ['user_ids'],
	skillSets: ['skill_ids'],
	skills: ['skill_set_ids'],
	users: ['team_ids', 'environment_ids'],
	environments: ['user_ids'],
	topics: ['info_ids'],
	infos: ['topic_ids'],
	quizItems: ['topic_ids'],
}

const relKeys = Object.keys(relFields)

const updateAssociation = ({recordId, relationKind, state, kind, currentVal, nextVal}) => {
	const assocRecords = state[relationKind]
	if (!assocRecords) return
	const assocField = `${utils.camelToSnake(pluralize.singular(kind))}_ids`
	const toBeAdded = nextVal.filter(id => !currentVal.includes(id))
	const toBeRemoved = currentVal.filter(id => !nextVal.includes(id))

	const newItems = []

	toBeAdded.forEach(id => {
		const assocRecord = assocRecords.find(i => i.id === id)
		if (assocRecord) {
			const newVal = [...assocRecord[assocField], recordId]
			newItems.push({...assocRecord, [assocField]: newVal})
		}
	})

	toBeRemoved.forEach(id => {
		const assocRecord = assocRecords.find(i => i.id === id)
		const newVal = assocRecord[assocField].filter(id => id != recordId)
		newItems.push({...assocRecord, [assocField]: newVal})
	})

	API.createMultiLocal(relationKind, newItems)
}

const afterAdd = (kind, item, params) => {
	kind = utils.snakeToCamel(kind)
	if (!relKeys.includes(kind)) return
	const state = store.getState().records

	const records = state[kind]
	if (!records) return
	const keys = Object.keys(item)
	const currentItem = records.find(r => r.id === item.id)
	const relVals = relFields[kind]

	relVals.forEach(val => {
		if (keys.includes(val)) {
			const currentVal = currentItem ? currentItem[val] : []
			const nextVal = item[val]
			const relationKind = toLocalType(val.split('_')[0])
			if (!_.isEqual(currentVal, nextVal)) {
				if (kind == 'infos' || kind == 'quizItems') return API.index('topics') // is connected through topic resources, so a 'simple' updateAssoc doesn't work and I'm too lazy to update topic recs locally
				updateAssociation({state, relationKind, kind, currentVal, recordId: item.id, nextVal})
			}
		}
	})
}

const untangleRecMap = ({resource_map}) => {
	let ordered = _.mapValues(_.groupBy(resource_map, 'kind'), arrOfItems => arrOfItems.map(i => i.resource_id))
	ordered = _.mapKeys(ordered, (value, key) => `${utils.camelToSnake(key)}_ids`.substring(1)) // hack cuz camelToSnake cant handle Pascal
	return ordered
}

export const enrichRecords = (ents, key) => {
	const records = ents[key]
	if (!records) return null
	switch (key) {
		case 'checkIns':
			return records.map(r => ({...r, time_registration_shift_ids: r.shift_ids}))
		case 'availabilityShifts':
			return records.map(r => ({...r, dateRange: makeRange(r.start_datetime, r.end_datetime)}))
		case 'planningShifts':
			return records.map(r => ({...r, dateRange: makeRange(r.start_date, r.end_date)}))
		case 'sickPeriods':
			return records.map(r => ({...r, dateRange: makeRange(r.start, r.end ? date(r.end).endOf('day') : r.end)}))
		case 'leaveRequests':
			return records.map(r => ({...r, dateRange: makeLeaveRange(r)}))
		case 'topics':
			return records.map(r => ({...r, ...untangleRecMap(r), amountItems: Object.keys(r.resource_map || {}).length}))
		case 'skillSets':
			return _.orderBy(records, 'idx')
		case 'infos':
			return _.orderBy(records, 'general_order', 'desc')
		case 'salaries':
		case 'workSchedules':
		case 'contractHolders':
		case 'userEmploymentTypes':
			let newRecords = records
			if (key == 'salaries') newRecords = records.map(r => ({...r, amount: Number(r.amount)}))
			newRecords = records.map(r => ({...r, dateRange: makeRange(r.start_date, r.end_date)}))
			return _.orderBy(newRecords, 'start_date', 'desc')
		case 'exportTableLayouts':
			return _.orderBy(records, 'sort_order')
		case 'exportTablePrivateTemplates':
			return _.orderBy(records, 'name')
	}
}

const makeLeaveRange = request => {
	const {start_datetime, end_datetime, start, eind} = request
	if (start_datetime && end_datetime) return makeRange(start_datetime, end_datetime)
	return makeRange(start, eind)
}

const afterIndex = (kind, items = [], {localKind}) => {
	if (!items || !_.isArray(items)) return []
	const state = store.getState()

	switch (kind) {
		case 'events':
			getAssocs(state.records, items, 'posts')
			break
		case 'posts':
			getAssocs(state.records, items, 'events')
			getAssocs(state.records, items, 'infos', {multi: true})
			break
	}
	return items
}

function getAssocs(allRecords, newRecords, tableName, {multi} = {}) {
	const singleTableName = pluralize.singular(tableName)
	const records = allRecords[tableName] || []
	if (!_.isArray(records)) return

	const currentIds = records.map(i => i.id)
	const suffix = multi ? 'ids' : 'id'
	const newIds = newRecords
		.map(i => i[`${singleTableName}_${suffix}`])
		.filter(i => i && !currentIds.includes(i))
		.flat()

	if (newIds.length > 0) {
		API.getByIds(tableName, newIds)
	}
}

const orderByStartDate = items => {
	return _.orderBy(items, 'start_date')
}

const sortFuncs = {
	posts: items => {
		return _.orderBy(items, 'created_at', 'desc')
	},
	users: items => {
		return _.orderBy(items, 'full_name', 'asc')
	},
}

const init = () => {
	setup({
		t,
		store,
		baseURL: HOST,
		sortFuncs,
		stampFields,
		alert: utils.errNotif,
		success: utils.success,
		updateUrls,
		createUrls,
		logout: authLogout,
		afterIndex,
		indexUrls,
		isProd,
		formErrors: true,
		getRefreshToken: refreshTokenIfNeeded,
		afterAdd,
		enrichRecords,
		models,
		ApplicationRecord,
		Association,
		dbConfig,
		version: process.env.REACT_APP_VERSION || '3.8.0',
	})

	api = initApi()
	api.addAsyncRequestTransform(addEnvId)

	api.addRequestTransform(addWebInfo)
	api.addMonitor(eitjeServerRunning)

	if (ENV == 'mirror') {
		api.addRequestTransform(addReq)
		api.addMonitor(delReq)
	}

	api.addMonitor(Reactotron.apisauce)
	api.addMonitor(handleFullErrors)
}

const handleFullErrors = res => {
	const {errors = [], is_full_error} = res.data
	if (!is_full_error || !utils.exists(errors)) return

	const kind = res.config?.url?.split('/')?.[0]

	utils.errorNotif({
		onClick: () => navigateModal(`/${kind}/handle_errors`, {replace: true}),
		content: t('handle_errors.notification'),
		duration: 10,
	})

	return store.dispatch({type: 'SET_RECORD_ERRORS', payload: errors})
}

const addReq = () => {
	if (!window) return
	if (!window.requestsPending) window.requestsPending = 0
	window.requestsPending += 1
}

const delReq = () => {
	if (!window || !window.requestsPending) return
	window.requestsPending -= 1
}

async function addWebInfo(req) {
	if (!req.params) req.params = {}
	req.params['isWeb'] = true
	req.params['page'] = window.location?.pathname
}

const eitjeServerRunning = res => {
	const hasInternet = navigator.onLine
	const serverDown = hasInternet && res.problem === 'NETWORK_ERROR'
	const onDowntimePage = window.location.pathname == '/server_down'

	if (serverDown && !onDowntimePage) {
		navigate('/server_down')
	} else if (!serverDown && onDowntimePage) {
		navigateAndReloadHome()
	}
}

async function addEnvId(req) {
	const st = store.getState()
	const hasEnvId = (req.data && req.data['env_id']) || (req.params && req.params['env_id'])
	if (hasEnvId) return
	if (!req.params) req.params = {}
	req.params['env_id'] = st.environment.active
}

export {api, backend, init}
export default backend
