import {Input, Switch, FormRow} from '@eitje/form-fields-web'
import {AvatarPicker} from 'lib/form/fields/avatar_picker'
import {ListPicker, DocsPopout} from 'common/components'
import {useRoleOrgEnvs, useShared} from 'hooks'
import {Team} from 'models'

export const TopicFormFields = ({showPublishedField}) => {
	const {orgEnvs} = useShared()
	const managerEnvs = useRoleOrgEnvs('manager')
	const managerTeams = Team.where({environment_id: managerEnvs.ids()})

	const environments = managerTeams._map('environment_id').uniq()
	const teamFieldProps = environments.length > 1 && {
		groupField: 'environment_id',
		groupItems: orgEnvs,
		groupLabelField: 'naam',
		groupReadOnly: true,
	}

	return (
		<>
			<FormRow>
				<Input field="title" required maxLength={35} />
			</FormRow>
			<FormRow>
				<Input field="subtitle" required maxLength={140} textarea />
			</FormRow>
			{showPublishedField && (
				<FormRow>
					<Switch field="published" tooltip popoutBody PopoutComponent={PublishedPopout} />
				</FormRow>
			)}
			<FormRow>
				<ListPicker
					field="team_ids"
					required
					collapseGroups
					hideEasyRemovePopout
					items={managerTeams}
					dropdown
					labelField="naam"
					tooltip
					popoutBody
					PopoutComponent={TeamPopout}
					maxWidth={400}
					{...teamFieldProps}
				/>
			</FormRow>
			<FormRow>
				<AvatarPicker field="remote_avatar_url" tooltip popoutBody PopoutComponent={ImagePopout} />
			</FormRow>
		</>
	)
}

const ns = 'form.topic.fields'
// destructure body from props to prevent it from being passed to the BasicPopout
const ImagePopout = ({body, ...props}) => {
	return <DocsPopout {...props} text={`${ns}.remote_avatar_url.popout_body`} />
}

const PublishedPopout = ({body, ...props}) => {
	return <DocsPopout {...props} text={`${ns}.published.popout_body`} />
}

const TeamPopout = ({body, ...props}) => {
	return <DocsPopout {...props} title="teams" text={`${ns}.team_ids.popout_body`} />
}
