import {makeLegacyField} from '@eitje/form'
import {BaseInput, buildField} from '@eitje/form-fields-web'
import utils from '@eitje/utils'
import {EitjeIcon} from 'components/common/index'
import {formatCssVars} from 'helpers'
import useHotkey from 'hooks/use_hotkey'
import usePrevious from 'hooks/use_previous'
import {useEffect, useRef, useState} from 'react'
import './styles/hour_minute_picker.less'
import './styles/form_v3_hour_minute_picker.less'

const numOnly = val => val.replace(/[^0-9.]/g, '')

const HourMinutePickerInput = ({onChange, showArrows, minimum = 0, autoFocus, value, max, maxHourDigits = 2, ...rest}) => {
	const hourInput = useRef(null)
	const minInput = useRef(null)
	const [hour, setHour] = useState(null)
	const [min, setMin] = useState(null)
	const prevValue = usePrevious(value)

	const changeHour = val => {
		val = val.toString()
		if (_.isString(val)) val = val.slice(0, maxHourDigits)
		const fullVal = getVal({hours: val}) / 60
		if (fullVal > max) return
		if (fullVal < minimum) return
		if (isNaN(fullVal)) return
		setHour(val)
		setHourMin({hours: val})
	}

	const changeMin = val => {
		const numVal = Number(val)
		if (numVal > 59) return

		const fullVal = getVal({mins: val}) / 60
		if (fullVal > max) return

		val = val.slice(0, 2)
		setMin(val)
		setHourMin({mins: val})
	}

	const onMinBlur = val => {
		// Always prefix a single digit with a zero, so that you can't end up with a time of 1:7, but becomes 1:07 instead.
		if (min && min < 10) {
			setMin(String(Number(min)).padStart(2, '0'))
		}
	}

	const setHourMin = obj => {
		const val = getVal(obj)
		onChange(val)
	}

	const getVal = ({mins = min, hours = hour}) => {
		const timeString = `${hours || 0}:${mins || 0}`
		return utils.timeStringToMin(timeString)
	}

	useEffect(() => {
		if (value != prevValue) {
			const [_hours, _mins] = utils.minToHourArray(value || 0)
			const _prev = prevValue
			setHour(utils.zeroOrNaN(_hours) ? null : _hours)
			setMin(utils.zeroOrNaN(_mins) ? null : _mins)
		}
	}, [value])

	const focusMin = e => {
		if (e.key == 'ArrowLeft' || e.key == 'Backspace') return
		const currentSel = e.target?.selectionEnd

		if (e.key === 'ArrowRight') {
			if (currentSel == `${hour}`.length) {
				minInput.current?.focus()
				return
			}
		}
		if (currentSel >= maxHourDigits) minInput.current?.focus()
	}

	const onKeyDown = e => {
		const currentSel = e.target?.selectionEnd
		if (currentSel != 0) return
		hourInput.current?.focus()
	}
	const _onKeyDown = useHotkey({backspace: onKeyDown})
	const _onKeyUp = useHotkey({arrowleft: onKeyDown})
	const hourDigitCount = hour ? hour.toString().length : 0
	const styles = formatCssVars({'hour-digits': hourDigitCount})
	const classNames = utils.makeCns('eitje-hour-minute-picker-input', hourDigitCount == 0 ? 'no-hours-entered' : 'hours-entered')

	return (
		<div style={styles} className={classNames}>
			<BaseInput
				{...rest}
				className="eitje-hour-minute-picker-input-left"
				bordered={false}
				placeholder="00"
				onKeyDown={focusMin}
				value={hour}
				formatValue={numOnly}
				onChange={changeHour}
				innerRef={hourInput}
				autoFocus={autoFocus}
			/>
			<span className="eitje-hour-minute-picker-divider">:</span>
			<BaseInput
				{...rest}
				className="eitje-hour-minute-picker-input-right"
				bordered={false}
				onFocus={() => !hour && !min && hourInput.current?.focus()}
				placeholder="00"
				onKeyDown={_onKeyDown}
				onBlur={onMinBlur}
				onKeyUp={_onKeyUp}
				innerRef={minInput}
				value={min}
				formatValue={numOnly}
				onChange={val => changeMin(val)}
			/>
			{showArrows && <Arrows onChange={num => changeHour(hour + num)} />}
		</div>
	)
}

const Arrows = ({onChange}) => {
	return (
		<div className="eitje-hour-minute-picker-arrows">
			<EitjeIcon src="arrow_filled_up" onClick={() => onChange(1)} />
			<EitjeIcon src="arrow_filled_down" onClick={() => onChange(-1)} />
		</div>
	)
}

const HourMinutePicker = buildField(HourMinutePickerInput, {className: 'eitje-hour-minute-picker-container'})
const LegacyHourMinutePicker = makeLegacyField(HourMinutePickerInput, {className: 'eitje-hour-minute-picker-container'})

export {HourMinutePicker, HourMinutePickerInput, LegacyHourMinutePicker}
