import {useSelector} from 'react-redux'
import {trainingItemsSelector} from 'selectors/training'

export const useCurrentTrainingStep = id => {
	const trainingItems = useSelector(trainingItemsSelector)
	const trainingItem = trainingItems.find(t => t.id === id) || {}
	const {training_resources = []} = trainingItem
	const progressIdx = training_resources.findIndex(tr => !tr.finished)

	return progressIdx > -1 ? progressIdx : 0
}
