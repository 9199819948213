import {ListPicker} from 'common/components'
import {t} from 'initializers/i18n'

const periods = ['day', 'week', 'month', 'flex_wage_period', 'hours_wage_period', 'quarter', 'year']
const makeItems = items => items.map(i => ({name: t(`common.${i}`), id: i}))

export const PeriodListPicker = ({without = [], ...props}) => {
	const _periods = periods.filter(p => !without.includes(p))
	return (
		<ListPicker data-intercom-target="period-list-picker" dropdown single noSort items={makeItems(_periods)} field="period" {...props} />
	)
}
