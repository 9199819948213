import {useEnvSetting, useOrgEnvSetting} from 'hooks'
import {date} from 'initializers/date'
import {useGet} from 'hooks'

export const useBalanceStartDate = () => {
	const dates = useOrgEnvSetting('statistieken', 'vanaf').filter(Boolean)
	return dates.map(d => date(d))
}

export const useBalanceStartDateRaw = () => {
	const dates = useOrgEnvSetting('statistieken', 'vanaf').filter(Boolean)
	return dates
}

export const useBalanceStartDateLowest = () => {
	return _.min(useBalanceStartDate())
}

export const useBalanceStartDateEnv = envId => {
	const startDate = useEnvSetting('statistieken', 'vanaf', envId)
	if (!startDate) return null
	return date(startDate)
}

export const useUserBalanceBasics = (userId, watchArg) => {
	let watch = userId
	if (watch) watch += `-${watchArg}`
	const {data, loading, retry} = useGet(`balance/basics?user_id=${userId}`, {skip: !userId, watch})

	return {data: data?.balance_data || {}, loading, retry}
}

export const useUserBalanceInfo = (userId, watch) => {
	const {data, loading, retry} = useUserBalanceBasics(userId, watch)
	const {environment_id, env_start_date, overtime_start_date, leave_start_date, ...rest} = data

	return {
		envStartDate: date(env_start_date),
		environment_id,
		loading,
		retry,
		overtime_start_date,
		leave_start_date,
		overtimeStart: date(overtime_start_date),
		leaveStart: date(leave_start_date),
		...rest,
	}
}

export const useAllUserBalanceBasics = () => {
	const {data} = useGet(`balance/basics/all`)
	return data?.items || []
}
