import {useHasRole, useShared} from 'hooks'
import {prettyPrintArray} from 'helpers'
import {t} from 'initializers/i18n'
import {ENV} from 'initializers/api'
import utils from '@eitje/utils'

export const useRoleMessage = props => {
	const {orgEnvs} = useShared()

	let propsObject
	// Allow a string to be passed for a quick any env check, or an object for more complex checks
	if (_.isString(props)) propsObject = {roles: props}
	// allow undefined being passed for easy access, simply return
	if (!props) propsObject = {roles: null}
	// fallback to accept the passed object, if above conditions are untrue
	if (!propsObject) propsObject = props
	let {env = 'any', roles} = propsObject
	if (!env) env = 'any'
	// Figure out what the envIds are, so that we may pass environments to this function in many different
	// ways for easy access.
	let envIds
	const array = _.isArray(env)
	const item = env[0]

	if (_.isNumber(env)) envIds = [env]
	if (!array && env.tableName === 'environments') envIds = [env.id]
	if (array && _.isNumber(item)) envIds = env
	if (array && item?.tableName === 'environments') envIds = env._map('id')

	// Simply return nothing if the item shouldn't be disabled
	const anyEnvRole = useHasRole(roles, envIds)
	if (anyEnvRole || !roles) return null

	// Explain why the item is disabled
	return formatRoleMessage({roles, env, envIds, orgEnvs})
}

export const hooklessRoleMessage = ({roles, envId, roleEnvs, orgEnvs}) => {
	// This function is an absolutely retarded nuisance, but sometimes we just have to use it, when we can't run hooks
	// due to a differing amount of items. This happens outside components, e.g. in a ListPicker taxonomy. Simply pass
	// all hook-based props from the single render parent component and use this method to receive the formatted role
	// message.
	const envIds = Array.isArray(envId) ? envId : [envId]
	const hasRole = roleEnvs.some(roleEnv => envIds.includes(roleEnv.id))
	return !hasRole && formatRoleMessage({roles, envIds, orgEnvs})
}

const formatRoleMessage = ({roles, env, envIds, orgEnvs}) => {
	const missingRole = t('role_message.missing_role')
	const showEnvNames = env !== 'any' && env !== 'current'

	// Filter envIds on org to dump any possible incorrect arguments
	const orgEnvIds = orgEnvs.ids()
	const envIdsInOrg = envIds?.filter(id => orgEnvIds.includes(id))

	// Translate and format the roles
	const rolesArray = _.isArray(roles) ? roles : roles?.split(' ')
	const rolesWithAdmin = _.uniq([...rolesArray, 'admin'])
	const translatedRoles = rolesWithAdmin.map(r => `${t(`rolesEnum.${r}`)}`)
	const roleNames = prettyPrintArray({array: translatedRoles})

	// Show "in current", "in any" or "in ..." environments
	const envTKey = showEnvNames ? '' : `${env}_`
	let inEnvs = t(`role_message.be_in_${envTKey}env`)

	// If multiple envs but not any, show in which envs the role is missing
	const orgEnvsObject = _.keyBy(orgEnvs, 'id')
	const envNames = envIdsInOrg?.map(id => _.get(orgEnvsObject[id], 'naam', null))
	const envDescription = envNames && showEnvNames ? prettyPrintArray({array: envNames}) : ''

	// If there is a environment id, but the user does not belong to that environment at all (instead of simply missing
	// the required role), we don't store that env in Redux, so we can't print the name. Fallback to a generic message
	// that explains that the user does not belong to the env.
	if (showEnvNames && _.isEmpty(envNames)) {
		inEnvs = t('role_message.be_in_unknown_env')
	}

	return `${missingRole}${roleNames} ${inEnvs}${envDescription}`
}
