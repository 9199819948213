import _ from 'lodash'

const initialState = {
	accordShifts: [],
	ignoredAccordShifts: [],
	loading: false,
	plusMinUsers: {},
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
			return initialState

		case 'SHIFTS_LOADING': // used by user_uren
			return {
				...state,
				loading: true,
			}

		case 'SHIFTS_LOADED': // used by user_uren
			return {
				...state,
				loading: false,
			}

		case 'GET_SHIFTS': // used by user_uren
			const s = action.shifts.filter(s => s.reg && s.published) || []
			const ign = state.ignored || []
			return {
				...state,
				accordShifts: action.shifts,
				ignoredAccordShifts: _.uniq([...ign, ...s.map(s => s.id)]),
			}

		default:
			return state
	}
}
