import {AuthPageLayout} from 'cores/auth'
import {Input} from '@eitje/form-fields-web'
import {API} from '@eitje/easy_api'
import {useShared} from 'hooks'
import {navigate} from 'components/routing'

export const UserInfo = () => {
	const {me} = useShared()
	const {voornaam, achternaam} = me

	// Make inaccessible for authed users that have already set their first and last name
	if (voornaam && achternaam) {
		return navigate('/news')
	}

	// Show the first and last name if the manager has already entered them
	const dontShowEmail = name => (name?.includes('@') ? null : name)
	const initialValues = {voornaam: dontShowEmail(voornaam), achternaam: dontShowEmail(achternaam)}

	const onSubmit = data => API.update('users', {id: me.id, skipSuccessNotif: true, ...data})
	const afterSubmit = () => navigate('/news')

	return (
		<AuthPageLayout name="user_info" currentStep={2} steps={2} onSubmit={onSubmit} afterSubmit={afterSubmit} initialValues={initialValues}>
			<Input field="voornaam" required />
			<Input field="achternaam" required />
		</AuthPageLayout>
	)
}
