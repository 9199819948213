import {BasicTable, usePageContext} from 'common/components'
import {ModalLink} from 'components/routing'
import {kindMap} from 'cores/balance'
import {date} from 'initializers/date'
import {BalanceAdjustment} from 'models'
import {columns, textRows} from './cells'
import {getContractItems, transformBalance} from './helpers'
import {t} from 'initializers/i18n'

export const Table = () => {
	const {balance, startDate, endDate, terminationDate, envStartDate, environment_id, user, kind, periodType} = usePageContext()

	const _terminationDate = terminationDate && date(terminationDate)

	const empTypeItems = getContractItems(user, environment_id, kind, endDate)
	const transformed = transformBalance(balance, periodType, {empTypeItems, terminationDate})

	const typ = kindMap[kind]
	let adjustments = BalanceAdjustment.doIndex({filters: {user_id: user.id}}, {watch: [user.id]}).where({
		initial: null,
		typ,
		user_id: user.id,
	})

	adjustments = adjustments.filter(a => envStartDate.isSameOrBefore(a.date) && a.amt != 0)

	const initial = BalanceAdjustment.find({typ, user_id: user.id, initial: true})

	const initialAdjustment = {
		...initial,
		date: startDate.format(),
		label: startDate.formatDate({year: true}),
		typeId: 'initial_balance',
		terminatedTooltip: !!terminationDate,
		clickable: true,
	}

	const terminationDateObject = terminationDate && {
		date: _terminationDate.clone().add(20, 'year').format(),
		label: terminationDate,
		typeId: 'termination_date',
	}

	const mappedAdjustments = adjustments.map(a => ({...a, date: a.date > endDate ? endDate : a.date, typeId: 'adjustment', clickable: true}))

	let final = [...empTypeItems, initialAdjustment, ...mappedAdjustments, ...transformed, terminationDate && terminationDateObject].filter(
		Boolean,
	)
	final = _.orderBy(final, 'date').reverse()

	return (
		<BasicTable
			textRows={textRows}
			tooltip={props => props.item.terminatedTooltip && t('basic_tables.balance_show.initial_disabled')}
			layoutProps={props => props.item.terminatedTooltip && {colorSet: 'disabled'}}
			RowWrapper={({item, ...rest}) => <ModalLink to={`periods/${item.periodType}/${item.period}`} {...rest} />}
			date={date}
			withBorders
			endOfContentPlaceholder
			name="balance_show"
			data={final}
			columns={columns}
			user={user}
		/>
	)
}
