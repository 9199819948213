import {tableTypes, useTablesContext} from 'cores/exports2/index'
import {t} from 'cores/exports2/pages/tables/index/index'
import {CreateButton, TourButton} from 'common/components'
import utils from '@eitje/web_utils'
import {ModalLink} from 'components/routing'
import './styles/index.less'
import {DocsButton} from 'common/components'

export const Header = () => {
	const {isAdmin} = useTablesContext()
	return (
		<div className="exports-tables-index-header">
			<h3 className="exports-tables-title"> {t('header.title')} </h3>
			<p className="exports-tables-subtitle"> {t('header.subtitle')} </p>
			<DocsLinks />
			{isAdmin && <CreateButton link="new" children={t('header.new_export')} />}
		</div>
	)
}

export const TypeSelector = () => {
	const {isAdmin} = useTablesContext()
	const types = isAdmin ? tableTypes : [tableTypes[0]]
	return (
		<div className="exports-tables-type-picker">
			{types.map(t => (
				<TypePicker type={t} />
			))}
			<ModalLink to="/exports/hours">
				<div className="exports-tables-type">
					<h4 className="exports-table-type-count"> 10 </h4>
					<p className="exports-table-type-title"> {t('static_exports')}</p>
				</div>
			</ModalLink>
		</div>
	)
}

const TypePicker = ({type}) => {
	const {tables = [], activeType} = useTablesContext()
	const relevant = tables.filter(t => t.kind() == type)
	const classNames = utils.makeCns('exports-tables-type', type == activeType && 'active')
	if (relevant.length == 0 && type == 'draft') return null
	return (
		<div data-intercom-target={`exports-nav-btn-${type}`} className={classNames} onClick={() => scrollToType(type)}>
			<h4 className="exports-table-type-count"> {relevant.length || '-'} </h4>
			<p className="exports-table-type-title"> {t(type)} </p>
		</div>
	)
}

const scrollToType = type => {
	const el = document.querySelector(`.exports-tables-block-${type} .exports-tables-block-title-container`)
	el?.scrollIntoView({block: 'center', inline: 'nearest'})
}

const DocsLinks = () => {
	const {isAdmin} = useTablesContext()
	return (
		<div className="exports-tables-index-header-docs">
			<DocsButton to="7979079" text={t('header.docs_one')} height="small" />
			{isAdmin && <TourButton height="small" tourId={447826} />}
		</div>
	)
}

export default Header
