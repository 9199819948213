import {BalanceIndex} from './index'
import {t} from 'initializers/i18n'
import {Label, PopoutCard} from 'common/components'
import {useEmploymentTypes} from 'cores/contract'
import {labelProps} from 'cores/balance'
import {Fragment} from 'react'
export const LeaveBalance = () => {
	return <BalanceIndex kind="leave" Labels={Labels} />
}

const Labels = () => {
	const empTypes = useEmploymentTypes()
	const {fixed, flex, payroll} = empTypes

	return (
		<>
			<BalanceLabel empTypes={flex} kind="flex" />
			<BalanceLabel empTypes={fixed} kind="fixed" />
			<BalanceLabel empTypes={payroll} kind="payroll" />
		</>
	)
}

const MultiDatePopout = ({empTypes, ...props}) => {
	const title = empTypes.map(e => {
		const factor = !e.hasLeave() ? t('common.none') : e.leave_factor
		const envName = e.environment?.naam || t('common.unknown_environment')
		return `${envName}: ${factor}\n`
	})
	return <PopoutCard {...props} title={title} />
}

const BalanceLabel = ({kind, empTypes}) => {
	const hasInactive = empTypes.some(e => e.leave_registration == 'no_leave')
	const hasActive = empTypes.some(e => e.leave_registration != 'no_leave')

	const allInactive = hasInactive && !hasActive

	const multipleFactors =
		empTypes
			.filter(e => e.hasLeave())
			._map('leave_factor')
			.uniq().length > 1

	const isIndeterminate = (hasInactive && hasActive) || multipleFactors

	const _factor = allInactive ? '-' : isIndeterminate ? t('balance.multiple_factors') : empTypes[0].leave_factor

	const Wrapper = isIndeterminate ? MultiDatePopout : Fragment

	return (
		<Wrapper empTypes={empTypes}>
			<Label {...labelProps} t={{key: `balance.leave_index.${kind}_label`, factor: _factor}} />
		</Wrapper>
	)
}
