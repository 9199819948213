import createCachedSelector from 'initializers/selectors'
import {activeUsersSelector} from './active_users'
import {all} from '@eitje/easy_api'

export const activeUsersContractsSelector = createCachedSelector(
	activeUsersSelector,
	state => all(state, 'contractHolders'),
	(users, contracts) => {
		const activeUserIds = users._map('id')
		return contracts.where({user_id: activeUserIds})
	},
)
