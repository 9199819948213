import {SearchEmpty} from 'hooks'
import {Placeholder as _Placeholder} from 'common/components'
import {useBasicTableContext} from 'common/components/basic_table'

const layoutProps = {width: 'full', height: 'unset', margin: '48px 0 0 0'}

export const Placeholder = () => {
	const {hasData, hasFiltered, clearFilters, placeholderProps} = useBasicTableContext()

	if (hasData) {
		return null
	}

	if (hasFiltered) {
		return <SearchEmpty action={clearFilters} layoutProps={layoutProps} />
	}

	if (placeholderProps) {
		return <_Placeholder layoutProps={layoutProps} {...placeholderProps} />
	}

	return null
}
