import {EitjeModal} from 'components/common/index'
import utils from '@eitje/web_utils'
import {Routes} from 'components/routing'
import {SideMenu} from 'common/components'
import {useRouteMatch, useLocation} from 'react-router-dom'
import {getStringUpToSubstr} from 'helpers'
import {buildRoute} from 'common/components'
import {useCallback} from 'react'

// routes - array of route definitions
// links - array of actual links to be shown in the side menu (default is routes for backwards compatibility)
export const NewSideMenuModal = ({routes, links = routes, name, children, className, sortFn, sideMenuProps = {}, ...rest}) => {
	const match = useRouteMatch()
	const {path} = match
	const classNames = utils.makeCns('side-menu-modal', className)
	const loc = useLocation()
	const basePath = getStringUpToSubstr(loc.pathname, name)
	const sideMenuRoutes = routes.map(r => buildRoute(r, sideMenuProps, basePath))
	const menuProps = {...sideMenuRoutes, ...sideMenuProps, name}

	if (sortFn) {
		routes = routes.sortBy(item => sortFn(item))
		routes.forEach(route => {
			route.subRoutes = route.subRoutes.sortBy(item => sortFn(item))
		})
	}

	const LeftPanel = useCallback(
		() => <SideMenu modal children={children} routes={links} {...menuProps} path={path} />,
		[children, links, menuProps, path],
	)

	return (
		<EitjeModal className={classNames} LeftPanel={LeftPanel} name={name} goBackToForeground {...rest}>
			<Routes routes={routes} path={path} sideMenuRoutes={sideMenuRoutes} {...sideMenuProps} />
		</EitjeModal>
	)
}

export default NewSideMenuModal
