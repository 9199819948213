import {useEffect} from 'react'
import {t} from 'initializers/i18n'
import {navigateModal} from 'actions'
import {Layout, EitjeButton, UserListPopout, DocsPopout, Placeholder, Message} from 'common/components'
import {useModalContext} from 'components/common/index'
import {useTopicContext, NAMESPACE} from 'cores/knowledge'
import {ResultField} from '.'
import {useTrainingStats} from './hooks'

export const ResultsOverview = ({match, basePath, userCount}) => {
	const topic = useTopicContext()
	const {startedUsers, finishedUsers, notStartedUsers, averageScore, worstQuestion, bestQuestion} = useTrainingStats(topic)
	const showPlaceholder = useShowPlaceholder({topic, userCount})

	if (showPlaceholder) {
		return (
			<Placeholder animation="waving-people" name="training_not_available" customButtons={[<ManageTrainingButton topicId={topic.id} />]} />
		)
	}

	const handleUserClick = id => {
		const baseUrl = match.url.replace(basePath, 'users')
		navigateModal(`${baseUrl}${id}`, {keepBackground: true})
	}

	const StartedPopout = props => <UserListPopout users={startedUsers} placement="bottom-start" onItemClick={handleUserClick} {...props} />
	const FinishedPopout = props => <UserListPopout users={finishedUsers} placement="bottom-start" onItemClick={handleUserClick} {...props} />
	const NotStartedPopout = props => (
		<UserListPopout users={notStartedUsers} placement="bottom-start" onItemClick={handleUserClick} {...props} />
	)

	return (
		<Layout className="results-overview" direction="vertical" gap={0}>
			<Layout borderBottom>
				<Message t={`${NAMESPACE}.results_overview_message`} colorSet="color-bordered-fill" />
			</Layout>
			<ResultField t="started" value={startedUsers?.length} labelPopoutComponent={startedUsers?.length && StartedPopout} />
			<ResultField t="finished" value={finishedUsers?.length} labelPopoutComponent={finishedUsers?.length && FinishedPopout} />
			<ResultField t="not_started" value={notStartedUsers?.length} labelPopoutComponent={notStartedUsers?.length && NotStartedPopout} />
			<ResultField t="average_score" value={averageScore} labelPopoutComponent={AverageScorePopout} />
			{worstQuestion && (
				<ResultField label={t(`${NAMESPACE}.worst_question`, {score: worstQuestion.score})} value={worstQuestion.question} />
			)}
			{bestQuestion && <ResultField label={t(`${NAMESPACE}.best_question`, {score: bestQuestion.score})} value={bestQuestion.question} />}
		</Layout>
	)
}

function useShowPlaceholder({topic, userCount}) {
	const {setModalProps} = useModalContext()
	const {trainable, user_answers} = topic
	const hasNoAnswers = !user_answers?.length
	const hasNoUsers = !userCount
	const showPlaceholder = !trainable && (hasNoAnswers || hasNoUsers)

	useEffect(() => {
		const modalProps = showPlaceholder
			? {LeftPanel: null, hideCancel: false}
			: {buttons: [<ManageTrainingButton colorSet="solid" topicId={topic.id} />], hideCancel: true}

		setModalProps(modalProps)
	}, [showPlaceholder])

	return showPlaceholder
}

const ManageTrainingButton = ({topicId, ...rest}) => (
	<EitjeButton t={`${NAMESPACE}.manage_training`} iconLeft="three-sliders" {...rest} modalLink={`/topics/${topicId}/edit/training`} />
)

const AverageScorePopout = props => (
	<DocsPopout
		{...props}
		title={`${NAMESPACE}.average_score`}
		body={t(`${NAMESPACE}.average_score_explanation`)}
		docsId="6015044"
		placement="bottom-start"
	/>
)
