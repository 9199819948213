export const kindMap = {
	overtime: 'plus_min',
	leave: 'verlof',
}

export const reverseKindMap = {
	plus_min: 'overtime',
	verlof: 'leave',
}

export const labelProps = {
	colorSet: 'grey-bordered',
	baseColor: 'blue',
	height: 'small',
}

export const labelContainerProps = {
	width: 'full',
	style: {overflowX: 'auto'},
	className: 'balance-labels',
}

export const articles = [
	{
		id: 6019469,
		t: 'balance.setup',
	},
	{
		id: 6019449,
		t: 'balance.adjustment',
	},
	{
		id: 10294313,
		t: 'balance.overtime_101',
	},
	{id: 10299987, t: 'balance.leave_101'},
]

export const getBalanceArticles = ids => {
	return ids.map(id => articles.find(a => a.t == id)).filter(Boolean)
}

export const getBalanceArticleId = name => {
	return articles.find(art => art.t == name)?.id
}
