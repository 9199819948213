import {useRoleMessage} from 'hooks'
import {t} from 'initializers/i18n'

export const makeRedirectProps = ({role, env}) => {
	const content = useRoleMessage({roles: role, env, debug: true})
	return {
		title: t('modals.all.roles.title'),
		content,
	}
}
