import {useParams as useRouteParams} from 'react-router-dom'

export function useParams() {
	const obj = useRouteParams()

	for (const key in obj) {
		if (key.toLowerCase().endsWith('id')) {
			const value = obj[key]
			if (!isNaN(Number(value))) obj[key] = Number(value)
		}
	}

	return obj
}

export default useParams
