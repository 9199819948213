import {API, where} from '@eitje/easy_api'
import {Switch} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {Tooltip} from 'antd'
import Dropdown from 'components/dropdown'
import useList from 'components/general/list'
import {Link, ModalLink} from 'components/routing'
import {CheckMark, BoxedRow} from 'components/ui'
import {EitjeButton, CreateButton as StockCreateButton, Title} from 'common/components'
import {getImage} from 'helpers/images'
import {getDaysToStart} from 'helpers/topics'
import {useTeamFilterForm} from 'hooks/use_team_filter'
import {ContextForm, useForm} from '@eitje/form'
import {t} from 'initializers/i18n'
import {date} from 'initializers/date'
import pluralize from 'pluralize'
import React, {Fragment, useRef} from 'react'
import {useSelector} from 'react-redux'
import {hasTrainingResource, topicIsTrainable, trainingItemsSelector, usersCompletedTraining} from 'selectors/training'
import '../styles/topics.less'
import '../styles/training.less'
import useTopicResources from './use_topic_resources'
import {SubmitPopout} from 'common/components'

// TODO move to global components
export const SectionHeader = ({head, subText, button, size = 'md'}) => {
	return (
		<div className={`section-header-container ${size}`}>
			<div className="section-header-text-container">
				<div className="section-header-text">{head}</div>
				<p className="section-header-sub-text">{subText}</p>
			</div>
			{button && button}
		</div>
	)
}

// TODO move to global components
export const Tile = ({imgSrc, title, subtitle, optionButton, children}) => {
	return (
		<div className="topic-tile-container" style={{width: '268px'}}>
			{optionButton && <div className="topic-tile-options">{optionButton}</div>}
			<img className="topic-tile-image" src={imgSrc} />
			{(title || subtitle) && (
				<div className="topic-tile-text-container">
					<p>{title}</p>
					<p>{subtitle}</p>
				</div>
			)}
			{children && <div className="topic-tile-footer">{children}</div>}
		</div>
	)
}

const InfoButton = ({text}) => {
	return (
		<Tooltip placement="right" title={text} overlayStyle={{minWidth: '300px'}}>
			<img src={'/images/web/icons/infoIcon.png'} width={16} height={16} />
		</Tooltip>
	)
}

// TODO move to global components
export const TileInfoLine = ({infoText = '', label}) => {
	return (
		<div className="topic-info-label">
			<p className="topic-info-label-text">{label}:</p>
			<InfoButton text={infoText} />
		</div>
	)
}

export const CreateButton = props => {
	const links = [<ModalLink to="/topics/new" t="createTopic" />, <ModalLink to="/articles/new" t="createArticle" />]
	return (
		<Dropdown elements={links}>
			<StockCreateButton {...props} />
		</Dropdown>
	)
}

const trainingPromptKeys = ['always_trainable', 'valid_untill', 'days_to_start']

const afterSubmit = (data, published) => {
	if (data['published']) {
		utils.openNotif(t('saved'), 'success', t('topic_published'))
		return
	}
	if (published) return
	const changedKeys = Object.keys(data)
	if (_.intersection(trainingPromptKeys, changedKeys).length > 0) {
		utils.openNotif(t('saved'), 'info', t('saved_trainable_after_published'))
	}
}

const Trigger = ({title}) => <EitjeButton width="full" children={title} height="small" />

// Only used on the Topic SHow Page
export const TopicDetails = ({item, isManager}) => {
	const {title, id, subtitle, published} = item
	const {starts_at = '2022-10-20'} = useSelector(state => hasTrainingResource(state, id)) || {}
	const {publishedTopicResources = [], topicInfos = []} = useTopicResources(item)
	const hasResources = publishedTopicResources.length > 0 || topicInfos.length > 0
	const unpublishedResources = topicInfos.filter(topic => !topic.published)
	const imgSrc = getImage(item, 'medium') || '/images/placeholder_manual_img.png'
	const {filter} = useTeamFilterForm({
		role: 'manager',
		title: 'team',
		decorated: false,
		Trigger,
	})

	// TODO - should the back end change the fields of 'days_to_start'
	// TODO - expand box should be it's own component so we don't have to worry about the ref in the parent componet

	const formRef = useRef()

	const handlePublishAll = async () => {
		const payload = {id, publish_articles: true, published: true}
		const res = await API.update('topics', payload)
		if (res.ok) {
			afterSubmit(payload, published)
			formRef.current.resetValues()
		}
	}

	const button =
		<TrainButton id={id} /> || (starts_at && <BoxedRow text={`${t('trainingStarts')} ${date(starts_at).format(`Do MMM YY`)}`} disabled />)

	return (
		<Tile imgSrc={imgSrc} optionButton={isManager && <EitjeButton iconLeft="three-dots" modalLink="edit" />}>
			<div className="padding-sm margin-bottom-sm">
				<Title truncate className="margin-bottom-xss">
					{title}
				</Title>
				<p className="eitje-text-secondary">{subtitle}</p>
			</div>
			{isManager && (
				<ContextForm
					fieldProps={{labelVisible: false}}
					ref={formRef}
					useSubmitStrategy
					debounceTime={250}
					initialValues={item}
					afterSubmit={(res, data) => afterSubmit(data, published)}
					onSubmit={data => API.update('topics', data)}
				>
					<div fieldWrapper className="margin-bottom-lg">
						<TileInfoLine label={t('topicPublishLabel')} infoText={t('topicPublishDescript')} />

						<Tooltip fieldWrapper placement="topRight" title={!hasResources && t('addResourceToPublish')}>
							<PublishSwitch
								item={item}
								handlePublishAll={handlePublishAll}
								unpublishedResources={unpublishedResources}
								disabled={!hasResources}
								disabledStyle={{opacity: '1'}}
								field="published"
							/>
						</Tooltip>
					</div>
					<TileInfoLine label={t('topicViewLabel')} infoText={t('topicViewDescript')} />
					<div children={filter} fieldWrapper />
				</ContextForm>
			)}
			{button && <div className="padding-top-xss">{button}</div>}
		</Tile>
	)
}

const PublishSwitch = props => {
	const {item, unpublishedResources = [], handlePublishAll} = props
	const {submit} = useForm()
	const value = item.published
	const numUnpub = unpublishedResources.length
	const hasUnpublishedResources = numUnpub > 0
	const askPublishArticles = hasUnpublishedResources && !value
	const Wrapper = askPublishArticles ? SubmitPopout : Fragment

	return (
		<Wrapper
			text={t('publishAllTopicItems')}
			onSubmit={handlePublishAll}
			submitText={t('everything')}
			onCancel={() => submit()}
			cancelText={t('onlyTopic')}
			placement="right"
		>
			<Switch
				label={t('published')}
				labelVisible
				containerProps={{className: 'elementContainer boxed-field-container'}}
				{...props}
				submitStrategy={askPublishArticles ? null : 'change'}
			/>
		</Wrapper>
	)
}

// Main Component - move to own file?
export const Training = () => {
	const openTraining = useSelector(trainingItemsSelector).map(item => item.id)
	const doneTraining = useSelector(usersCompletedTraining)
	// done trainings is every type of training. Only need the trainings that have days_to_start
	let topics = useSelector(state => where(state, 'topics', [...doneTraining, ...openTraining])).filter(item =>
		utils.exists(item.days_to_start),
	)
	topics = _.orderBy(topics, 'days_to_start')
	const hasTopics = topics.length > 0

	const {list} = useList({
		items: topics,
		ListItem: TrainingTopic,
	})

	if (!hasTopics) return null

	return (
		<>
			<div className="margin-bottom-md row-with-justify-center padding-top-sm">
				<div className="topic-container">
					<SectionHeader size="sm" head={t('availableTrainingLabel')} subText={t('availableTrainingDescript')} />

					<div className="training-list">{list}</div>
				</div>
			</div>
			<hr style={{border: 'none', height: '1px', backgroundColor: '#eee', margin: '0px 0px 24px 0px'}} />
		</>
	)
}

export const TrainButton = ({id, ...rest}) => {
	const {trainable} = useSelector(state => topicIsTrainable(state, id)) || {}
	if (!trainable) return null

	return <EitjeButton link={`/topics/${id}/train`} width="full" t="start_training" {...rest} />
}

const getTrainingCardText = (isDone, starts_at, trainable) => {
	if (isDone) return t('finishedSet')
	if (trainable) return t('start_training')
	if (starts_at) return t('startsIn') + utils.daysUntill(starts_at) + pluralize(t('day'), utils.daysUntill(starts_at))
	return t('nextInLine')
}

const TrainingCheck = ({topicId}) => {
	const {starts_at} = useSelector(state => find(state, 'topics', topicId))
	const userDoneIds = useSelector(usersCompletedTraining) || []
	const isDone = userDoneIds.includes(topicId)
	const {trainable} = useSelector(state => topicIsTrainable(state, topicId)) || {}

	return (
		<div className="topic-training-check">
			{isDone && <CheckMark small checked={isDone} />}
			<div className="topic-training-check-text">{getTrainingCardText(isDone, starts_at, trainable)}</div>
		</div>
	)
}

const makeTrainTopicCardSubhead = (pubItems = [], quiz_items = []) => {
	const numQuiz = quiz_items.length
	const numArt = pubItems.length - numQuiz
	const quizStr = numQuiz > 0 && `${numQuiz} ${pluralize(t('question'), numQuiz).toLowerCase()}`
	const artStr = numArt > 0 && `${numArt} ${pluralize(t('article'), numArt).toLowerCase()}`
	return [artStr, quizStr].filter(Boolean).join(' | ')
}

const TrainingTopic = ({item}) => {
	const {title, id, days_to_start} = item
	const {trainable} = useSelector(state => topicIsTrainable(state, id)) || {}
	const {publishedTopicResources, topicQuizItems} = useTopicResources(item)
	const className = utils.makeCns('training-card', trainable && 'highlighted')
	const subHead = makeTrainTopicCardSubhead(publishedTopicResources, topicQuizItems)
	const dayCount = `${_.capitalize(t('day'))} ${getDaysToStart({number: days_to_start})}`
	const trainingImageSrc = getImage(item, 'mini', '/images/placeholder_manual_img.png')

	return (
		<Link to={`/topics/${id}`}>
			<div className={className}>
				<div className="row-with-justify-start">
					<div className="flex-grow-one">
						<p className="training-card-header">{title}</p>
						<p className="eitje-text-secondary">{subHead}</p>
					</div>

					<div className="training-card-right">
						<div className="training-card-day-count">
							<img src="/images/web/icons/powerIcon.png" width={18} height={22} />
							<h5 className="bold">{dayCount}</h5>
						</div>
						<img src={trainingImageSrc} className="training-image" />
					</div>
				</div>

				<div className="trainButton">{trainable ? <TrainButton id={id} /> : <TrainingCheck topicId={id} />}</div>
			</div>
		</Link>
	)
}
