export const pathActive = (pathPart, stripPaths = true) => {
	const {pathname, href} = window.location

	if (!pathPart) return

	// check for equality over inclusiveness to prevent short urls (e.g. /user/...) to be matched
	// to incorrect paths (e.g. /user_uren)
	if (stripPaths) return strip(pathPart) === strip(pathname)

	return href.includes(pathPart)
}

export const pathStartsWith = part => {
	if (!part) return
	const {pathname} = window.location
	return pathname.startsWith(part)
}

function strip(string) {
	if (!string) return

	return string
		.split(/\/\d/)[0] // cut of ID part of URL
		.split(/\?/)[0] // cut of query parameters
}

export function slug(pathname) {
	return _.last(_.compact(pathname.split('/')))
}
