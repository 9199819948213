import {formatDateForBalance} from 'cores/balance'
import {date, makeRange} from 'initializers/date'

export const getContractItems = (user, envId, kind, endDate) => {
	let items = []

	let userEmploymentTypes = user.userEmploymentTypes.sortBy('start_date')
	if (endDate) userEmploymentTypes = userEmploymentTypes.filter(c => c.start_date < endDate)

	const activeUserEmpTypes = user.userEmploymentTypes.filter(u => u.employmentType.hasBalance(kind))
	userEmploymentTypes.forEach((userEmpType, idx) => {
		const empType = userEmpType.employmentType
		const active = empType.hasBalance(kind)
		const {end_date, start_date} = userEmpType
		items.push({
			typeId: 'contract_start',
			range: userEmpType.dateRange,
			date: start_date,
			active: empType.hasBalance(kind),
			type: empType.sanitizedName(),
		})

		const nextEmpType = userEmploymentTypes[idx + 1]

		let gap = nextEmpType && date(nextEmpType.start_date).diff(end_date, 'days') > 1
		let minGapDate = end_date

		if (gap && active) {
			const gapRange = makeRange(date(end_date).subtract(1, 'day'), nextEmpType.start_date)
			const minGapDate = gapRange.find(_date => !activeUserEmpTypes.some(s => s.dateRange.contains(_date)))?.format()
			gap = !!minGapDate
		}

		if ((userEmpType.end_date && !nextEmpType) || gap) {
			items.push({typeId: 'contract_end', date: minGapDate})
		}
	})

	if (endDate) items = items.filter(i => i.date <= endDate)

	const lastItem = items.sortBy('date').last()

	if (lastItem && !lastItem.active && endDate && lastItem.date <= date().format()) {
		lastItem.balanceEnded = true
		lastItem.labelDate = lastItem.date
		lastItem.date = date(lastItem.date).clone().add(5, 'years').format() // needs to come as last item
	}

	return items
}

export const transformBalance = (balance, periodType, {empTypeItems, terminationDate}) => {
	let currentBalance = 0
	const keys = Object.keys(balance)
	return keys
		.map((period, idx) => {
			const current = balance[period] || {mins: 0}
			currentBalance += current.mins
			const startDate = date(current.date).startOf(periodType)
			const isTerminated = terminationDate && terminationDate <= startDate
			const relevantEmpTypeItems = empTypeItems.filter(e => e.active && e.typeId == 'contract_start')
			const balanceActive =
				!isTerminated && relevantEmpTypeItems.find(e => e.range.contains(startDate) || e.range.contains(date(current.date)))

			return {
				period,
				isLast: idx == keys.length - 1,
				periodType,
				diff: current.mins,
				balanceActive,
				balance: currentBalance,
				label: formatDateForBalance(period, periodType),
				...current,
			}
		})
		.reverse()
}
