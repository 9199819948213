import React from 'react'
import utils from '@eitje/web_utils'
import AvailabilityCard from './availability_card/index'
import {useAmountWorkedContext} from 'cores/planning/pages/index/amount_worked_provider'

const AvailabilityList = ({users = [], label, noSort, ...rest}) => {
	if (users.length == 0) return null
	return (
		<div className="availability-list">
			{label && <AvailabilityLabel label={label} amount={users.length} />}
			{noSort ? <AvListInner users={users} {...rest} /> : <AvListSorted users={users} {...rest} />}
		</div>
	)
}

const AvListSorted = ({users, maxAmount, ...rest}) => {
	const {userMap} = useAmountWorkedContext()
	const sortedUsers = _.orderBy(
		users,
		u => {
			let val = userMap[u.id]?.daysRemaining
			if (!utils.exists(val)) return -1000
			if (u.available) val += 100
			return val
		},
		'desc',
	).slice(0, maxAmount)

	return <AvListInner users={sortedUsers} {...rest} />
}

const AvListInner = ({users, maxAmount, ...rest}) => {
	const _users = users.slice(0, maxAmount)
	return _users.map(u => <AvailabilityCard key={u.id} user={u} {...rest} />)
}

export default React.memo(AvailabilityList, _.isEqual)

const AvailabilityLabel = ({label, amount}) => {
	return (
		<h4 className="availability-list-label">
			{label} ({amount})
		</h4>
	)
}
