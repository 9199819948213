import {backend} from '@eitje/easy_api'
import {store} from 'index'

export const finishTrainingStep = async (resource, data) => {
	const {tableName, id, topicResourceId} = resource

	let res

	if (tableName === 'infos') {
		res = await backend.post(`topic_resources/${topicResourceId}/finish`)
	}

	if (tableName === 'quizItems') {
		res = await backend.post('user_answers', {quiz_item_id: id, ...data})
	}

	if (res.ok) {
		store.dispatch({type: 'SET_VALUE', name: 'training', payload: res.data})
	}

	return res
}
