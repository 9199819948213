import {usePrevious} from '@eitje/react-hooks'
import {useMemo} from 'react'

export const useLastUpdated = model => {
	const items = model.all()
	return useMemo(() => ({updated_at: items.max('updated_at'), length: items.length}), [items])
}

export const useLastUpdatedCallback = ({items = [], callback}) => {
	const maxUpdated = items._map('updated_at').filter(Boolean).max() || 0
	const totalLength = items._map('length').filter(Boolean).sum() || 0
	const prevUpdated = usePrevious(maxUpdated)
	const prevLength = usePrevious(totalLength)
	if (prevLength > totalLength || maxUpdated > prevUpdated) {
		setTimeout(callback) // timeout is strictly necessary, otherwise it creates an infinite render loop
	}
}
