import {backend} from '@eitje/easy_api'
import {setArbitrary} from 'actions'
export const getOvertimeBalance = async () => {
	const res = await backend.get(`balance/overtime_by_date?out_of_service=both`)
	if (res.ok) {
		setArbitrary('overtime_balance', res.data.balance)
	}
}

export const getLeaveBalance = async () => {
	const res = await backend.get(`balance/leave_by_date?out_of_service=both`)
	if (res.ok) {
		setArbitrary('leave_balance', res.data.balance)
	}
}
