import {Layout, Title} from 'common/components'
import './styles.less'
import {PageHeaderTitle} from './title'

export const FullScreenPageHeader = ({className, left, right, height, ...rest}) => {
	return (
		<Layout childrenGrow borderBottom className={className} padding={height === 'small' ? 4 : 16} minWidth={1200}>
			<Layout children={left} />
			<Layout horizontal="center">
				<PageHeaderTitle height={height} {...rest} />
			</Layout>
			<Layout horizontal="end" children={right} />
		</Layout>
	)
}
