import {useWhere} from '@eitje/easy_api'
import {t as baseT} from 'initializers/i18n'
import useParams from 'hooks/use_params'
import {Environment, LeaveRequest} from 'models/index'
import {ContextForm, useForm} from '@eitje/form'
import {t, LeftModalFooter, EnvPicker} from 'cores/leave/index'
import useFormData from 'hooks/use_form_data'
import {EitjeModal, makeRedirectProps, useModalContext} from 'components/common/index'
import Body from './body/index'
import RightPanel from './right_panel/index'
import {useModalBack, useRoleOrgEnvs} from 'hooks/index'
import SimpleProvider, {useSimpleContext} from 'contexts/simple'
import {useGet, useHasRole} from 'hooks/index'
import {ModalRedirect} from 'components/routing'
import {ConfirmButton, TourButton} from 'common/components'
import {ResourceFetcher} from 'components/shared'
import './styles/index.less'
import {useEffect} from 'react'
export * from './components/index'

const _Approve = ({transNamespace, processed, hasLeaveRole, request}) => {
	const data = useFormData()

	const btnProps = !processed
		? {children: baseT('common.deny'), onClick: () => request.deny(data)}
		: {children: t('reset'), iconLeft: 'arrow-circle', onClick: () => request.reset()}

	const insertAtIndex = hasLeaveRole && {0: <TourButton tourId={454632} />, 1: <ConfirmButton {...btnProps} />}

	return (
		<EitjeModal
			form={!processed && hasLeaveRole}
			RightPanel={RightPanel}
			RightPanelWidth={300}
			docsUrl="6019593"
			name={transNamespace}
			okButtonProps={{text: baseT('common.approve')}}
			LeftFooter={LeftModalFooter}
			RightHeader={hasLeaveRole && RightHeader}
			confirmSubmit
			insertAtIndex={insertAtIndex}
			width={1100}
		>
			<Body />
		</EitjeModal>
	)
}

const RightHeader = () => {
	const {t} = useModalContext()
	const {setValues} = useForm()
	const {environment_id} = useFormData()
	const {processed, request} = useSimpleContext()

	const leaveEnvs = useRoleOrgEnvs('verlof')
	const allEnvs = Environment.all()
	const envs = processed ? allEnvs : leaveEnvs.filter(e => request.relevantEnvironmentIds().includes(e.id))

	const status = processed ? 'processed' : 'unprocessed'
	const envIds = envs._map('id')

	useEffect(() => {
		if (!processed && !envIds.includes(environment_id)) {
			setValues({environment_id: envs[0].id})
		}
	}, [environment_id, processed])

	const envIsUnknown = !envIds.includes(environment_id)

	return (
		<>
			<h5>{t(`env_picker.${status}_label`)} </h5>
			{envIsUnknown ? <h5>{baseT('common.unknown_environment')}</h5> : <EnvPicker envs={envs} required />}
		</>
	)
}

export const Approve = () => {
	const {goBack} = useModalBack()
	const {id} = useParams()
	const request = LeaveRequest.find(id)

	const {data} = useGet(request.buildUrl?.('audits'))
	const {audits = []} = data
	const transNamespace = 'approve_leave'
	const processed = request?.isProcessed?.()
	const hasLeaveRole = useHasRole('verlof', request.relevantEnvironmentIds?.())
	const dateRange = request?.__dateRange?.()

	const schedules = useWhere('work_schedules', {dateRange, user_id: request.user_id})

	const redirectProps = makeRedirectProps({role: 'verlof', env: request.environment_id})

	if (!request.id) return <ResourceFetcher kind="leave_requests" id={id} />
	if (!hasLeaveRole) return <ModalRedirect to={`/leave/${id}`} {...redirectProps} />

	const schedule = schedules[0]

	const initial = processed ? request : _.omit(request, 'total_mins')
	// there's a race condition between our effect setting 'total_mins' and our request for all leave requests in this period
	// once we fetch the requests, the identity of `request` changes and thus easy API sees initialValues as a new object
	// because total_mins is a key of the request AND it's not been touched (setValues doesn't touch) it's wiped if the leave API call is slower than the approve info call
	// an idea might be to compare initialValues deeply instead of shallowly, then I guess this wouldn't have occured

	const context = {request: {...request, audits}, schedule, schedules, processed, transNamespace, hasLeaveRole}

	return (
		<ContextForm
			fieldProps={{readOnly: processed}}
			transNamespace={transNamespace}
			initialValues={initial}
			afterSubmit={goBack}
			onSubmit={data => request.approve(data)}
		>
			<SimpleProvider {...context}>
				<_Approve {...context} />
			</SimpleProvider>
		</ContextForm>
	)
}
