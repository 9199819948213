import utils from '@eitje/web_utils'
import useArbitrary from './use_arbitrary'

const useUserOvertime = userId => {
	const allOvertime = useArbitrary('overtime_balance') || []
	const overtimeMins = allOvertime.find(u => u.user_id == userId)?.balance
	return {mins: overtimeMins, timeString: utils.minutesToString(overtimeMins), available: utils.exists(overtimeMins)}
}

export default useUserOvertime
